import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { withStyles, Button, TextField, Grid, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import DocumentDropZone from '../../utils/DocumentDropZone';
import DocumentGridList from '../../utils/DocumentGridList';
import { getNoteGroupDropDown, addFieldNote, setInitialState } from '../../actions/note/NotesActions';
import ReactSelect from '../../utils/ReactSelect';
import immutable from 'seamless-immutable';
import axios from './../../utils/AxiosWrapper';

const styles = theme => ({
    innerContainer: {
        width: '100%',
        textAlign: 'center',
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    circleGreen: {
        height: '50px',
        width: '50px',
        backgroundColor: 'darkgreen',
        borderRadius: '50%',
    },
    circleYellow: {
        height: '50px',
        width: '50px',
        backgroundColor: 'gold',
        borderRadius: '50%',
    },
    circleRed: {
        height: '50px',
        width: '50px',
        backgroundColor: 'red',
        borderRadius: '50%',
    },
    circleBlue: {
        height: '50px',
        width: '50px',
        backgroundColor: '#000080',
        borderRadius: '50%',
    },
    circleBlack: {
        height: '50px',
        width: '50px',
        backgroundColor: '#000000',
        borderRadius: '50%',
    },
    circlePurple: {
        height: '50px',
        width: '50px',
        backgroundColor: 'purple',
        borderRadius: '50%',
    },
    circleBrown: {
        height: '50px',
        width: '50px',
        backgroundColor: 'brown',
        borderRadius: '50%',
    }
});
export class AddNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notesObj: this.props.notesObj,
            displayColorPicker: false,
            openModal: false,
            noteGroupDropDown: []
        }
    }

    componentDidMount() {
        this.props.getNoteGroupDropDown()
    }

    componentDidUpdate(prevProps) {
        const { noteGroupDropDown, notesPostingFailed, notesObj } = this.props;
        let user = this.getUser();

        if (prevProps.noteGroupDropDown !== noteGroupDropDown) {

            if (user.id_jezik === 1) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
                })
            }
            else if (user.id_jezik === 2) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv_en, value: item.id } }), { deep: true }),
                })
            }

            else if (user.id_jezik === 4) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv_hr, value: item.id } }), { deep: true }),
                })
            }

        }
        if (prevProps.notesPostingFailed === false && notesPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80,
            });
            this.props.setInitialState('notesPostingFailed');
        }
        if (prevProps.notesObj !== notesObj) {
            this.setState({
                notesObj
            })
        }
    }

    addNote() {
        const { notesObj, acceptedDocuments } = this.state;
        const { addFieldNote, onSave } = this.props;

        if (onSave) {
            return onSave(notesObj);
        }

        if (notesObj.id_terenska_intervencija_vrsta && notesObj.id_terenska_intervencija_vrsta === 19) {
            if (notesObj.datum_analize && notesObj.naslov && notesObj.dubina) {
                addFieldNote(notesObj, acceptedDocuments);
                if (this.props.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 15 } })
                else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 11 } })
            } else {
                toast.error(i18n.t('Please enter all required fields!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            }
        } else if (notesObj.naslov &&
            notesObj.id_terenska_intervencija_vrsta &&
            notesObj.datum_dogadjaja) {
            let data;
            data = notesObj.id && {
                id: notesObj.id,
                naslov: notesObj.naslov,
                id_terenska_intervencija_vrsta: notesObj.id_terenska_intervencija_vrsta,
                datum_dogadjaja: notesObj.datum_dogadjaja,
                opis: notesObj.opis
            } || {
                naslov: notesObj.naslov,
                id_terenska_intervencija_vrsta: notesObj.id_terenska_intervencija_vrsta,
                datum_dogadjaja: notesObj.datum_dogadjaja,
                opis: notesObj.opis
            }
            addFieldNote(data, acceptedDocuments);
            if (this.props.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 15 } })
            else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 11 } })
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.notesObj);
        obj[name] = event.target.value;
        this.setState({ notesObj: obj });
    };

    handleChangeDropdownValue = name => selected => {
        let obj = Object.assign({}, this.state.notesObj);
        obj[name] = selected && selected.value || null;
        this.setState({ notesObj: obj });
    }

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.notesObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ notesObj: obj });
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY')
        } else {
            return i18n.t('Pick date');
        }
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();

        const { notesObj, noteGroupDropDown } = this.state;
        const { classes, edit, handleClose, add, notesPosting, notesDocuments } = this.props;
        const renderSemaphore = () => {
            let circle;
            switch (notesObj.id_terenska_intervencija_vrsta) {
                case 12:
                    circle = <img width="50" height="50" src="./note/fire-icon-new.png" />
                    break;
                case 13:
                    circle = <img width="50" height="50" src="./note/disease-icon-new.png" />
                    break;
                case 14:
                    circle = <img width="50" height="50" src="./note/pest-icon-new.png" />
                    break;
                case 15:
                    circle = <img width="50" height="50" src="./note/steal-icon-new.png" />
                    break;
                case 16:
                    circle = <img width="50" height="50" src="./note/other-icon-new.png" />
                    break;
                case 17:
                    circle = <img width="50" height="50" src="./note/mark-icon-new.png" />
                    break;
                case 18:
                    circle = <img width="50" height="50" src="./note/weed-icon-new.png" />
                    break;
                case 19:
                    circle = <img width="50" height="50" src="./note/analysis-icon-new.png" />
                    break;
                default:
                    circle = <div className={classes.circleGreen} />
            }
            return circle;
        }
        const { theme } = this.props;

        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{edit ? i18n.t('Edit note') : i18n.t('Add note')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid style={{ marginTop: 10 }} container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item xs={12}>
                                {renderSemaphore()}
                            </Grid>
                            <Grid item>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    value={noteGroupDropDown.find((element) => {
                                        return element.value === notesObj.id_terenska_intervencija_vrsta
                                    })}
                                    maxMenuHeight={150}
                                    menuPlacement="auto"
                                    onChange={this.handleChangeDropdownValue('id_terenska_intervencija_vrsta')}
                                    options={noteGroupDropDown}
                                    label={i18n.t('Note type') + '*'}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }), clearIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    placeholder={i18n.t('Select')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t('Title')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={notesObj.naslov}
                                    onChange={this.handleChangeValue('naslov')}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker
                                    keyboard
                                    label={i18n.t('Event date *')}
                                    format="DD.MM.YYYY"
                                    value={notesObj.datum_dogadjaja && moment(notesObj.datum_dogadjaja, 'DD.MM.YYYY') || ''}
                                    onChange={this.handleDateChange('datum_dogadjaja')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    disableOpenOnEnter
                                    labelFunc={this.renderLabel}
                                    animateYearScrolling={false}
                                />
                            </Grid>
                            {edit && notesDocuments && notesDocuments.length !== 0 ?
                                <DocumentGridList
                                    documentArray={notesDocuments}
                                    showDelete={false}
                                />
                                : null}
                            <DocumentDropZone
                                multiple={true}
                                onDropAccepted={({ accepted, rejected }) => {
                                    this.setState({
                                        acceptedDocuments: accepted,
                                        rejectedDocuments: rejected
                                    });
                                }}
                            />
                            {notesPosting && < LinearProgress className="spiner" />}
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="full-width"
                                margin="normal"
                                label={i18n.t('Description')}
                                value={notesObj.opis}
                                onChange={this.handleChangeValue('opis')}
                            />
                            {notesObj.naziv_materijala &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Material')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.naziv_materijala}
                                    />
                                </Grid>
                            }
                            {notesObj.prosecan_prinos &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Average yield')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.prosecan_prinos}
                                    />
                                </Grid>
                            }
                            {notesObj.cena &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Price')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.cena}
                                    />
                                </Grid>
                            }
                            {notesObj.naziv_korov &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Root')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.naziv_korov}
                                    />
                                </Grid>
                            }
                            {notesObj.naziv_bolest &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Disease')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.naziv_bolest}
                                    />
                                </Grid>
                            }
                            {notesObj.naziv_stetocine &&
                                <Grid item>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        label={i18n.t('Pests')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.naziv_stetocine}
                                    />
                                </Grid>
                            }
                        </Grid>
                        {notesObj.id_terenska_intervencija_vrsta === 19 &&
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Analysis date *')}
                                        format="DD.MM.YYYY"
                                        value={notesObj.datum_analize && moment(notesObj.datum_analize, 'DD.MM.YYYY') || ''}
                                        onChange={this.handleDateChange('datum_analize')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disableOpenOnEnter
                                        labelFunc={this.renderLabel}
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t('Depth(cm)')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.dubina}
                                        onChange={this.handleChangeValue('dubina')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('pH KCI')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.phkci}
                                        onChange={this.handleChangeValue('phkci')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('pH H2O')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.phh2o}
                                        onChange={this.handleChangeValue('phh2o')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Humus')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.humus}
                                        onChange={this.handleChangeValue('humus')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('AI-P2O5')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.aip2o5}
                                        onChange={this.handleChangeValue('aip2o5')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('AI-K20')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.aik2o}
                                        onChange={this.handleChangeValue('aik2o')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Ukupni N')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.ukupni_n}
                                        onChange={this.handleChangeValue('ukupni_n')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('CaCO3')}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={notesObj.caco3}
                                        onChange={this.handleChangeValue('caco3')}
                                    />
                                </Grid>
                                <TextField
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    label={i18n.t('Note')}
                                    value={notesObj.napomena}
                                    onChange={this.handleChangeValue('napomena')}
                                />
                            </Grid>
                        }
                        <Grid item xs>
                            <Button
                                style={{ alignSelf: 'center', color: 'white', marginTop: 20 }}
                                disabled={notesPosting}
                                variant="contained"
                                color="primary"
                                onClick={() => this.addNote()}
                            >
                                {edit && i18n.t('Edit') || i18n.t('Add')}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        notesObj: state.noteReducer.notesObj,
        noteGroupDropDown: state.noteReducer.noteGroupDropDown,
        notesPostingFailed: state.noteReducer.notesPostingFailed,
        notesPosting: state.noteReducer.notesPosting,
        notesDocuments: state.noteReducer.notesDocuments,
        notesDocumentsFetching: state.noteReducer.notesDocumentsFetching,
        notesDocumentsFetchingFailed: state.noteReducer.notesDocumentsFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNoteGroupDropDown: () => dispatch(getNoteGroupDropDown()),
        addFieldNote: (noteObj, acceptedDocuments) => dispatch(addFieldNote(noteObj, acceptedDocuments)),
        setInitialState: (component) => dispatch(setInitialState(component))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNote))