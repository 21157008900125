import React from 'react';
import i18n from '../i18n/i18n';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { Button, Link, Typography } from '@material-ui/core';

const now = moment();
const today = moment().subtract(1, 'days');
const yesterday = moment().subtract(2, 'days');
const week = moment().subtract(7, 'days');
const month = moment().subtract(30, 'days');

// props: startDate, endDate, setDates, getPessl

export default class DatePickerMeteo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null,
        }
    }

    componentDidMount() {
        this.props.getPessl();
    }

    onFocusChange = (focusedInput) => {
        if (focusedInput) this.setState({ focusedInput });
        else this.setState({ focusedInput: this.props.startDate });
    }

    //getToday = () => { this.props.setDates(today, now) }
    getYesterday = () => { this.props.setDates(yesterday, today) }
    getThisWeek = () => { this.props.setDates(week, now) }
    getThisMonth = () => { this.props.setDates(month, now) }

    render() {
        const { focusedInput } = this.state;
        return (
            <div style={{ alignSelf: 'center', marginTop: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Typography style={{ marginRight: '10px', marginLeft: '2%', fontSize: '14px', marginTop: '16px' }}>{i18n.t('Select date range')}</Typography>
                    <div style={{ marginRight: '10px', marginTop: '5px', width: '265px', border: 'outset', marginBottom: '5px' }}>
                        <DateRangePicker
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            small={true}
                            onDatesChange={({ startDate, endDate }) => this.props.setDates(startDate, endDate)}
                            onFocusChange={this.onFocusChange}
                            focusedInput={focusedInput}
                            displayFormat={() => "DD.MM.YYYY"}
                            isOutsideRange={() => false}
                            daySize={30}
                            noBorder={true}
                            readOnly={true}
                            showDefaultInputIcon={true}
                        />
                    </div>
                    <div style={{ marginTop: '16px', display: 'flex' }}>
                        {/* <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '14px', color: 'black', cursor: 'pointer' }}
                            onClick={this.getToday}
                            variant="contained"
                            color="secondary" >
                            <Typography>{i18n.t('Today')}</Typography>
                        </Link> / */}
                        <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '14px', color: 'black', cursor: 'pointer' }}
                            onClick={this.getYesterday}
                            variant="contained"
                            color="secondary">
                            <Typography>{i18n.t('Yesterday')}</Typography>
                        </Link> /
                        <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '14px', color: 'black', cursor: 'pointer' }}
                            onClick={this.getThisWeek}
                            variant="contained"
                            color="secondary" >
                            <Typography>{i18n.t('7 days')}</Typography>
                        </Link> /
                        <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '14px', color: 'black', cursor: 'pointer' }}
                            onClick={this.getThisMonth}
                            variant="contained"
                            color="secondary"  >
                            <Typography>{i18n.t('30 days')}</Typography>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}