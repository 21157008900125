import React from 'react';
import axios from './../../utils/AxiosWrapper';
import randomColor from 'randomcolor';
import { Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Typography } from '@material-ui/core';

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            graph: {}, units: [], options: [], filters: {}, data: null, initial_data: null, filterable: []
        };
    }

    componentDidMount() {
        let template = {};
        if (this.props.filters) {
            this.props.filters.forEach(item => { template[item] = []; });

            this.setState({ filters: template }, () => {
                axios.get(this.props.url)
                    .then(res => {
                        this.setState({ initial_data: res.data.data, data: res.data.data }, () => {
                            this.prepareOptions(this.state.initial_data);
                            this.prepareData(this.state.data);
                        });
                    })
                    .catch(err => { console.log(err) });
            });
        }
    };

    filterData = () => {
        let data = this.state.initial_data;

        let filters = {};
        let obj = {};

        if (this.props.filters) {
            this.props.filters.forEach(item => { filters[item] = []; });
            Object.keys(this.state.filters).forEach((key, i) => {
                obj[key] = [];
                this.state.filters[key].forEach((item, j) => {
                    filters[key].push(item.value);
                })
            });
        }

        data.forEach((el, e) => {
            Object.keys(el).forEach((key, k) => {
                if (this.props.filters.includes(key)) {
                    if (filters[key].includes(el[key])) {
                        if (!obj[key].includes(e)) obj[key].push(e);
                    }
                }
            })
        });

        let first = null;


        Object.keys(obj).forEach((key, i) => { if (obj[key].length === 0) delete obj[key]; });

        let init = true;
        let final = [];
        Object.keys(obj).forEach((key, i) => {

            if (Object.keys(obj).length === 1 && init === true) {
                final = obj[key];
                init = false;
            }
            else {
                if (init === true) first = obj[key];
                if (init === false) {
                    obj[key].forEach((el, e) => {
                        if (first.includes(el)) final.push(el);
                    })
                }
                init = false;
            }
        });


        let final_data = [];
        final.forEach((item, i) => {
            final_data.push(this.state.initial_data[item]);
        })

        this.prepareData(final_data);

    };

    prepareData = (data) => {
        let labels = [], dataset = [], colors = [], units = [];
        if (data.length === 0) data = this.state.initial_data;
        // this.prepareOptions(data);

        data.forEach(item => {
            labels.push(item[this.props.labels]);
            dataset.push(Number(item[this.props.dataset]));
            colors.push(randomColor());
            // units.push(item[this.props.unit]);
        });

        let unique = [...new Set(labels)];
        let array = [];

        for (let i = 0; i < dataset.length; i++) {
            for (let j = 0; j < unique.length; j++) {
                if (unique[j] === labels[i]) {
                    if (array[j] === undefined) array[j] = dataset[i]
                    else array[j] = array[j] + dataset[i];
                };
            };
        };

        labels = unique;
        dataset = array;

        const graph = {
            labels: labels,
            datasets: [{ label: this.props.title, data: dataset, backgroundColor: colors }]
        };

        this.setState({
            graph,
            // units
        });
    };

    prepareOptions = (data) => {
        let options = [];

        if (this.props.filters) {
            for (let i = 0; i < this.props.filters.length; i++) {
                let array = [];
                for (let j = 0; j < data.length; j++)  array.push(data[j][this.props.filters[i]])
                array = [...new Set(array)];
                options.push(array);
            };

            this.setState({ options });
        }
    };


    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();
        let sum = 0;
        if (this.props.total === true && this.state.graph.datasets && this.state.graph.datasets[0]) {
            sum = this.state.graph.datasets[0].data[1] - this.state.graph.datasets[0].data[0];
            if (!this.state.graph.datasets[0].data[1]) sum = this.state.graph.datasets[0].data[0];
        }

        let options_d = {
            legend: {
                display: true
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (tooltipItem) => { // nije automatizovan
                        let index = tooltipItem.index;

                        if (user.id_region === 2) {
                            return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index] + ' ' + 'rsd'
                        }
                        else return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index];
                        // return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index] + ' ' + this.state.units[index];
                    }
                },
            }
        };

        let options = {
            legend: {
                display: false
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (tooltipItem) => {
                        let index = tooltipItem.index;

                        if (user.id_region === 2) {
                            return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index] + ' ' + 'rsd'
                        }
                        else return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index];
                        // return this.state.graph.labels[index] + ': ' + this.state.graph.datasets[0].data[index] + ' ' + this.state.units[index];
                    }
                },
            },
            scales: {
                yAxes: [{ display: true, ticks: { beginAtZero: true, min: 0 } }],
                xAxes: [{ display: true, ticks: { beginAtZero: true, min: 0 } }],
            }
        };

        return (
            <div>
                <div>
                    {this.props.total === true && this.state.graph.datasets && this.state.graph.datasets[0] &&
                        <Typography style={{ margin: '10px', fontSize: '18px' }}>Total: {sum} rsd</Typography>
                    }
                </div>
                <div style={{ display: 'flex' }}>
                    {this.state.options.length > 0 && this.props.filters.map((item, i) => {
                        return <div style={{ display: 'flex' }} key={i}>
                            <div style={{ width: '256px', marginLeft: '10px' }}>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    value={this.state.filters[item]}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        let temp = this.state.filters;
                                        temp[item] = e;
                                        this.setState({ filters: temp }, () => { this.filterData(); });
                                    }}
                                    options={this.state.options[i].map((el) => { return { label: el, value: el } })}
                                    classNamePrefix="select"
                                    placeholder={`⠀⠀⠀ ⠀ ⠀⠀⠀ ⠀` + i18n.t(this.props.filter_names[i])}
                                />
                            </div>
                        </div>
                    })}

                </div>
                <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>
                    {this.props.type === 'doughnut' && <Doughnut data={this.state.graph} options={options_d} />}
                    {this.props.type === 'vertical_bar' && <Bar data={this.state.graph} options={options} />}
                    {this.props.type === 'horizontal_bar' && <HorizontalBar data={this.state.graph} options={options} />}
                </div>
            </div>
        )
    }
}