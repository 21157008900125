/**
 * Created by pajicv on 7/1/18.
 */

import axios from '../../utils/AxiosWrapper';

import React from 'react';

import { connect } from 'react-redux';

import { IconButton, Button, Menu, MenuItem, withStyles, AppBar, Tab, Tabs, Typography } from '@material-ui/core';

import {
    ResponsiveContainer, LineChart, Line, Bar, XAxis, YAxis, ReferenceLine,
    ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, BarChart, Area,
    Label, LabelList
} from 'recharts';

import { WbCloudy } from '@material-ui/icons';

import * as LayerSelectors from '../../selectors/LayerSelectors';
import * as LayerActions from '../../actions/map/LayerActions';

import './Forecast.css'

class ForecastMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            data: [],
            activeTab: 0
        };
    }

    componentDidMount() {
        const { lat, lng } = this.props;
        let that = this;
        const url = `api/forecast/seasonal?lat=${lat}&lng=${lng}`;

        return axios.get(url)
            .then(function (response) {
                that.setState({ data: response.data.data })
            })
            .catch(function (error) {
                that.setState({ data: [] })
            });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.lat === this.props.lat && nextProps.lng === this.props.lng) {
            return;
        }

    }

    handleClick = event => {
        event.currentTarget.hidden = true;
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleTabChange = (event, activeTab) => {
        this.setState({ activeTab });
    };

    render() {
        const { anchorEl, activeTab } = this.state;

        const { classes } = this.props;

        const left = window.innerWidth - 550 - 50;

        return (
            <div className="forecast-menu-panel">
                <IconButton
                    className={classes.button}
                    aria-owns={anchorEl ? 'simple-menu-forecast' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <WbCloudy style={{ fontSize: 36 }} />
                </IconButton>
                <Menu
                    id="simple-menu-forecast"

                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    anchorPosition={{
                        left: left, top: 56
                    }}
                    anchorReference="anchorPosition"
                >
                    <MenuItem className={classes.chart}>
                        <div>
                            <h2>Seasonal forecast</h2>
                            <Typography color="textSecondary">{this.props.lat.toFixed(6)}, {this.props.lng.toFixed(6)}</Typography>
                        </div>
                        <div>
                            <AppBar position="static" color="white" elevation={0}>
                                <Tabs value={activeTab} onChange={this.handleTabChange}>
                                    <Tab label="Temperature" />
                                    <Tab label="Precipitation" />
                                </Tabs>
                            </AppBar>
                            {activeTab === 0 &&
                                <BarChart width={500} height={300} data={this.state.data}>
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                    <Legend />
                                    <Tooltip />
                                    <Bar dataKey="temperature" fill="#ff8800" />
                                    <Bar dataKey="avgTemperature" fill="#ffcf99" />
                                </BarChart>
                            }
                            {activeTab === 1 &&
                                /*<LineChart width={500} height={300} data={this.state.data}>
                                    <XAxis dataKey="month"/>
                                    <YAxis/>
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                    <Line type="monotone" dataKey="precipitation" stroke="#0000ff" strokeWidth={2} />
                                    <Line type="monotone" dataKey="avgPrecipitation" stroke="#82ca9d" strokeWidth={2} />
                                </LineChart>*/
                                <BarChart width={500} height={300} data={this.state.data}>
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                    <Legend />
                                    <Tooltip />
                                    <Bar dataKey="precipitation" fill="#0000ff" />
                                    <Bar dataKey="avgPrecipitation" fill="#82ca9d" />
                                </BarChart>
                            }

                        </div>
                    </MenuItem>
                    <MenuItem>
                        <Button onClick={this.handleClose}>CLose</Button>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

//<Line type="monotone" dataKey="precipitation" stroke="#82ca9d" />

const styles = theme => ({
    button: {
        height: 40,
        padding: 5
    },
    chart: {
        height: 420,
        width: 500,
        display: 'block'
    }
});

ForecastMenu = withStyles(styles)(ForecastMenu);

function mapStateToProps(state) {
    return {
        seasonalForecast: LayerSelectors.getSeasonalForecast(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeasonalForecast: () => dispatch(LayerActions.getSeasonalForecast())
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForecastMenu);