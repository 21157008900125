import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
    IconButton
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
const drawerWidth = 56;

const styles = theme => ({
    drawerPaper: {
        position: 'fixed',
        width: drawerWidth,
        zIndex: 1,
        background: '#333333',
        height: '100%'
    },

});

export class DashboardDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchor: 'right',
        };
    }

    render() {

        const { classes } = this.props;
        const { anchor } = this.state;

        const drawer = (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={anchor}
            >
                <IconButton style={{ paddingRight: '26px' }}
                    className="dashboardDrawer" onClick={() => this.props.onTableClick()}>
                    <span className={`icon-table2 ${window.location.pathname.includes('dashboard') ? 'dashboardIcon' : 'dashboardIcon-selected'}`} />
                </IconButton>
                <Divider />
                {/* <IconButton style={{ paddingRight: '23px' }}
                    className="dashboardDrawer" onClick={() => this.props.onPieClick()}>
                    <span className={`icon-pie-chart ${window.location.pathname.includes('dashboard') ? 'dashboardIcon-selected' : 'dashboardIcon'}`} />
                </IconButton> */}
            </Drawer>
        );

        let after = null;
        after = drawer;

        return (
            <div>
                <div>
                    {after}
                </div>
            </div>
        )
    };


};

DashboardDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDrawer));