import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Dialog, DialogContent, DialogTitle,
    Avatar, Card,
    IconButton
} from '@material-ui/core';
import {
    editClientProfile, editClientProfilePicture
} from '../actions/LoginActions';
import Dropzone from 'react-dropzone';
import { CircleLoader } from 'react-spinners';
import ReactSelect from '../utils/ReactSelect';
import immutable from 'seamless-immutable';
import { getSeasonDropDown } from '../actions/DropDownActions';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});

export class Settings extends React.Component {

    componentDidMount() {
        this.props.getSeasonDropDown();
    }

    onDropPicture = ({ accepted, rejected }) => {
        if (!!accepted && accepted.length !== 0) {
            this.props.editClientProfilePicture(accepted);
        }
    }

    onChangeSeason(selectedOption) {
        this.props.editClientProfile({ id: this.props.clientProfile.id, id_default_sezona: selectedOption.value });
        window.location.href = '/';
    }

    render() {
        let avatarSrc = require('../assets/images/profileImage.png');
        if (this.props.clientProfile.slika) {
            avatarSrc = this.props.clientProfile.signedURL;
        }

        const { theme } = this.props;

        const seasonDropDown = this.props.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } });

        const selectedValue = seasonDropDown.find((el) => el.value === this.props.clientProfile.id_default_sezona);

        return (
            <Dialog open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ className: "dialogOverlow" }}>
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Settings')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialogOverlow">
                    {/* <Card className="userProfile-img" >
                        <Avatar src={avatarSrc}
                            className="userProfile-avatar"
                        />
                        {!this.props.clientProfilePictureEditedFetching ?
                            <Dropzone accept={['image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml', 'image/gif']}
                                maxSize={5242880}
                                className="dropZone-profile"
                                multiple={false}
                                onDrop={(accepted, rejected) => this.onDropPicture({ accepted, rejected })} >
                                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    return <div className="dropZoneDiv">
                                        <span className=" icon-Izmeni1 dropZoneIcon" style={{ color: '#58B4E5' }} />
                                    </div>
                                }}
                            </Dropzone>
                            :
                            <CircleLoader color={'#58B4E5'}
                                loading={this.props.clientProfilePictureEditedFetching}
                            />
                        }
                    </Card> */}

                    {seasonDropDown.length > 0 &&
                        <ReactSelect closeMenuOnSelect={true}
                            isClearable={false}
                            value={selectedValue.asMutable()}
                            label={i18n.t('Default season')}
                            onChange={(option) => this.onChangeSeason(option)}
                            options={seasonDropDown.asMutable().sort((a, b) => { return a.value - b.value })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            maxMenuHeight={180}
                            styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                            placeholder={i18n.t('Select')} />}
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        clientProfile: state.appReducer.clientProfile,
        clientProfilePictureEditedFetching: state.appReducer.clientProfilePictureEditedFetching,
        seasonDropDown: state.appReducer.seasonDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        editClientProfile: (obj) => dispatch(editClientProfile(obj)),
        editClientProfilePicture: (obj) => dispatch(editClientProfilePicture(obj)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown())
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings));