import React from 'react';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import MenuHamburger from './MenuHamburger';
import { toggleDrawer } from '../../actions/LoginActions';

const MenuIcon = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${p => p.theme.size(1)};
  align-self: stretch;
  transition: right 0.3s ease-in-out;
  left: ${p => (p.isDrawerOpen ? p.theme.size(1) : `-${p.theme.size(4)}`)};
`;

const Navbar = styled('div')`
  height: ${p => p.theme.size(4)};
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: ${p => p.theme.zIndex.header};
  top: 0;
  left: 0;
  padding-left: ${p => p.theme.size(0.5)};
  background: ${p => p.theme.palette.secondary.main};
`;

const Top = styled('header')`
  display: flex;
  align-items: center;
  height: ${p => p.theme.size(4)};
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(18)}` : `${p.theme.size(5)}`)};
  transition: width 0.3s ease-in-out;
  position: fixed;
  z-index: ${p => p.theme.zIndex.header + 25};
  top: 0;
  left: 0;
`;
const Title = styled('div')`
  color: ${p => p.theme.palette.secondary.contrast};
  text-decoration: none;
  font-size: ${p => p.theme.size(2)};
  overflow:hidden
`;


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: this.props.isDrawerOpen,
            isMenuOpen: this.props.isMenuOpen
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isDrawerOpen: nextProps.isDrawerOpen,
            isMenuOpen: nextProps.isMenuOpen
        })
    }

    toggleDrawerClick() {
        this.props.toggleDrawer(!this.state.isDrawerOpen);
    }

    render() {
        return (
            <div>
                <Top isDrawerOpen={this.state.isDrawerOpen}
                    isMenuOpen={this.state.isMenuOpen}>
                    <MenuIcon
                        isDrawerOpen={this.state.isDrawerOpen}
                        onClick={() => this.toggleDrawerClick()}
                    >
                        <MenuHamburger />
                    </MenuIcon>
                    {(this.state.isDrawerOpen || this.state.isMenuOpen)
                        &&
                        <Title
                            isDrawerOpen={this.state.isDrawerOpen}
                            isMenuOpen={this.state.isMenuOpen}>
                            <img src={require('../../assets/images/logo_white.svg')} width="170" height="40" />
                        </Title>
                    }

                </Top>
                {/* <Navbar isDrawerOpen={this.state.isDrawerOpen} /> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isDrawerOpen: state.appReducer.isDrawerOpen,
        isMenuOpen: state.appReducer.isMenuOpen
    };
}
function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
