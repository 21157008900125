import React, { Component } from 'react'
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText, FormGroup } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
export default class CropRotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            archivedCrop: this.props.archivedCrop,
            cr_crop: null, cr_crops: [],
            rot_crop: null, 
            rot_crops:[],
            rot_crop_subgroup: null, 
            rot_crop_subgroups:[],
            rot_crop_group: null, 
            rot_crop_groups:[],
            isJednogodisnja: false,
            crop_rotation_tip: {},
            idKultura: this.props.idKultura ? this.props.idKultura : null,
            sve: {},
            shrink: false,
        };
    }
    componentDidMount() {
        axios.get('api/crop/drop_down_oneyear')
                .then(res => this.setState({ cr_crops: res.data.data }))
                .catch(err => console.log(err))

        if (this.state.idKultura !== null) {
            let temp = {};
            axios.get('api/crop/is_oneyear', { params: { id: this.state.idKultura } })
            .then(res => this.setState({sve: res.data.data[0], loading: false}) )
            .catch(err => console.log(err))   
        }else{
            this.populateCropRotation(false)
        }

        
    };
    getCropRotation = (id_kultura, id_predkultura) => {
        axios.get('api/field/getPlodored', { params: { id_kultura: id_kultura, id_predkultura: id_predkultura } })
            .then(res => { this.setState({ crop_rotation_tip: res.data.data }); })
            .catch(err => console.log(err))
    };
    populateCropRotation(bollean){
        if (bollean === true) {
           
            axios.get('api/crop/drop_down_group_oneyear')
                .then(res => this.setState({ rot_crop_groups: res.data.data }))
                .catch(err => console.log(err))
            axios.get('api/crop/drop_down_subgroup_oneyear', { params: { rot_id_grupa: this.state.sve.id_kultura_grupa } })
                .then(res => this.setState({ rot_crop_subgroups: res.data.data }))
                .catch(err => console.log(err))
            axios.get('api/crop/drop_down_oneyear', {  params: { rot_id_grupa: this.state.sve.id_kultura_grupa, rot_id_podgrupa: this.state.sve.id_kultura_podgrupa } })
                .then(res => this.setState({ rot_crops: res.data.data }))
                .catch(err => console.log(err))
            
        }else{
            axios.get('api/crop/drop_down_group_oneyear')
                .then(res => this.setState({ rot_crop_groups: res.data.data }))
                .catch(err => console.log(err))
            axios.get('api/crop/drop_down_subgroup_oneyear')
                .then(res => this.setState({ rot_crop_subgroups: res.data.data }))
                .catch(err => console.log(err))
            axios.get('api/crop/drop_down_oneyear')
                .then(res => this.setState({ rot_crops: res.data.data }))
                .catch(err => console.log(err))
        }
    }

    deleteOldState(){

        this.setState({ rot_crop: null,
            rot_crop_subgroup:null,
            rot_crop_group:null})

    }

  render() {

    if(this.state.loading === false){
        if (this.state.sve.jednogodisnja === true) {
            this.populateCropRotation(true)
            this.setState({shrink: true})
            
            this.setState({ rot_crop: this.state.sve.id,
                            rot_crop_subgroup:this.state.sve.id_kultura_podgrupa,
                            rot_crop_group:this.state.sve.id_kultura_grupa})
            
                this.setState({loading: true})
            
            

        }else{
            this.populateCropRotation(false)
            this.setState({loading: true})

        }
    }
    return (
      <div style={{ padding: 20 }}>
    <Dialog
        open
        disableBackdropClick
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title">

        <DialogTitle maxWidth="sm" disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{i18n.t('Crop rotation')}
        <IconButton onClick={() => {
                  this.deleteOldState();
                  this.props.close();
                }}>
                    <span className="icon-cancel-circle closeIcon" />
        </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: 'grid' }}>
            <TextField
                label={i18n.t('Preceding crop')}
                select
                value={this.state.archivedCrop}
                SelectProps={{ native: true, }}
                style={{ width: '512px', marginLeft: '10px', marginTop: '20px', marginBottom: '10px', marginRight: '10px' }}
                onChange={(e) => {
                    this.setState({ cr_crop: e.target.value }, () => {
                         if (this.state.cr_crop !== null && this.state.rot_crop !== null) {
                            this.getCropRotation(this.state.rot_crop, this.state.cr_crop);
                         }
                    });
                }}>
                <option value='' />
                {this.state.cr_crops.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
            </TextField>
            <TextField
                label={i18n.t('Crop group')}
                select
                value={this.state.rot_crop_group}
                InputLabelProps={this.state.shrink  ? {shrink: true } : {shrink: false }}
                SelectProps={{ native: true, }}
                style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                onChange={(e) => {
                    this.setState({ rot_crop_group: e.target.value },  () => {
                        axios.get('api/crop/drop_down_subgroup_oneyear', { params: { rot_id_grupa: this.state.rot_crop_group } })
                            .then(res => { this.setState({ rot_crop_subgroups: res.data.data, kpg_disabled: false }); })
                            .catch(err => { console.log(err) })
                        axios.get('api/crop/drop_down_oneyear', {  params: { rot_id_grupa: this.state.rot_crop_group, rot_id_podgrupa: this.state.rot_crop_subgroup } })
                            .then(res => { this.setState({ rot_crops: res.data.data, kultura_disabled: false }); })
                            .catch(err => { console.log(err) })
                    if(!this.state.rot_crops.includes(this.state.rot_crop) && this.state.rot_crop_group !== ''){
                        this.setState({ rot_crop: null })
                        }
                        this.setState({shrink:true})
                            
                    });
                    
                }} >
                <option value='' />
                {this.state.rot_crop_groups.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}</TextField>
            <TextField
                label={i18n.t('Crop subgroup')}
                select
                disabled={this.state.kpg_disabled}
                value={this.state.rot_crop_subgroup}
                InputLabelProps={this.state.shrink  ? {shrink: true } : {shrink: false }}
                SelectProps={{ native: true, }}
                style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                onChange={(e) => {
                    this.setState({ rot_crop_subgroup: e.target.value }, () => {
                        axios.get('api/crop/drop_down_oneyear', { params: { rot_id_grupa: this.state.rot_crop_group, rot_id_podgrupa: this.state.rot_crop_subgroup } })
                            .then(res => { this.setState({ rot_crops: res.data.data, kultura_disabled: false }); })
                            .catch(err => { console.log(err) })
                        if(!this.state.rot_crops.includes(this.state.rot_crop) && this.state.rot_crop_subgroup !== ''){
                        this.setState({ rot_crop: null })
                        }
                    });
                    this.setState({shrink:true})

                }} >
                <option value='' />
                {this.state.rot_crop_subgroups.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
            </TextField>
            <TextField
                label={i18n.t('Crop')}
                select
                disabled={this.state.kultura_disabled}
                value={this.state.rot_crop}
                InputLabelProps={this.state.shrink  ? {shrink: true } : {shrink: false }}
                SelectProps={{ native: true, }}
                style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
                onChange={(e) => {
                    this.setState({ rot_crop: e.target.value }, () => {
                        if (this.state.cr_crop !== null && this.state.rot_crop !== null && this.state.rot_crop !== '') {
                            this.getCropRotation(this.state.rot_crop, this.state.cr_crop);
                        }
                    });
                    this.setState({shrink:true})

                }}>
                <option value='' />
                {this.state.rot_crops.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
            </TextField>
            <DialogContentText style={{ marginLeft: '10px', marginTop: '20px' }}>
                {i18n.t('Crop rotation is the practice of growing different crops in successive seasons on the same land, in order to preserve the productive capacity of the soil.')}
            </DialogContentText>
            {Object.keys(this.state.crop_rotation_tip).length > 0 && this.state.cr_crop !== null && this.state.rot_crop !== null && this.state.rot_crop !== '' && this.state.cr_crop !== '' &&
                <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                    {i18n.t('Crop rotation') + ' : ' + this.state.crop_rotation_tip[0].naziv}
                </DialogContentText>
            }
            {Object.keys(this.state.crop_rotation_tip).length === 0 && this.state.cr_crop !== null && this.state.rot_crop !== null && this.state.rot_crop !== '' && this.state.cr_crop !== '' &&
                <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                    {i18n.t('There is no crop rotation for a selected culture and preculture.')}
                </DialogContentText>
            }
        </DialogContent>
    </Dialog>
  </div>
    )
  }
}

