import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { login, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, IconButton, Link, Dialog } from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import RegisterPage from './RegisterPage';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}
const styles = theme => ({
    primaryMain: {
        backgroundColor: '#126038'
    },
});

export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            language: 'en'
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.logInFailed === true) {
            if (nextProps.message == 'password') {
                toast.error(('Incorrect password!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80,
                    theme: "light"
                });
            } else if (nextProps.message == 'enterprise') {
                toast.error(('Only enterprise can log in here!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else {
                toast.error(('Username or password are incorrect!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            }
            this.props.setInitialState('logInFailed');
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        // axios.get('api/season/activity_season')
        //     .then(res => console.log(res))
        //     .catch(err => console.log(err))
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }
    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.loginPressed()
        }
    }
    toastError() {
        toast.error(('Username or password are incorrect!'), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 80
        });
    }

    loginPressed() {
        if (this.state.username && this.state.password) {
            this.props.login(this.state.username, this.state.password)
        } else {
            toast.error(('Username and passwords are required.'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleClick = () => {
        this.props.history.push('forgotpass');
    };

    registerPressed = () => {
        this.props.history.push('registerPage');
    }

    render() {
        const { classes } = this.props;
        return (
            <div>

                <div className="login">
                    <Paper className="roundedBox" >
                        <div className={classnames(classes.primaryMain, "loginText")}>
                            LOGIN
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <div className="logo">
                                <img src={require('../../assets/images/login_agrolife.svg')} width="300" height="70" />
                            </div>
                            <TextField
                                label={('Username')}
                                required
                                style={{ width: '256px' }}
                                onChange={(event) => this.setState({ username: event.target.value })}
                            />
                            <br />
                            <TextField
                                type="password"
                                label={('Password')}
                                required
                                style={{ width: '256px' }}
                                onChange={(event) => this.setState({ password: event.target.value })}
                            />
                            <br />
                            <Button
                                className="loginButton"
                                variant="contained"
                                // color="secondary"
                                style={{ backgroundColor: '#126038', color: 'white' }}

                                onClick={() => this.loginPressed()}
                            >
                                {('login')}
                            </Button>
                            <br />
                            <Link
                                onClick={() => this.handleClick()}
                                variant="contained"
                                color="secondary"
                            >
                                {('Forgot password?')}
                            </Link>
                            <br />
                            <Button
                                className="loginButton"
                                variant="contained"
                                // color="secondary"
                                style={{ backgroundColor: '#126038', color: 'white' }}

                                onClick={() => this.registerPressed()}
                            >
                                {('Register account')}
                            </Button>
                            <br />
                            <div style={{ justifyContent: 'space-between', width: '100%', whiteSpace: 'pre-wrap', margin: '5px 0' }}>
                                <a href="https://manuals.agrolife.world/privacy_policy" target="blank" style={{ color: '#04764e' }}>Privacy policy</a>
                                <span>     </span>
                                <a href="https://manuals.agrolife.world/tos" target="blank" style={{ color: '#04764e' }}>Terms & Conditions</a>
                            </div>
                        </div>
                    </Paper>
                    {/* <div style={style}>
                        <a href="https://manuals.agrolife.world/privacy_policy" target="blank">Privacy policy </a>
                        |
                        <a href="https://manuals.agrolife.world/tos" target="blank">Terms & Conditions</a>
                        |
                        <span className="icon-Facebook loginIcon"></span>
                        <span className="icon-Twiter loginIcon"></span>
                        <span className="icon-Instagram loginIcon"></span>
                        <span className="icon-linkedin loginIcon"></span>
                        <span className="icon-G+ loginIcon"></span>
                        |<a href="http://agrolife.world" target="blank">www.agrolife.world</a>
                    </div> */}
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        logInFailed: state.appReducer.logInFailed,
        token: state.appReducer.token,
        message: state.appReducer.message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => dispatch(login(username, password)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage));