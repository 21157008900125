import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import {
    Button, IconButton, Card, CardHeader, CardContent, Typography,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip,
    Menu, MenuItem, InputLabel
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import immutable from 'seamless-immutable';
import ActivitiesMain from './ActivitiesMain';
import { withStyles } from '@material-ui/core/styles';
import {
    getActivities, setInitialState, getById, getWODropdown,
    deleteActivity, getStatusDropdown
} from '../../actions/activity/ActivityActions';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import { Delete } from '../../utils/Delete';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import ReactSelect from '../../utils/ReactSelect';
import axios from './../../utils/AxiosWrapper';
import NewSeasonAlert from './NewSeasonAlert';
import iconStyle from './activities_new/status.module.css';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import { DateRange } from 'react-date-range';
import { Route, Redirect } from 'react-router-dom';
import { wentWrong } from '../../utils/ToastConfig';
import NewPatch from './NewPatch';

const styles = theme => ({
    page: {
        width: '96%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
        height: '100%'
    },
    card: {
        width: '100%',
        boxShadow: '0 2px 4px rgba(154,160,185,0.05),  0 4px 8px rgba(166,173,201,0.2)',
        borderRadius: '8px'
    },
    cardHeader: {
        padding: "0.75rem 1.25rem",
        margin: "15px 15px -15px 15px",
        background: theme.palette.secondary.light,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // borderWidth: 0.1,
        // borderStyle: 'solid',
        // borderColor: '#f2f2f2',
        padding: '8px 0px 8px 20px'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    cardAction: {
        marginRight: 20,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    actionButton: {
        width: 50,
        height: 50,
        flexFlow: 'row wrap',
        alignItems: 'center'
    },
    search: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '40%',
        height: 40,
        borderRadius: '25px'
    },
    typography: {
        color: '#fff'
    },
    filterLabel: {
        margin: '10px',
        color: theme.palette.primary.main
    },
    activityDetail: {
        fontWeight: 400,
    },
    activityId: {
        backgroundColor: '#DCDCDC',
        width: '75px',
        display: 'flex',
        justifyContent: 'center'
    },
});
export class ActivityContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            delete: false,
            name: '',
            edit: false,
            searchValue: '',
            forSortValue: 'by field',
            anchorEl: null,
            new_season_alert: true,
            new_feature_alert: true,
            alert: false,
            alert_feature: false,
            new_edit: false,
            new_copy: false,
            propObj: {},
            filters: {
                date: {
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                }
            }
        };
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    componentDidMount() {
        let user = this.getUser();

        if (user.id_region === 2 || user.id_region === 20 || user.id_region === 23) {
            axios.get('api/season/new_season_alert')
                .then(res => this.setState({ new_season_alert: res.data.data[0].nova_sezona_alert }, () => {
                    if (this.state.new_season_alert === false) {
                        this.setState({ alert: true });
                    }
                }))
                .catch(err => console.log(err))
        }

        axios.get('api/season/new_feature')
            .then(res => this.setState({ new_feature_alert: res.data.data[0].new_design_alert }, () => {
                if (this.state.new_feature_alert === false) {
                    this.setState({ alert_feature: true });
                }
            }))
            .catch(err => console.log(err))

        localStorage.removeItem('row');
        const { clientProfile } = this.props;
        let { filters } = this.state;
        filters.id_sezona = clientProfile.id_default_sezona || null;
        this.setState({
            filters
        })
        this.props.getWODropdown();
        this.props.getFieldDropDown();
        this.props.getStatusDropdown();
        if (filters.id_sezona !== null) {
            this.props.getActivities(this.state.searchValue, filters);
        }

        axios.get('api/activity/sort_client', { params: { status: this.state.forSortValue } })
            .then(res => {
                if (res.data.data[0]) {
                    if (res.data.data[0].sort) {
                        this.setState({ forSortValue: res.data.data[0].sort })
                    }
                }
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }


    handleAlert = () => {
        this.setState({ alert: !this.state.alert });
    }

    handleAlertFeature = () => {
        this.setState({ alert_feature: !this.state.alert_feature });
    }

    goTo(path) {
        this.props.history.push(path);
    }

    componentDidUpdate(prevProps) {
        const { activity, fieldDropDown, woDropdown, statusDropdown, activityPostingSuccess, clientProfile, getActivities, deleteActivitySuccess } = this.props;
        let { filters } = this.state;
        if (prevProps.deleteActivitySuccess === false && deleteActivitySuccess === true) {
            this.setState({
                searchValue: ''
            })
            this.props.setInitialState('deleteActivitySuccess');
        }
        if (prevProps.activity !== activity) {
            this.setState({
                activity: activity
            }, () => {
                this.sortByProperty({ val: this.state.forSortValue })
            });
        }
        if (prevProps.fieldDropDown !== fieldDropDown) {
            this.setState({
                fieldDropDown: immutable.asMutable(fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.woDropdown !== woDropdown) {
            this.setState({
                woDropdown: immutable.asMutable(woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.statusDropdown !== statusDropdown) {
            this.setState({
                statusDropdown: immutable.asMutable(statusDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.activityPostingSuccess === false && activityPostingSuccess === true) {
            this.setState({
                add: false,
                searchValue: ''
            })
            this.props.setInitialState('activityPostingSuccess');
        }
        if (prevProps.clientProfile !== clientProfile) {
            filters.id_sezona = clientProfile.id_default_sezona;
            this.setState({
                filters
            })
            getActivities(this.state.searchValue, filters)
        }
    }

    handleClick = () => {
        if (this.state.edit === true && this.state.add === false) {
            this.setState({ edit: false })
        }
        this.props.setInitialState('activityObj');
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === true && this.state.edit === false) {
                this.setState({
                    name: '',
                })
            }
        });
    };

    onDeleteClick = (data) => {
        this.setState({
            delete: !this.state.delete,
            selection: [data.id],
            data: data
        });
    }

    deleteItem = () => {
        if (this.state.data.id_status !== 4) {
            this.props.deleteActivity(this.state.selection);
            this.setState({ delete: false, data: {} })
        } else {
            toast.error(i18n.t('A locked activity can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    onEditClick = (id) => {
        // this.props.getById(id);
        // this.setState({ add: !this.state.add, edit: true });
        axios.get(`api/activity/getById?id=` + id)
            .then(res => {
                let data = res.data.data;
                let obj = {
                    id: data.id,
                    id_status: data.id_status,
                    id_radna_operacija: data.id_radna_operacija,
                    datum_pocetka: data.datum_pocetka,
                    datum_zavrsetka: data.datum_zavrsetka,
                    vreme_pocetka: data.vreme_pocetka,
                    vreme_zavrsetka: data.vreme_zavrsetka,
                    table: data.table.map(item => {
                        if (item.tabla.value === undefined) {
                            let obj = item.tabla;
                            obj.value = item.tabla.id;
                            obj.label = item.tabla.naziv;
                            obj.id_tabla = item.tabla.id;
                            obj.obradjena_povrsina = item.obradjena_povrsina;
                            delete obj.id;
                            return obj;
                        }
                        else return item.tabla;
                    }),
                    materijali: data.materijali,
                    pogonskeMasine: data.pogonskeMasine.map(item => {
                        if (item.pogonska.value === undefined) {
                            let obj = item.pogonska;
                            obj.value = item.pogonska.id;
                            obj.label = item.pogonska.naziv;
                            obj.id_pogonska_masina = item.pogonska.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.pogonska;
                    }),
                    prikljucneMasine: data.prikljucneMasine.map(item => {
                        if (item.prikljucna.value === undefined) {
                            let obj = item.prikljucna;
                            obj.value = item.prikljucna.id;
                            obj.label = item.prikljucna.naziv;
                            obj.id_prikljucna_masina = item.prikljucna.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.prikljucna;
                    }),
                    komentar: data.komentar,
                    radna_operacija: data.radna_operacija.naziv
                };
                this.setState({ propObj: obj }, () => {
                    this.setState({ new_edit: true });
                })

            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    handleChangeOption = (val) => {
        if (val !== 0) {
            this.setState({ forSortValue: val }, () => {
                axios.get('api/activity/update_sort_client', { params: { status: val } })
                    .then(res => {
                        this.sortByProperty({ val: val });
                    })
                    .catch(() => wentWrong(i18n.t('Something went wrong')))
            });
            this.handleGroupByClose();
        }
    }

    handleGroupByClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleGroupByClose = () => {
        this.setState({ anchorEl: null });
    };
    handleFilterClick = event => {
        this.setState({ anchorElFilter: event.currentTarget });
    };

    handleFilterClose = () => {
        this.setState({ anchorElFilter: null });
    };

    sortByProperty = (val) => {
        let searchText = val;
        let forSort = this.state.activity;
        let sortedData = [];
        if (searchText.val === "by status") {
            let sorted = _.groupBy(forSort, d => d.id_status);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].status,
                    data: next
                })
                return accumulator;
            }, [])
        } else if (searchText.val === "by field") {
            let sorted;
            sorted = _.reduce(forSort, (accumulator, next, index) => {
                _.map(next.table, (ex, indexic, arr) => {
                    if (accumulator.idTable.length === 0) {
                        accumulator.idTable.push(ex.tabla.id);
                        accumulator.data.push({
                            title: ex.tabla.naziv,
                            data: [next]
                        });
                    }
                    else {
                        if (!accumulator.idTable.some((item) => {
                            return item === ex.tabla.id
                        })) {
                            accumulator.idTable.push(ex.tabla.id);
                            accumulator.data.push({
                                title: ex.tabla.naziv,
                                data: [next]
                            });
                        } else {
                            accumulator.data[accumulator.idTable.indexOf(ex.tabla.id)].data.push(next);
                        }
                    }
                });
                return accumulator;
            }, { idTable: [], data: [] });
            sortedData = sorted.data;
        } else if (searchText.val === "by working operation") {
            let sorted = _.groupBy(forSort, d => d.id_radna_operacija);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].radna_operacija,
                    data: next
                })
                return accumulator;
            }, [])
        } else if (searchText.val === "by date") {
            let sorted = _.groupBy(forSort, d => d.datum_pocetka);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].datum_pocetka,
                    data: next
                })
                return accumulator;
            }, [])
        }else if (searchText.val === "by season") {
            let sorted = _.groupBy(forSort, d => d.sezona);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].sezona,
                    data: next
                })
                return accumulator;
            }, [])
        }
        if (sortedData) {
            this.setState({ activityGrouped: sortedData, sorted: true });
        } else {
            // this.setState({ workOrders: this.props.workOrders, sorted: false }, function () {
            //     ToastAndroid.show(I18n.t("error"), ToastAndroid.SHORT);
            // })
        }
    }

    handleSearch = search => {
        this.setState({ searchValue: search }, () => {
            this.props.getActivities(this.state.searchValue, this.state.filters);
        })
    }
    handleChangeFilter = name => selectedOption => {
        let obj = Object.assign({}, this.state.filters);
        obj[name] = name === 'date' ? selectedOption : selectedOption.map((item) => {
            return item.value;
        });
        this.setState({ filters: obj });
    }

    handleCloseFilter = () => {
        this.setState({
            anchorElFilter: null,
        });
    };

    handleClearFilter = () => {
        const { clientProfile } = this.props;
        this.setState({
            filters: {
                date: {
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                },
                id_sezona: clientProfile.id_default_sezona
            }
        }, function () {
            this.props.getActivities(this.state.searchValue, this.state.filters);
        });
    };

    handleOkFilter = () => {
        this.props.getActivities(this.state.searchValue, this.state.filters);
    };

    // onCopyClick = (id) => {
    //     this.props.getById(id, { copy: true });
    //     if (this.state.edit === true && this.state.add === false) {
    //         this.setState({ edit: false })
    //     }
    //     this.setState({ add: !this.state.add })

    // }

    onCopyClick = (id) => {
        // this.props.getById(id);
        // this.setState({ add: !this.state.add, edit: true });
        axios.get(`api/activity/getById?id=` + id)
            .then(res => {
                let data = res.data.data;
                let obj = {
                    id_status: data.id_status,
                    id_radna_operacija: data.id_radna_operacija,
                    datum_pocetka: data.datum_pocetka,
                    datum_zavrsetka: data.datum_zavrsetka,
                    vreme_pocetka: data.vreme_pocetka,
                    vreme_zavrsetka: data.vreme_zavrsetka,
                    table: data.table.map(item => {
                        if (item.tabla.value === undefined) {
                            let obj = item.tabla;
                            obj.value = item.tabla.id;
                            obj.label = item.tabla.naziv;
                            obj.id_tabla = item.tabla.id;
                            obj.obradjena_povrsina = item.obradjena_povrsina;
                            delete obj.id;
                            return obj;
                        }
                        else return item.tabla;
                    }),
                    materijali: data.materijali,
                    pogonskeMasine: data.pogonskeMasine.map(item => {
                        if (item.pogonska.value === undefined) {
                            let obj = item.pogonska;
                            obj.value = item.pogonska.id;
                            obj.label = item.pogonska.naziv;
                            obj.id_pogonska_masina = item.pogonska.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.pogonska;
                    }),
                    prikljucneMasine: data.prikljucneMasine.map(item => {
                        if (item.prikljucna.value === undefined) {
                            let obj = item.prikljucna;
                            obj.value = item.prikljucna.id;
                            obj.label = item.prikljucna.naziv;
                            obj.id_prikljucna_masina = item.prikljucna.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.prikljucna;
                    }),
                    komentar: data.komentar,
                    radna_operacija: data.radna_operacija.naziv
                };
                this.setState({ propObj: obj }, () => {
                    this.setState({ new_edit: true });
                })

            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    render() {
        const { theme, classes } = this.props;
        const { activityGrouped, anchorEl, anchorElFilter, filters } = this.state;
        let activityList = () => {
            return !!activityGrouped ? activityGrouped.map((group, i) => {
                return (
                    <ExpansionPanel key={i}  >
                        <ExpansionPanelSummary
                            style={{ background: '#DCDCDC', border: '0' }}
                            expandIcon={<Icons.ExpandMore />}>
                            <Typography variant='h5'>{i18n.t(group.title) + ` (${group.data.length})`}</Typography>
                        </ExpansionPanelSummary>
                        <div style={{ maxHeight: '380px', overflowY: 'auto' }}>
                            {
                                !!group.data && group.data.map((data, i) => {
                                    let statusColor = '#58B4E5', statusText = i18n.t('Choose status'), statusIcon = './status/otvoren.svg';
                                    if (data) {
                                        if (data.id_status) {
                                            switch (data.id_status) {
                                                case 1:
                                                    statusColor = '#73db4d';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Finished')}`;
                                                    statusIcon = './status/zavrsen.svg';
                                                    break;
                                                case 2:
                                                    statusColor = '#f3e741';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('In progress')}`;
                                                    statusIcon = './status/utoku.svg';
                                                    break;
                                                case 3:
                                                    statusColor = '#058457';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Open ')}`;
                                                    statusIcon = './status/otvoren.svg';
                                                    break;
                                                case 4:
                                                    statusColor = '#e40f0f';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Locked')}`;
                                                    statusIcon = './status/zakljucan.svg';
                                                    break;
                                                case 5:
                                                    statusColor = '#2e63c5';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Planned')}`;
                                                    statusIcon = './status/planski.svg';
                                                    break;
                                                default:
                                                    statusColor = '#49c954';
                                                    statusText = i18n.t('Choose status');
                                                    statusIcon = './status/zavrsen.svg';
                                                    break;
                                            }
                                        }
                                    }
                                    return (
                                        <ExpansionPanelDetails className={classes.panel} key={i}>
                                            <Typography variant='h6' className="divcontainer">
                                                <div style={{
                                                    display: 'inline-flex',
                                                    backgroundColor: statusColor,
                                                    padding: '9px',
                                                    borderRadius: '3px',
                                                    borderColor: '#CCCCCC',
                                                    marginRight: '16px',
                                                    fontWeight: '500',
                                                    boxShadow: '1px 1px 2px 0 rgb(0 0 0 / 30%)',
                                                    color: 'white',
                                                    lineHeight: '20px',
                                                    width: 'fit-content',
                                                    userSelect: 'none'
                                                }}>
                                                    {data.id}
                                                    <img src={statusIcon} height="16px" className={iconStyle.icon_filter} style={{ padding: '0px 0 0 10px', marginTop: '2px' }} />
                                                </div>
                                                <p style={{ display: 'inline', fontWeight: '400' }}>{data.radna_operacija}: </p>
                                                <p style={{ display: 'inline' }}>{data.datum_pocetka}</p>
                                            </Typography>
                                            <div>
                                                <Tooltip title={i18n.t("Edit")}>
                                                    <IconButton aria-label="edit"
                                                        color="secondary"
                                                        onClick={() => this.onEditClick(data.id)}>
                                                        <Icons.Edit fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={i18n.t("Copy")}>
                                                    <IconButton aria-label="copy"
                                                        color="secondary"
                                                        onClick={() => this.onCopyClick(data.id)}>
                                                        <Icons.FileCopy fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={i18n.t("Delete")}>
                                                    <IconButton aria-label="Delete"
                                                        className={classes.cssRoot}
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => this.onDeleteClick(data)}>
                                                        <Icons.Delete fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </ExpansionPanelDetails>)
                                })
                            }
                        </div>
                    </ExpansionPanel>
                )
            }) : null;
        }
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
        return (
            <div className={classes.page}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            <div className={classes.row}>
                                <div style={{ marginRight: 20 }}>
                                    <Typography className={classes.typography} variant='h5'>{i18n.t('Activity')}</Typography>
                                    <Typography className={classes.typography} variant='h6'>{i18n.t(this.state.forSortValue)}</Typography>
                                </div>
                                <Tooltip title={i18n.t("Group By")}>
                                    <IconButton aria-label="groupBy"
                                        aria-owns={anchorEl ? 'groupBy-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.handleGroupByClick}>
                                        <span className="icon-StrelicaDole1 menuIcon2" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="groupBy-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleGroupByClose}
                                >
                                    <MenuItem onClick={() => this.handleChangeOption('by field')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Field')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by status')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Status')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by working operation')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Working operation')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by date')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Date')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by season')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Season')}</Typography>
                                    </MenuItem>
                                </Menu>
                                <SearchBar
                                    className={classes.search}
                                    placeholder={i18n.t('Search')}
                                    cancelOnEscape={true}
                                    value={this.state.searchValue}
                                    onChange={(searchValue) => !searchValue && this.handleSearch('')}
                                    onRequestSearch={(searchValue) => this.handleSearch(searchValue)}
                                    onCancelSearch={() => this.handleSearch('')}
                                />


                            </div>
                        }
                        action={
                            <div className="filter-add-rn">
                                <Tooltip title={i18n.t("Filter")}>
                                    <IconButton aria-label="filter"
                                        aria-owns={anchorElFilter ? 'filter-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.handleFilterClick}>
                                        <span className="icon-filter menuIcon2" />
                                    </IconButton>
                                </Tooltip>


                                <Menu
                                    id="filter-menu"
                                    anchorEl={anchorElFilter}
                                    open={Boolean(anchorElFilter)}
                                    onClose={this.handleFilterClose}
                                >
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Status')}
                                        maxMenuHeight={150}
                                        menuPlacement='auto'
                                        value={this.state.statusDropdown && this.state.statusDropdown.filter(function (element) {
                                            return filters.id_status && filters.id_status.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_status')}
                                        options={this.state.statusDropdown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                        styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }), clearIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    />
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Field')}
                                        maxMenuHeight={150}
                                        menuPlacement='auto'
                                        value={this.state.fieldDropDown && this.state.fieldDropDown.filter(function (element) {
                                            return filters.id_tabla && filters.id_tabla.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_tabla')}
                                        options={this.state.fieldDropDown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                        styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }), clearIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    />
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Working operation')}
                                        maxMenuHeight={150}
                                        value={this.state.woDropdown && this.state.woDropdown.filter(function (element) {
                                            return filters.id_radna_operacija && filters.id_radna_operacija.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_radna_operacija')}
                                        options={this.state.woDropdown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                        styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }), clearIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    />
                                    {/* <InputLabel className={classes.filterLabel}>{i18n.t('Date')}:</InputLabel> */}
                                    {/* <DateRange
                                        ranges={[filters.date.selection]}
                                        className={'centered'}
                                        onChange={this.handleChangeFilter('date')}
                                        preview={{ color: theme.palette.primary.main }}
                                    /> */}
                                    <div style={{ padding: '20px', paddingTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleOkFilter}>{i18n.t('Ok')}</Button>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleCloseFilter}>{i18n.t('Close')}</Button>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleClearFilter}>{i18n.t('Clear')}</Button>

                                    </div>
                                </Menu>
                                <Button
                                    variant="fab"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.actionButton}
                                    style={{ marginRight: '16px', marginBottom: '3px', marginLeft: '4px' }}
                                    // onClick={this.handleClick}
                                    onClick={() => this.goTo('/new_activity')}
                                >
                                    <AddIcon />
                                </Button>
                                {i18n.t('Add activity')}
                            </div>
                        }
                    />
                    <CardContent>
                        {activityList()}
                    </CardContent>
                </Card>
                {this.state.add && <ActivitiesMain add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.alert && <NewSeasonAlert alert={this.state.alert} handleClose={this.handleAlert} />}
                {this.state.alert_feature && <NewPatch alert={this.state.alert_feature} handleClose={this.handleAlertFeature} />}
                {this.state.new_edit && <Redirect from="/activities" to={{ pathname: "/new_activity", state: { propObj: this.state.propObj, edit: true } }} />}
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {
        activity: state.activityReducer.activity,
        activityFetching: state.activityReducer.activityFetching,
        activityPages: state.activityReducer.activityPages,
        activityObj: state.activityReducer.activityObj,
        activityPostingSuccess: state.activityReducer.activityPostingSuccess,
        woDropdown: state.activityReducer.woDropdown,
        statusDropdown: state.activityReducer.statusDropdown,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        fieldFetchingDropDown: state.fieldReducer.fieldFetchingDropDown,
        clientProfile: state.appReducer.clientProfile,
        deleteActivitySuccess: state.activityReducer.deleteActivitySuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getActivities: (search, filter) => dispatch(getActivities(search, filter)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getById: (id, opt) => dispatch(getById(id, opt)),
        getWODropdown: () => dispatch(getWODropdown()),
        deleteActivity: (selection) => dispatch(deleteActivity(selection)),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        getStatusDropdown: () => dispatch(getStatusDropdown()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityContainer));