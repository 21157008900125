import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './fields.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class Fields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: null,
            user_season: null,
            user_layer: null,
            fieldType: [],
            fieldList: [],
        };
    };

    componentDidMount() {
        axios.get('api/season/activity_season')
            .then(res => {
                this.setState({ user_season: res.data.data[0].id_default_sezona }, () => {
                    axios.get('api/layer/layer_by_season', { params: { season: this.state.user_season } })
                        .then(res => {
                            this.setState({ user_layer: res.data.data[0].id }, () => {
                                axios.get('api/field/field_list_season', { params: { layer: this.state.user_layer } })
                                    .then(res => {
                                        let listObj = [];
                                        let list = res.data.data.map(item => {
                                            let label = item.naziv + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)';
                                            let value = item.id;
                                            let id_tabla = item.id;
                                            let naziv = item.naziv;
                                            let povrsina = (Number(item.povrsina)).toFixed(2);
                                            let deo_table = item.deo_table;
                                            let obradjena_povrsina;
                                            if(this.props.activityObj.table !== undefined && this.props.activityObj.table.length > 0){
                                                if (this.props.activityObj.table.findIndex(item => item.id_tabla === id_tabla) !== -1) {
                                                    var index = this.props.activityObj.table.findIndex(item => item.id_tabla === id_tabla);
                                                    obradjena_povrsina = this.props.activityObj.table[index].obradjena_povrsina
                                                }else{
                                                    obradjena_povrsina = null;
                                                }
                                            }else{
                                                obradjena_povrsina = null;
                                            }
                                            listObj.push({ label, value, naziv, id_tabla, povrsina, deo_table, obradjena_povrsina});

                                            return { label, value, povrsina, obradjena_povrsina };
                                        });
                                        this.setState({ fieldType: list, fieldList: listObj });
                                    })
                                    .catch(() => wentWrong('Something went wrong'))
                            })
                        })
                        .catch(() => wentWrong('Something went wrong'))
                })
            })
            .catch(() => wentWrong('Something went wrong'))

        if (this.props.activityObj.table) {
            let temp = this.props.activityObj.table.map(item => {
                let obj = {};
                obj.label = item.label;
                obj.value = item.value;
                obj.obradjena_povrsina = item.obradjena_povrsina;
                return obj;
            });
            this.setState({ fields: temp });
        }
    };

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.fields) {
            if (this.state.fields.length > 0) {
                let fieldObj = [];
                let selectedIds = [];

                this.state.fields.map(item => selectedIds.push(item.value));
                this.state.fieldList.map((item, i) => { if (selectedIds.includes(item.value)) fieldObj.push(item) });

                this.props.handleFieldsObj(fieldObj);
            }
            else wentWrong('You have to select at least one field');
        }
        else wentWrong('You have to select at least one field');
    };


    getSumByKey = (arr, key) => {
        return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let area = 0;
        if (this.state.fields) area = (this.getSumByKey(this.state.fields, 'povrsina')).toFixed(2);
        let user = this.getUser()

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {user.id_region === 29 ? i18n.t('Add working fields') : i18n.t('Add fields') }
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={false}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.fields}
                                label={i18n.t('Field')}
                                maxMenuHeight={320}
                                onChange={(e) => this.setState({ fields: e })}
                                options={this.state.fieldType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }), clearIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                placeholder={i18n.t('Select')}
                            />
                        </div>
                        <div className={styles.flex}>
                            {this.state.fields && area > 0 && <div className={styles.font}>{i18n.t('Total land area')}: {area} ha</div>}
                            <div></div>
                            <div className={styles.center}>
                                <Button onClick={() => { this.setState({ fields: this.state.fieldType }) }} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Select all')}</Button>
                                <Button onClick={() => this.handleAdd()} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};