import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './products.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            matList: [],
            materialGroupType: [],
            materialGroup: null,
            materialType: [],
            material: null,
            unitType: [],
            unit: null,
            amount: null,
            addDisabled: true,
        };
    };

    componentDidMount() {
        axios.get('api/material_group/drop_down')
            .then(res => {
                let data = res.data.data;
                let label = '';
                let value = null;

                let materialGroupType = data.map((item, i) => {
                    if (item.naziv === 'Product' || item.naziv === 'Proizvodi žetve/berbe' || item.naziv === 'Proizvodi žetve i berbe' || item.naziv === 'Žetveni izdelki') {
                        label = item.naziv;
                        value = item.id;
                    }
                });

                materialGroupType = [{ label, value }];
                this.setState({ materialGroupType }, () => {
                    this.setState({ materialGroup: this.state.materialGroupType[0] }, () => {
                        axios.get('api/material/drop_down', { params: { id_grupa: this.state.materialGroup.value } })
                            .then(res => {
                                let data = res.data.data;
                                let matList = [];
                                let materialType = data.map(item => {
                                    let label = item.label;
                                    let value = item.value;
                                    let id_unit = item.id_unit;
                                    let indikator_prinosa = item.indikator_prinosa;
                                    let naziv = item.label;
                                    let id_materijal = item.value;
                                    // let jedinica_mere 
                                    // let utroseno
                                    // let prinos
                                    matList.push({ label, value, naziv, indikator_prinosa, id_materijal, id_unit });
                                    return { label: item.label, value: item.value, id_unit };
                                });
                                this.setState({ materialType, matList });
                            })
                            .catch(() => wentWrong('Something went wrong'))
                    });
                });
            })
            .catch(() => wentWrong('Something went wrong'))

        axios.get('api/unit/drop_down_product_units')
            .then(res => {
                let data = res.data.data;
                console.log(data)
                let unitType = data.map(item => { return { label: item.naziv, value: item.id } });
                console.log(unitType)
                this.setState({ unitType });
            })
            .catch(() => wentWrong('Something went wrong'))
    };

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.material) {
            if (this.state.unit) {
                if (this.state.amount) {
                    if (this.state.amount > 0) {

                        let matObj = {};

                        let data = this.state.matList.filter(item => item.value === this.state.material.value);
                        matObj = data[0];
                        matObj.jedinica_mere = this.state.unit.label;
                        matObj.id_unit = this.state.unit.value;
                        matObj.utroseno = null;
                        matObj.prinos = Number(this.state.amount);

                        this.props.handleProductsObj(matObj);
                    }
                    else wentWrong(i18n.t('Amount must be more than 0'));
                }
                else wentWrong(i18n.t('You have to enter an amount'));
            }
            else wentWrong(i18n.t('You have to choose a unit'));
        }
        else wentWrong(i18n.t('You have to choose a material'));
    };

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add product')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ height: '10px' }} />
                        <div className={styles.content}>
                            {/* <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={false}
                                isMulti={false}
                                value={this.state.materialGroup}
                                label={i18n.t('Material group')}
                                maxMenuHeight={250}
                                onChange={(e) => this.setState({ materialGroup: e }, () => {
                                    axios.get('api/material/drop_down', { params: { id_grupa: this.state.materialGroup.value } })
                                        .then(res => {
                                            let data = res.data.data;
                                            let matList = [];
                                            let materialType = data.map(item => {
                                                let label = item.label;
                                                let value = item.value;
                                                // let id_unit = item.id_unit;
                                                let indikator_prinosa = item.indikator_prinosa;
                                                let naziv = item.label;
                                                let id_materijal = item.value;
                                                // let jedinica_mere 
                                                // let utroseno
                                                // let prinos
                                                matList.push({ label, value, naziv, indikator_prinosa, id_materijal });
                                                return { label: item.label, value: item.value };
                                            });
                                            this.setState({ materialType, matList });
                                        })
                                        .catch(() => wentWrong('Something went wrong'))
                                })}
                                options={this.state.materialGroupType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            /> */}
                            <div style={{ height: '10px' }} />
                            {this.state.materialType.length > 0 &&
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isMulti={false}
                                    value={this.state.material}
                                    label={i18n.t('Yield')}
                                    maxMenuHeight={240}
                                    onChange={(e) => this.setState({ material: e }, () => {
                                        let unit = this.state.unitType.filter(item => item.value === e.id_unit)
                                        this.setState({ unit: unit[0] })
                                    })}
                                    options={this.state.materialType}
                                    className="multi-select-activity-form-new2"
                                    classNamePrefix="select"
                                    styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    placeholder={i18n.t('Select')}
                                />
                            }
                            <div style={{ height: '20px' }} />
                            {this.state.material &&
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isMulti={false}
                                    value={this.state.unit}
                                    label={i18n.t('Unit')}
                                    maxMenuHeight={200}
                                    onChange={(e) => this.setState({ unit: e })}
                                    options={this.state.unitType}
                                    className="multi-select-activity-form-new3"
                                    classNamePrefix="select"
                                    styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                    placeholder={i18n.t('Select')}
                                />
                            }
                            <div style={{ height: '20px' }} />
                            {this.state.unit &&
                                <TextField
                                    label={i18n.t('Amount')}
                                    value={this.state.amount}
                                    type="number"
                                    style={{ width: '510px', marginLeft: '20px' }}
                                    onChange={(e) => {
                                        this.setState({ amount: e.target.value }, () => {
                                            this.setState({ addDisabled: false });
                                        })
                                    }} >
                                </TextField>
                            }
                        </div>
                        <div className={styles.center}>
                            {/* <Button onClick={() => { this.setState({ fields: this.state.fieldType }) }} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Select all')}</Button> */}
                            <Button onClick={() => this.handleAdd()} disabled={this.state.addDisabled} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};