import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText, FormGroup } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import AlertArchive from './AlertArchive';
import 'react-toastify/dist/ReactToastify.css';
import { areaOfParts, availableSurface } from "../../../utils/ParcelArea";
import CropRotation from "./CropRotation"
export default class AddWorkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            kultura_grupa: null, cropGroupType: [],
            kultura_podgrupa: null, cropSubgroupType: [], kpg_disabled: true,
            kultura: null, cropType: [], boja: '', kultura_disabled: true,
            kultura_sorta: null, hybrydType: [], hybryd_disabled: true,
            katastarska_opstina: null, localGovType: [],
            layer: null, layerType: [],
            vrsta_proizvodnje: null, productionType: [],
            naziv: null, povrsina: null, id: null,
            semenska_proizvodnja: false, has_activity: false, geometry: false,
            povrsina: null, user_season: null, field_list: [], field: null, new: false, crop_rotation: false, crop_rotation_oneyear: false,
            cr_crop: null, cr_crops: [],
            crop_group: null, crops_group: [],
            crop_subgroup: null, crops_subgroup: [],
            crop: null, crops: [], crop_rotation_tip: {}, boja: null, boja_table: null,
            archived_crop: null,
            user_id_region: null, broj_cokota: null,
            availableArea:"",
            alert: false,
            add: this.props.add,
            edit: this.props.edit,
            parcels: [],
            parcel: "",
            user: this.getUser(),
            checkBoxActive: false,
            promjenaParcele: false
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    };

    onCloseRotation = () => { this.setState({ crop_rotation_oneyear: !this.state.crop_rotation_oneyear }); };

    onInputchange(event) {
        this.setState({
          povrsina: event.target.value
        });
      }

    async showAvailableSurface(id) {
        const povrsinaParcele = this.state.parcels.find(parcel => parcel.id==id).povrsina;
        let idTable = null;
        if(this.props.data==undefined){
            idTable = this.state.field
        }
        else {
            idTable = this.props.data.id
        }
        let preostalo = await availableSurface(id, idTable, this.state.povrsina, povrsinaParcele)
        this.setState({ availableArea: preostalo.toFixed(4), showAvaliable: true});
        if(this.state.edit && parseFloat(this.state.povrsina) >= (parseFloat(this.state.availableArea)+parseFloat(this.state.povrsina)) && this.state.promjenaParcele != true){
            this.setState(function(state, props){return {povrsina: parseFloat(this.state.availableArea)+parseFloat(this.state.povrsina)}})
        }
        else if(this.state.edit && parseFloat(this.state.povrsina) >= (parseFloat(this.state.availableArea)+parseFloat(this.state.povrsina)) && parseFloat(this.state.availableArea) == 0){
            this.setState(function(state, props){return {povrsina: 0}})
        }
        else if(parseFloat(this.state.povrsina) >= parseFloat(this.state.availableArea) && this.state.promjenaParcele == true){
                this.setState(function(state, props){return {povrsina: parseFloat(this.state.availableArea).toFixed(4)}})
        } 
    }

    onCheckBoxChange(event){
       this.setState(function(state, props){return {checkBoxActive: !state.checkBoxActive}})
       if(event.target.checked){
        this.setState(function(state, props) {
            if (this.state.edit) {
                return {
                    povrsina: (parseFloat(state.availableArea) + parseFloat(this.props.data.povrsina)).toFixed(4)
                }
            }
            else{
                return {
                    povrsina: state.availableArea
                }
            }
            
        })
       }
       else{
        if(this.state.edit){
            this.setState({
                povrsina: this.props.data.povrsina 
            })
        }
        else{
        this.setState({
            povrsina: ""
        })
    }
       }

    }

    // getUser() {
    //     let user = localStorage.getItem('user');
    //     let userObject = JSON.parse(user);
    //     !user && window.location.replace(window.location.origin);
    //     return userObject;
    // }
    

    componentDidMount() {
        let user = this.getUser();
        //this.getByURL('parcela/parcel_list_drop_down', 'parcels');
        if(user.id_region === 29){
        axios.get('api/parcela/parcel_list_drop_down').then(res => {
            this.setState({parcels: res.data.data})
            if(this.state.edit && this.props.data.parcela_id != null){ //u slucaju edita tabele koja nema parcelu iz nekog razloga
            this.showAvailableSurface(this.props.data.parcela_id)
            }
        })
        }
        
        this.setState({ user_id_region: user.id_region })
        this.getByURL('crop_group/drop_down', 'cropGroupType');
        this.getByURL('vrsta_proizvodnje/drop_down', 'productionType');

        axios.get('api/katastarska_opstina/drop_down', { params: { id_opstina: user.id_opstina } })
            .then(res => { this.setState({ localGovType: res.data.data }) })

        if (this.props.povrsina) {
            this.setState({ povrsina: this.props.povrsina });

            let seasons = [];
            if (this.props.seasons) this.props.seasons.map((item, i) => { seasons.push(item.value) })

            axios.get('api/field/field_list_no_geom', { params: { seasons } })
                .then(res => {
                    let field_list = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        field_list.push({ id: res.data.data[i].id, name: res.data.data[i].naziv });
                    }
                    this.setState({ field_list });
                })
                .catch(err => { console.log(err) })
        }

        if (this.state.edit === true) {
            let has_activity = false;
            if (this.props.data.has_activity === "0") has_activity = false;
            else has_activity = true;

            let geometry = false;
            if (this.props.data.geom_mobile === null) geometry = false;
            else geometry = true;
            axios.get('api/crop_subgroup/drop_down', { params: { id_grupa: this.props.data.id_kultura_grupa } })
                .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                .catch(err => { console.log(err) })

            axios.get('api/crop/drop_down', { params: { id_podgrupa: this.props.data.id_kultura_podgrupa } })
                .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                .catch(err => { console.log(err) })

            axios.get('api/crop/hybryd_drop_down', { params: { id_kultura: this.props.data.id_kultura } })
                .then(res => { this.setState({ hybrydType: res.data.data, hybryd_disabled: false }); })
                .catch(err => { console.log(err) })

            this.setState({
                boja: this.props.data.boja,
                geometry: geometry,
                has_activity: has_activity,
                id: this.props.data.id,
                katastarska_opstina: this.props.data.id_katastarska_opstina,
                kultura: this.props.data.id_kultura,
                kultura_grupa: this.props.data.id_kultura_grupa,
                kultura_podgrupa: this.props.data.id_kultura_podgrupa,
                kultura_sorta: this.props.data.id_kultura_sorta,
                layer: this.props.data.id_layer,
                vrsta_proizvodnje: this.props.data.id_vrsta_proizvodnje,
                naziv: this.props.data.naziv,
                povrsina: this.props.data.povrsina,
                semenska_proizvodnja: this.props.data.semenska_proizvodnja,
                broj_cokota: this.props.data.broj_cokota,
                parcel: this.props.data.parcela_id,
                
                
            }, () => {
                if (this.state.kultura) {
                    axios.get('api/crop/kultura_boja', { params: { id_crop: this.state.kultura } })
                        .then(res => {
                            if (res.data.data[0].boja_table !== null) {
                                function hexToRgb(h) { return ['0x' + h[1] + h[2] | 0, '0x' + h[3] + h[4] | 0, '0x' + h[5] + h[6] | 0] };
                                let boja = hexToRgb(res.data.data[0].boja_table);
                                boja = boja[0] + ', ' + boja[1] + ', ' + boja[2];
                                this.setState({ boja, boja_table: res.data.data[0].boja_table });
                            }
                            else {
                                this.setState({ boja: null, boja_table: null })
                            }
                        })
                        .catch(err => { console.log(err) })
                        
                }
                
            });
            
        }
    };

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    };
    archiveById(fieldId){
     axios.post(`api/field/archive_by_id`, {id: fieldId})
         .then(res => {
            this.setState({ alert: true });
         })
         .catch(err => {
             this.setState({ delete: false });
             console.log('Error: ', err);
         })
    }
    addField() {
        if (this.state.naziv && this.state.povrsina || this.state.field && this.state.povrsina) {
            if(this.state.povrsina != 0){
            const { onSave, handleClose } = this.props;
            
            let data = {};

            if (this.state.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 14 } })
            else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 9 } })

            if (this.state.edit === false && this.state.field === null && this.props.povrsina === undefined) {
                

                data = {
                    id_katastarska_opstina: (this.state.katastarska_opstina),
                    id_kultura_grupa: (this.state.kultura_grupa),
                    id_kultura_podgrupa: (this.state.kultura_podgrupa),
                    id_kultura: (this.state.kultura),
                    id_kultura_sorta: (this.state.kultura_sorta),
                    id_layer: (this.props.layer),
                    naziv: this.state.naziv,
                    povrsina: this.state.povrsina,
                    semenska_proizvodnja: this.state.semenska_proizvodnja,
                    id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                    broj_cokota: (this.state.broj_cokota),
                    id_parcela: (this.state.parcel),
                    id_predkultura: this.state.archived_crop
                };
                
                let boja = null;
                if (this.state.boja_table !== null) boja = this.state.boja_table;
                data.boja = boja;

                axios.post('api/field/create', data)
                    .then(res => {
                        wentRight(i18n.t('Successfully added a field'));
                        handleClose();
                    })
                    .catch(err => {
                        console.log(err);
                        wentWrong(i18n.t('Something went wrong, please try again!'))
                    })
            }
            else if (this.state.edit === true && this.state.field === null && this.props.povrsina === undefined) {
                data = {
                    id: (this.props.data.id),
                    id_katastarska_opstina: (this.state.katastarska_opstina),
                    id_kultura_grupa: (this.state.kultura_grupa),
                    id_kultura_podgrupa: (this.state.kultura_podgrupa),
                    id_kultura: (this.state.kultura),
                    id_kultura_sorta: (this.state.kultura_sorta),
                    id_layer: (this.props.layer),
                    naziv: this.state.naziv,
                    povrsina: this.state.povrsina,
                    semenska_proizvodnja: this.state.semenska_proizvodnja,
                    id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                    broj_cokota: (this.state.broj_cokota),
                    id_parcela: (this.state.parcel)
                };

                let boja = null;
                if (this.state.boja_table !== null) boja = this.state.boja_table;
                data.boja = boja;

                axios.post('api/field/create', data)
                    .then(res => {
                        wentRight(i18n.t('Successfully added a field'));
                        handleClose();
                    })
                    .catch(err => {
                        console.log(err);
                        wentWrong(i18n.t('Something went wrong, please try again!'))
                    })
            }

            if (this.props.povrsina !== undefined && this.state.naziv !== null && this.state.field === null) {
                data = {
                    id_katastarska_opstina: (this.state.katastarska_opstina),
                    id_kultura_grupa: (this.state.kultura_grupa),
                    id_kultura_podgrupa: (this.state.kultura_podgrupa),
                    id_kultura: (this.state.kultura),
                    id_kultura_sorta: (this.state.kultura_sorta),
                    id_layer: (this.props.layer),
                    naziv: this.state.naziv,
                    povrsina: this.state.povrsina,
                    semenska_proizvodnja: this.state.semenska_proizvodnja,
                    id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                    broj_cokota: (this.state.broj_cokota),
                    id_parcela: (this.state.parcel)
                };

                let boja = null;
                if (this.state.boja_table !== null) boja = this.state.boja_table;
                data.boja = boja;

                onSave(data);
            }
            else if (this.props.povrsina !== undefined && this.state.naziv === null && this.state.field !== null) {
                let field_obj = {};
                for (let i = 0; i < this.state.field_list.length; i++) {
                    if (Number(this.state.field) === this.state.field_list[i].id) {
                        field_obj = this.state.field_list[i];
                    }
                }

                data = {
                    id: field_obj.id,
                    id_katastarska_opstina: (this.state.katastarska_opstina),
                    id_kultura_grupa: (this.state.kultura_grupa),
                    id_kultura_podgrupa: (this.state.kultura_podgrupa),
                    id_kultura: (this.state.kultura),
                    id_kultura_sorta: (this.state.kultura_sorta),
                    id_layer: (this.props.layer),
                    naziv: field_obj.naziv,
                    povrsina: this.state.povrsina,
                    semenska_proizvodnja: this.state.semenska_proizvodnja,
                    id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                    broj_cokota: (this.state.broj_cokota),
                };

                let boja = null;
                if (this.state.boja_table !== null) boja = this.state.boja_table;
                data.boja = boja;

                onSave(data);
            }
        }
        else wentWrong('Area equals 0!');
        }
        else wentWrong('Please enter all required fields!');
    };

    getCropRotation = (id_kultura, id_predkultura) => {
        axios.get('api/field/getPlodored', { params: { id_kultura: id_kultura, id_predkultura: id_predkultura } })
            .then(res => { this.setState({ crop_rotation_tip: res.data.data }); })
            .catch(err => console.log(err))
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }
    addAfterCulture = (data) => {
        delete data.id;
        this.setState({add: true})
        this.setState({edit: false})
        this.setState({archived_crop: data.id_kultura})
        this.setState({cr_crop: data.id_kultura})
        this.setState({kultura_grupa: ''})
        this.setState({kultura_podgrupa: ''})
        this.setState({kultura: ''})
        this.setState({kultura_sorta: ''})


    }
    closeEditDialog = () => {
         this.props.handleClose()
    }
    handleCloseAlert = () => {
                this.setState({ alert: !this.state.alert });
                this.props.handleCloseArchived()
            }

    openCropRotation(){
        this.setState({ crop_rotation_oneyear: true })
    }
    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.state.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.state.edit ? i18n.t('Edit field') : i18n.t('Add field')}
                        {this.state.field_list.length > 0 &&
                            < div style={{ display: 'flex' }}>
                                <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('New')}</Typography>
                                <Switch
                                    checked={this.state.new}
                                    onChange={(e) => {
                                        this.setState({ new: !this.state.new });
                                        if (this.state.naziv === null) {
                                            this.setState({ field: null });
                                        }
                                        else this.setState({ naziv: null });
                                    }}
                                    color="default"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('Existing')}</Typography>
                            </div>
                        }
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {this.state.new === false &&
                                <TextField required
                                    label={i18n.t('Title')}
                                    value={this.state.naziv}
                                    style={{ width: '540px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ naziv: e.target.value, field: null }); }} >
                                </TextField>
                            }
                            </div>
                            {this.state.user.id_region === 29 && !this.state.new &&//this.state.field_list.length <= 0 &&
                            <Grid item xs={12}>
                                    <TextField required
                                        label={i18n.t('Parcel')}
                                        select
                                        value={this.state.parcel}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '532px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}
                                        onChange={(e) => {
                                            this.state.promjenaParcele = true
                                            this.setState({ parcel: e.target.value})
                                            this.showAvailableSurface(e.target.value)
                                            this.setState({checkBoxActive: false})
                                            if(this.state.edit){
                                                this.props.data.povrsina=0
                                                //this.setState(function() {this.setState({povrsina: "0"})})
                                                
                                            }
                                        // this.availableFields(e.target.value);
                                        }} >
                                       <option value='' disabled hidden />
                                        {this.state.parcels.map((parcel) => <option key={parcel.id} value={parcel.id}>{parcel.broj}</option>)}
                                        
                                    
                                    </TextField>
                                </Grid>
                            }


                            {/* <div style={{ display: 'flex', justifyContent: 'left' }}>
                            {this.state.new === false &&
                            <TextField required
                                        label={i18n.t('Parcel')}
                                        select
                                        value={this.state.tabla}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '540px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ tabla: e.target.value, })
                                        this.showAvailableSurface(e.target.value)
                                        // this.availableFields(e.target.value);
                                        }} >
                                        <option value='' />
                                        {this.state.table.map((table) => <option key={table.id} value={table.id}>{table.naziv}</option>)}
                                    </TextField>
                                     }
                                    </div> */}
                                
                                {/* {this.state.user.id_region === 29 && this.state.povrsina !== null && this.state.field_list.length > 0 && this.state.new === true &&
                                <Grid>
                                    <TextField
                                        label={i18n.t('Save geometry to field')}
                                        select
                                        value={this.state.field}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ field: e.target.value, naziv: null }); }}>
                                        <option value='' />
                                        {this.state.field_list.map((fl) => { return <option key={fl.id} value={fl.id}>{fl.name}</option> })}
                                    </TextField>
                                </Grid>
                            } */}


                            <div style={{display: 'flex'}}>
                            <div style={{flexDirection:  this.state.user.id_region === 29 ? 'column': 'row'}}>

                            {this.state.user.id_region === 29 &&
                                <TextField required 
                                type="number" 
                                pattern="[0-9]*"
                                placeholder={i18n.t('Land area') + " *"} //placeholder pa ne prikazuje zvjezdicu</div>
                                value={this.state.povrsina}
                                InputProps={{inputProps: {
                                    min: 0, 
                                    max:this.state.edit ? (parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina)) : this.state.availableArea}}
                                }
                                style={{ width: '256px', marginBottom: '10px', marginTop: !this.state.new ? '20px' : '30px', marginLeft: '10px' }}
                                onChange={(e) => { 
                                    if(e.target.value >= 0 ){
                                        if(this.state.edit){
                                            this.setState({ povrsina: (parseFloat(e.target.value == "" ? 0 : e.target.value).toFixed(4)  <= parseFloat(parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina)))  ? parseFloat(e.target.value) : parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina) }); 
                                        }
                                        else{
                                            this.setState({ povrsina: (parseFloat(e.target.value == "" ? 0 : e.target.value)  <= parseFloat(this.state.availableArea))  ? e.target.value : this.state.availableArea }); 
                                        }
                                    }
                                }} >
                            </TextField>
                            }
                            {this.state.user.id_region === 29 && !this.state.new &&
                            <label
                                style={{ display: 'block', width: '256px', marginTop: '-10px', marginLeft: '10px', marginBottom: '-5px' }}
                                >{i18n.t('Available surface')+ ': ' + this.state.availableArea +'(ha)'}</label>
                            }

                                {this.state.user.id_region !== 29 &&
                            <TextField required
                                label={i18n.t('Land area')}
                                value={this.state.povrsina}
                                type="number"
                                style={{ width: '256px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px' }}
                                onChange={(e) => { this.setState({ povrsina: e.target.value }); }} >
                            </TextField>
                            }
                            
                        

                            
                            </div>

                            
                        
                        <div>

                        {this.state.user.id_region === 29 && !this.state.new &&
                        <Grid xs={6}>
                                    <FormGroup>
                                    <FormControlLabel control={<Checkbox 
                                    checked={this.state.checkBoxActive} onChange={this.onCheckBoxChange}></Checkbox>} label={i18n.t('Use whole field')} 
                                    style={{ width: '256px', marginTop: '10px', marginLeft: '10px', marginBottom: '5px', }}></FormControlLabel>
                                    
                                    </FormGroup>
                                </Grid>
                                }

                               

                            

                        </div>

                            
                            

                           

                        {/* <TextField
                            label={i18n.t('Local government')}
                            select
                            value={this.state.katastarska_opstina}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                            onChange={(e) => { this.setState({ katastarska_opstina: e.target.value }); }} >
                            <option value='' />
                            {this.state.localGovType.map((lg) => <option key={lg.id} value={lg.id}>{lg.naziv}</option>)}
                        </TextField> */}
                        

                        {this.state.user.id_region !== 29 &&
                            <TextField
                            label={i18n.t('Number of seedlings')}
                            //disabled={ this.state.cropType == 'Vinova loza'}
                            value={this.state.broj_cokota ? this.state.broj_cokota : null}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginLeft: '20px', marginBottom: '10px',  marginTop: '20px'}}
                            onChange={(e) => {
                                this.setState({ broj_cokota: e.target.value }, () => {});
                            }}>
                            
                            </TextField>
                            }
                            </div>


                            {this.state.povrsina !== null && this.state.field_list.length > 0 && this.state.new === true &&
                                <div>
                                    <TextField
                                        label={i18n.t('Save geometry to field')}
                                        select
                                        value={this.state.field}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '532px', marginBottom: '10px', marginLeft: '10px', marginTop: '20px', marginRight: '10px' }}
                                        onChange={(e) => { this.setState({ field: e.target.value, naziv: null }); }}>
                                        <option value='' />
                                        {this.state.field_list.map((fl) => { return <option key={fl.id} value={fl.id}>{fl.name}</option> })}
                                    </TextField>
                                </div>
                            }
                            

                            {this.state.user.id_region === 29 && !this.state.new &&
                            <TextField
                            label={i18n.t('Number of seedlings')}
                            //disabled={ this.state.cropType == 'Vinova loza'}
                            value={this.state.broj_cokota ? this.state.broj_cokota : null}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginLeft: '10px', marginBottom: '10px',  marginTop: '10px'}}
                            onChange={(e) => {
                                this.setState({ broj_cokota: e.target.value }, () => {});
                            }}>
                            
                            </TextField>
                            }

                            {this.state.user.id_region !== 29 &&
                     <TextField
                            label={i18n.t('Local government')}
                            select
                            value={this.state.katastarska_opstina}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
                            onChange={(e) => { this.setState({ katastarska_opstina: e.target.value }); }} >
                            <option value='' />
                            {this.state.localGovType.map((lg) => <option key={lg.id} value={lg.id}>{lg.naziv}</option>)}
                        </TextField> }

                        { (this.state.user.id_region !== 29 || (this.state.user.id_region === 29 && !this.state.new)) &&
                        <TextField
                            label={i18n.t('Type of production')}
                            select
                            value={this.state.vrsta_proizvodnje}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginBottom: '10px', marginLeft: '20px', marginTop: '10px' }}
                            onChange={(e) => { this.setState({ vrsta_proizvodnje: e.target.value }); }} >
                            <option value='' />
                            {this.state.productionType.map((pt) => <option key={pt.id} value={pt.id}>{pt.naziv}</option>)}
                        </TextField>
                        }

                        { (this.state.user.id_region !== 29 || (this.state.user.id_region === 29 && !this.state.new)) &&
                        <TextField
                            label={i18n.t('Crop group')}
                            select
                            value={this.state.kultura_grupa}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
                            onChange={(e) => {
                                this.setState({ kultura_grupa: e.target.value }, () => {
                                    axios.get('api/crop_subgroup/drop_down', { params: { id_grupa: this.state.kultura_grupa } })
                                        .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                        .catch(err => { console.log(err) })
                                });
                            }} >
                            <option value='' />
                            {this.state.cropGroupType.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}
                        </TextField>
                         }

                        { (this.state.user.id_region !== 29 || (this.state.user.id_region === 29 && !this.state.new)) &&
                        <TextField
                            label={i18n.t('Crop subgroup')}
                            select
                            disabled={this.state.kpg_disabled}
                            value={this.state.kultura_podgrupa}
                            SelectProps={{ native: true, }}
                            style={{ width: '256px', marginBottom: '10px', marginLeft: '20px', marginBottom: '10px', marginTop: '10px' }}
                            onChange={(e) => {
                                this.setState({ kultura_podgrupa: e.target.value }, () => {
                                    axios.get('api/crop/drop_down', { params: { id_podgrupa: this.state.kultura_podgrupa } })
                                        .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                                        .catch(err => { console.log(err) })
                                });
                            }} >
                            <option value='' />
                            {this.state.cropSubgroupType.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
                        </TextField>
                     }


                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                        { (this.state.user.id_region !== 29 || (this.state.user.id_region === 29 && !this.state.new)) &&
                            <TextField
                                label={i18n.t('Crop')}
                                select
                                disabled={this.state.kultura_disabled}
                                value={this.state.kultura}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginLeft: '10px' }}
                                onChange={(e) => {
                                    this.setState({ kultura: e.target.value }, () => {
                                        axios.get('api/crop/kultura_boja', { params: { id_crop: this.state.kultura } })
                                            .then(res => {
                                                if (res.data.data[0].boja_table !== null) {
                                                    function hexToRgb(h) { return ['0x' + h[1] + h[2] | 0, '0x' + h[3] + h[4] | 0, '0x' + h[5] + h[6] | 0] };
                                                    let boja = hexToRgb(res.data.data[0].boja_table);
                                                    boja = boja[0] + ', ' + boja[1] + ', ' + boja[2];
                                                    this.setState({ boja, boja_table: res.data.data[0].boja_table });
                                                }
                                                else {
                                                    this.setState({ boja: null, boja_table: null })
                                                }
                                            })
                                            .catch(err => { console.log(err) })
                                            if(this.state.user_id_region == 29){
                                                axios.get('api/crop/hybryd_drop_down', { params: { id_kultura: this.state.kultura } })
                                                .then(res => { this.setState({ hybrydType: res.data.data, hybryd_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                            }
                                        });
                                        
                                }}>
                                <option value='' />
                                {this.state.cropType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                            </TextField>
                        }
                            
                        
                        {(this.state.user_id_region === 29) && !this.state.new &&
                        
                            <TextField
                                label={i18n.t('Sort/Hybrid')}
                                select
                                disabled={this.state.hybryd_disabled}
                                value={this.state.kultura_sorta}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginLeft: '20px' }}
                                onChange={(e) => {
                                    this.setState({ kultura_sorta: e.target.value }, () => {});
                                }}>
                                <option value='' />
                                {this.state.hybrydType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                            </TextField>
                        }
                        </div>
                        
                        {(this.state.user.id_region !== 29 || (this.state.user.id_region === 29 && !this.state.new)) &&
                        <div style={{ display: 'flex', marginLeft: '6px' }}>                     
                                <Checkbox
                                    checked={this.state.semenska_proizvodnja}
                                    onChange={(e) => { this.setState({ semenska_proizvodnja: !this.state.semenska_proizvodnja }); }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography style={{ fontSize: '16px', alignSelf: 'center', marginLeft: '-5px' }}>
                                    {i18n.t('Seed production')}
                                </Typography>
                            </div>
                         }
                        <div style={{ display: 'flex' }}>
                            {this.state.kultura &&
                                <div style={{ display: 'flex', marginLeft: '10px' }}>
                                    <Typography style={{ fontSize: '16px', alignSelf: 'center' }}>{i18n.t('Field color')}:</Typography>
                                    <div style={{
                                        width: '50px', height: '25px', alignSelf: 'center', marginLeft: '5px',
                                        border: this.state.boja === null ? `3px rgb(70, 146, 251) solid` : `3px rgb(${this.state.boja}) solid`,
                                        backgroundColor: this.state.boja === null ? `rgba(70, 146, 251, 0.3)` : `rgba(${this.state.boja}, 0.3)`
                                    }} />
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                if(this.getUser().id_region !== 29  || this.state.edit || this.state.new || this.state.parcel.length > 0){
                                    this.addField();
                                    }
                                    else{
                                    wentWrong('Please enter all required fields!');
                                    }
                            }}>
                                {this.state.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button>
                            <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                    if(this.state.user.id_region !== 29){
                                        this.setState({ crop_rotation: true }, () => {
                                        axios.get('api/crop/drop_down')
                                        .then(res => this.setState({ cr_crops: res.data.data }))
                                        .catch(err => console.log(err))})
                                    }else{
                                        this.setState({ crop_rotation_oneyear: true })
                                    }
                            }}>{i18n.t('Crop rotation')}
                            </Button>
                            {(this.state.user_id_region == 29) && this.state.edit &&
                            <Button style={{ marginLeft: '10px', marginTop: '20px', marginLeft: "auto" }} variant="contained" color="primary" onClick={() => {
                                this.archiveById(this.props.data.id);
                            }}>
                                {i18n.t('Archive field')}
                            </Button>
                            }
                            {this.state.alert === true && 
                            <AlertArchive 
                            handleCloseAlert={this.handleCloseAlert} 
                            addAfterCulture={this.addAfterCulture} 
                            closeEditDialog={this.closeEditDialog} 
                            data = {this.state.data}
                            alert={true} 
                            close={true} 
                            handleOpenAlert={this.handleOpenAlert} />}

                        </div>
                        <Dialog
                            open={this.state.crop_rotation}
                            onClose={() => { this.setState({ crop_rotation: false }) }}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle maxWidth="sm" disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.state.edit ? i18n.t('Crop rotation') : i18n.t('Crop rotation')}
                                <IconButton onClick={() => { this.setState({ crop_rotation: false }) }}>
                                    <span className="icon-cancel-circle closeIcon" />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent style={{ display: 'grid' }}>
                                <TextField
                                    label={i18n.t('Preceding crop')}
                                    select
                                    value={this.state.cr_crop}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginLeft: '10px', marginTop: '20px', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ cr_crop: e.target.value }, () => {
                                            if (this.state.cr_crop !== null && this.state.kultura !== null) {
                                                this.getCropRotation(this.state.kultura, this.state.cr_crop);
                                            }
                                        });
                                    }}>
                                    <option value='' />
                                    {this.state.cr_crops.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop group')}
                                    select
                                    value={this.state.kultura_grupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_grupa: e.target.value }, () => {
                                            axios.get('api/crop_subgroup/drop_down', { params: { id_grupa: this.state.kultura_grupa } })
                                                .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.cropGroupType.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop subgroup')}
                                    select
                                    disabled={this.state.kpg_disabled}
                                    value={this.state.kultura_podgrupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_podgrupa: e.target.value }, () => {
                                            axios.get('api/crop/drop_down', { params: { id_podgrupa: this.state.kultura_podgrupa } })
                                                .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.cropSubgroupType.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop')}
                                    select
                                    disabled={this.state.kultura_disabled}
                                    value={this.state.kultura}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura: e.target.value }, () => {
                                            if (this.state.cr_crop !== null && this.state.kultura !== null) {
                                                this.getCropRotation(this.state.kultura, this.state.cr_crop);
                                            }
                                        });
                                    }}>
                                    <option value='' />
                                    {this.state.cropType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                </TextField>
                                <DialogContentText style={{ marginLeft: '10px', marginTop: '20px' }}>
                                    {i18n.t('Crop rotation is the practice of growing different crops in successive seasons on the same land, in order to preserve the productive capacity of the soil.')}
                                </DialogContentText>
                                {Object.keys(this.state.crop_rotation_tip).length > 0 && this.state.cr_crop !== null && this.state.kultura !== null &&
                                    <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                                        {i18n.t('Crop rotation') + ' : ' + this.state.crop_rotation_tip[0].naziv}
                                    </DialogContentText>
                                }
                                {Object.keys(this.state.crop_rotation_tip).length === 0 && this.state.cr_crop !== null && this.state.kultura !== null &&
                                    <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                                        {i18n.t('There is no crop rotation for a selected culture and preculture.')}
                                    </DialogContentText>
                                }
                            </DialogContent>
                        </Dialog>
                    </DialogContent>
                </Dialog>
                {this.state.crop_rotation_oneyear && <CropRotation idKultura={this.state.kultura} close={this.onCloseRotation} archivedCrop={this.state.archived_crop}/>}
            </div >
        );
    };
};