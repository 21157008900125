import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import 'react-dates/initialize';
import { formatData, formatForecast, formatDisease, formatWorkPlanningData } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {
    value: { width: '8px', height: '8px', margin: '3px', borderRadius: '3px', padding: '8px', textAlign: 'center', fontWeight: 'bold', font: '12px', color: 'white' }
};

export class PlantProtectionGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            data: {},
            work: {},
            load: true,
            barColors: [],
            fourHours:[],
            twentyFourHours:[]
        };
    }

    componentDidMount() {
        this.getPessl();
    }

    getWork = (path, work) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => {
                obj = formatWorkPlanningData(res, obj);
                this.setState({ [work]: obj });
                this.generateValuesAndColors();                
                this.rain4and24Hours();

            })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let station_id = this.state.station_id;

        let work_path = '/forecast/' + station_id;

        this.getWork({ path: work_path, name: "plant-protection", crop: "general", url: "https://api.fieldclimate.com/v2", method: "POST" }, 'work');
    }

    generateValuesAndColors() {
        let newValues = [];
        if (this.state.work['plant-protection']) newValues = this.state.work['plant-protection'].map(item => (item + 1));

        this.setState(prevState => {
            let work = { ...prevState.work };
            work['plant-protection'] = newValues;
            return { work }
        });

        let colors = [];
        for (let i = 0; i < this.state.work['plant-protection'].length; i++) {
            const element = this.state.work['plant-protection'][i];
            if (element === 1) {
                colors.push('#660000');
            } else if (element === 2) {
                colors.push('#C67465');
            } else if (element === 3) {
                colors.push('#A1C16E');
            } else {
                colors.push('#458248');
            }
        }
        this.setState({ barColors: colors, load: false });
    }

    rain4and24Hours(){
        let fourHours = [];
        let twentyFourHours = [];
        let data = this.state.work['Precipitation']
        
        //Prva for petlja puni niz dok ima 3 clana posle i-tog clana
        for (let i = 0; i < data.length - 3; i++) {
            let sum = 0; 
            for (let j = i; j < i + 4; j++) {
                sum += data[j]
            }
            fourHours.push(parseFloat(sum).toFixed(1))
        }
        //Druga for petlja puni niz za poslednja 3 clana
        for (let i = data.length - 3; i < data.length; i++) {
            let sum = 0; 
            for (let j = i; j < data.length; j++) {
                sum += data[j];
            }
            fourHours.push(parseFloat(sum).toFixed(1))
        }

        //Prva for petlja puni niz dok ima 23 clana posle i-tog clana
        for (let i = 0; i < data.length - 23; i++) {
            let sum = 0; 
            for (let j = i; j < i + 24; j++) {
                sum += data[j]
            }
            twentyFourHours.push(parseFloat(sum).toFixed(1))
        }
        //Druga for petlja puni niz za poslednja 23 clana
        for (let i = data.length - 23; i < data.length; i++) {
            let sum = 0; 
            for (let j = i; j < data.length; j++) {
                sum += data[j];
            }
            twentyFourHours.push(parseFloat(sum).toFixed(1))
        }
       
        this.setState({ fourHours: fourHours, load2: false });
        this.setState({ twentyFourHours: twentyFourHours, load2: false });
    }

    render() {
        const { classes } = this.props;

        let graph1 = {};
        let graph2 = {};

        const options1 = {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (tooltipItem, chart) => graph1.datasets.map(ds => ds.label + ': ' + ds.data[tooltipItem.index]),
                },
            },
            scales: {
                xAxes: [
                    {
                        barPercentage: 1,
                        categoryPercentage: 1,
                        gridLines: {
                            drawOnChartArea: false
                        },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: true
                        },
                        ticks: {
                            min: 0,
                            max: 4,
                            maxTicksLimit: 5
                        },
                    },
                ],
            },
        };
        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                        }
                    }
                ],
            },
        };
        if (
            this.state.work.dates &&
            this.state.work
        ) {
            graph1 = {
                labels: this.state.work.dates,
                datasets: [
                    {
                        label: i18n.t('Temperature [°C]'),
                        hidden: true,
                        data: this.state.work['Temperature']
                    },
                    {
                        label: i18n.t('Wind gust [km/h]'),
                        hidden: true,
                        data: this.state.work['Wind gust']
                    },
                    {
                        label: i18n.t('Wind speed [m/s]'),
                        hidden: true,
                        data: this.state.work['Wind speed']
                    },
                    {
                        label: i18n.t('Relative humidity [%]'),
                        data: this.state.work['Relative humidity'],
                        hidden: true,
                    },
                    {
                        label: i18n.t('Precipitation [mm]'),
                        data: this.state.work['Precipitation'],
                        hidden: true,
                    },
                    
                        
                    {
                        label: i18n.t('Rain 4h [mm]'),
                        hidden: true,
                        data: this.state.fourHours
                    },
                    {
                        label: i18n.t('Rain 24h [mm]'),
                        hidden: true,
                        data: this.state.twentyFourHours
                    },
                    {
                        data: this.state.work['plant-protection'],
                        label: i18n.t('Value'),
                        backgroundColor: this.state.barColors,
                        type: 'bar',
                        borderWidth: 2,
                        yAxisID: 'y-axis-1',

                    },
                ],
            };

            graph2 = {
                labels: this.state.work.dates,
                datasets: [
                    {
                        data: this.state.work['Temperature'],
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-2',
                    },
                    {
                        data: this.state.work['Precipitation'],
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.work['Probability of Prec.'],
                        label: i18n.t('Precipitation probability [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#0d86d6',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-4',
                        borderDash: [10, 5]
                    },

                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Conditions for plant protection - general')}</p>
                        </div>
                </div>
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                    <div>
                        {/* <div>
                            <h1 align="center">{i18n.t('7 day forecast for plant protection - ')}{i18n.t('general data')}</h1>
                        </div> */}
                        <div style={{ marginTop: '30px', textAlign: "center" }}>
                            <span>{i18n.t('Very bad conditions - ')}</span>
                            <span className={classes.value} style={{ background: '#660000' }}>1</span>
                            <span className={classes.value} style={{ background: '#C67465' }}>2</span>
                            <span className={classes.value} style={{ background: '#A1C16E' }}>3</span>
                            {/* <span className={classes.value} style={{ background: '#458248' }}>4</span> */}
                            <span>{i18n.t(' - very good conditions')}</span>
                        </div>
                        <div style={{ width: '95%', marginTop: '30px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        {/* <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div> */}
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Wind gust [km/h]'), i18n.t('Wind speed [m/s]'), i18n.t('Relative humidity [%]'), i18n.t('Precipitation [mm]'), i18n.t('Rain 4h [mm]'), i18n.t('Rain 24h [mm]') ],
                            dates: this.state.work.dates,
                            temperature: this.state.work['Temperature'],
                            windgust: this.state.work['Wind gust'],
                            windspeed: this.state.work['Wind speed'],
                            relativehumidity: this.state.work['Relative humidity'],
                            precipitation: this.state.work['Precipitation'],
                            fourhours: this.state.fourHours,
                            twentyfourhours: this.state.twentyFourHours
  
                        }} />
                        <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(PlantProtectionGeneral);
