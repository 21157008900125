import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { createNewPassword, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, } from '@material-ui/core';
import validator from 'validator';

/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}

export class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            password1: '',
            password2: '',
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.createpassword === true) {
            toast.success(i18n.t('You have changed your password!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('createpassword');
            this.props.history.replace('/');

        }
    }

    createNewPassword = () => {
        if (this.state.password1 &&
            this.state.password2) {

            if (this.state.password1 !== this.state.password2) {
                toast.error(i18n.t('Passwords do not match!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else if (!validator.isLength(this.state.password1 && this.state.password2, { min: 6, max: 10 })) {
                toast.error(i18n.t('Password must contain at least 6 letters!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else {
                var params = window.location.search.substring(1);
                var token = params.substring(6);
                this.props.createNewPassword(this.state.password1, token);

            }

        } else {
            toast.error(i18n.t('Please enter all fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });

        }
    }

    render() {
        return (
            <div>
                <div className="login">
                    <Paper className="roundedBox" >
                        <div className="loginText">
                            Reset your password
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <div>
                                <div className="logo">
                                    <img src={require('../../assets/images/login_agrolife.svg')} width="300" height="70" />
                                </div>
                                <TextField
                                    required
                                    type='password'
                                    onChange={(event) => this.setState({ password1: event.target.value })}
                                    label={i18n.t('New password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <TextField
                                    type='password'
                                    onChange={(event) => this.setState({ password2: event.target.value })} required
                                    label={i18n.t('Retype new password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <Button
                                    style={{ alignSelf: 'center', backgroundColor: '#126038', color: 'white' }}
                                    onClick={() => this.createNewPassword()}
                                >
                                    {i18n.t('Update password')}
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        createpassword: state.appReducer.createpassword,
        createpasswordFetching: state.appReducer.createpasswordFetching,
        createpasswordFetchingFailed: state.appReducer.createpasswordFetchingFailed,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createNewPassword: (password1, token) => dispatch(createNewPassword(password1, token)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);