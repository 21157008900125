import React from 'react';
import Dashboard from '../Dashboard';
import i18n from '../../../i18n/i18n';

export default class BalanceDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Dashboard
                    url='api/dashboards/material_balance'
                    labels='naziv'
                    dataset='value'
                    title={i18n.t('Balance')}
                    type='horizontal_bar'
                    // unit='jedinica_mere'
                    filters={['naziv']}
                    filter_names={['Vrsta']}
                    total={true}
                />
            </div>
        )
    }
}