import React from 'react';
import styles from './SeasonStatistics.module.css';
import i18n from '../../i18n/i18n';
import axios from './../../utils/AxiosWrapper';
import { MoonLoader } from 'react-spinners';
import { TextField } from '@material-ui/core';

export default class SeasonStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            material: '',
            materialsNaziv: []
        };
    }

    componentDidMount() {
        this.getUser()
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        this.setState({
            user: userObject
        })
        this.getSeason()
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getSeason() {
        axios.get('api/season/activity_season')
            .then(res => {
                let choosenSeason = res.data.data[0].naziv;
                let seasonId = res.data.data[0].id
                this.setState({
                    choosenSeason: choosenSeason,
                }, () => {
                    axios.get('api/activity/statistics', { params: { seasonId } })
                        .then(
                            res => {
                                let count = res.data.data[0].count
                                this.setState({
                                    activitiesCount: count
                                }, () => {
                                    axios.get('api/field/statistics', { params: { seasonId } })
                                        .then(
                                            res => {
                                                let data = res.data.data.map(field => Number(field.povrsina))
                                                if (data.length) {
                                                    let totalArea = data.reduce((a, x) => a + x)
                                                    this.setState({
                                                        area: totalArea.toFixed(2)
                                                    }, () => {
                                                        axios.get('api/activity/activity_material_statistics', { params: { seasonId } })
                                                            .then(
                                                                res => {
                                                                    let materialsNaziv = res.data.data.map(item => item)
                                                                    const getUniqArrBy = (props = [], arrInp = []) => {
                                                                        return Object.values(arrInp.reduce((res, item) => {
                                                                            const keyComb = props.reduce((res, prop) => `${res}${item[prop]}`, '')
                                                                            return { ...res, [keyComb]: item }
                                                                        }, []))
                                                                    }
                                                                    const uniq = getUniqArrBy(['id_materijal', 'naziv'], materialsNaziv)
                                                                    let uniqMaterial = uniq.filter(item => item.prinos === null)
                                                                    let uniquePrinos = uniq.filter(item => item.prinos !== null)
                                                                    let materialData = res.data.data.map(item => item.id_materijal)
                                                                    //let uniqueData = [...new Set(materialData)]
                                                                    let numberOfMaterials = uniqMaterial.length
                                                                    let numberOfYields = uniquePrinos.length
                                                                    this.setState({
                                                                        materialsNaziv: uniqMaterial,
                                                                        prinosNaziv: uniquePrinos,
                                                                        materialsNumber: numberOfMaterials !== [] ? numberOfMaterials : 0,
                                                                        yieldsNumber: numberOfYields !== [] ? numberOfYields : 0,
                                                                        materialAll: materialsNaziv,
                                                                        uniqePrinos: uniquePrinos,
                                                                        loading: false
                                                                    })
                                                                }
                                                            )
                                                            .catch(err => { console.log(err) })
                                                    })
                                                } else {
                                                    this.setState({
                                                        area: '0',
                                                        loading: false,
                                                        materialsNumber: '0',
                                                        yieldsNumber: '0',
                                                        materialsNaziv: []
                                                    })
                                                }
                                            }
                                        )
                                        .catch(err => { console.log(err) })
                                })
                            }
                        )
                        .catch(err => { console.log(err) })
                }
                )
            })
            .catch(err => { console.log(err) })

    }

    getMaterialConsumption = () => {
        let matConsumption = this.state.materialAll.filter(item => item.id_materijal == this.state.material)
        const uniqueData = [...matConsumption.reduce((map, obj) => map.set(obj.id_radni_nalog, obj), new Map()).values()];
        const uniqueMernaJedinica = [...matConsumption.reduce((map, obj) => map.set(obj.jedinica_mere, obj), new Map()).values()];

        // Unique grupisano po jedinici mere
        let grouped = Array.from(
            uniqueData.reduce((m, o) => m.set(o.jedinica_mere, [...(m.get(o.jedinica_mere) || []), o]), new Map)
                .values()

        );
        this.setState({
            merneJedinice: uniqueMernaJedinica,
            materialsGrouped: grouped
        })
    }

    getPrinosConsumption = () => {
        let matConsumption = this.state.materialAll.filter(item => item.id_materijal == this.state.prinos)
        const uniqueData = [...matConsumption.reduce((map, obj) => map.set(obj.id_radni_nalog, obj), new Map()).values()];
        const uniqueMernaJedinica = [...matConsumption.reduce((map, obj) => map.set(obj.jedinica_mere, obj), new Map()).values()];

        // Unique grupisano po jedinici mere
        let grouped = Array.from(
            uniqueData.reduce((m, o) => m.set(o.jedinica_mere, [...(m.get(o.jedinica_mere) || []), o]), new Map)
                .values()
        );
        this.setState({
            merneJedinicePrinos: uniqueMernaJedinica,
            materialsGroupedPrinos: grouped
        })
    }
    render() {

        let jedinice;
        if (this.state.merneJedinice) {
            jedinice = this.state.merneJedinice.map(item => item.jedinica_mere)
        }
        let materialSum;
        let totalAmount;
        if (this.state.materialsGrouped) {
            let grouped = this.state.materialsGrouped.map(a => a.map(item => item.utroseno));
            materialSum = grouped.map(a => a.reduce((x, y) => Number(x) + Number(y), 0));
            totalAmount = materialSum.map((utroseno, index) => {
                return {
                    utroseno: utroseno,
                    jedinica: jedinice[index],
                }
            });
        }
        let jediniceToShow
        if (this.state.merneJedinice) {
            jediniceToShow = totalAmount.map(item => {
                return (
                    <div className={styles.jediniceContainer} key={item.utroseno}>
                        <div className={styles.jediniceItem}>{item.utroseno} {item.jedinica}</div>
                    </div>
                )
            })
        }
        let jedinicePrinos;
        if (this.state.merneJedinicePrinos) {
            jedinicePrinos = this.state.merneJedinicePrinos.map(item => item.jedinica_mere)
        }
        let materialSumPrinos;
        let totalAmountPrinos;
        if (this.state.materialsGroupedPrinos) {
            let groupedPrinos = this.state.materialsGroupedPrinos.map(a => a.map(item => item.prinos));
            materialSumPrinos = groupedPrinos.map(a => a.reduce((x, y) => Number(x) + Number(y), 0));
            totalAmountPrinos = materialSumPrinos.map((utroseno, index) => {
                return {
                    utrosenoPrinos: utroseno,
                    jedinicaPrinos: jedinicePrinos[index],
                }
            });
        }

        let jediniceToShowPrinos
        if (this.state.merneJedinicePrinos) {
            jediniceToShowPrinos = totalAmountPrinos.map(item => {
                return (
                    <div className={styles.jediniceContainer} key={item.utrosenoPrinos}>
                        <div className={styles.jediniceItem}>{item.utrosenoPrinos} {item.jedinicaPrinos}</div>
                    </div>
                )
            })
        }
        return (
            <div className={styles.container}>
                {this.state.loading === true && <div style={{ marginLeft: '50%', marginTop: '10%', paddingTop: '200px', paddingBottom: '300px' }}> <MoonLoader loading={true} size={100} /></div>}

                {this.state.loading === false &&
                    <div>
                        <div className={styles.header}>
                            <h1 className={styles.heading}>{i18n.t('Overview')} - {this.state.choosenSeason}</h1>
                        </div>
                        <div className={styles.featured}>
                            <div className={styles.featuredItem}>
                                <div className={styles.feturedTop}>
                                    <span className={styles.featuredTitle}>{i18n.t('Land area')}</span>
                                    <img style={{ width: '40px', height: '40px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./area.svg' />
                                </div>
                                <div className={styles.featuredContainer}>
                                    <span className={styles.featuredNumber}>{this.state.area} ha</span>
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total count of land area (all tables) from this season')}</span>
                            </div>
                            <div className={styles.featuredItem}>
                                <div className={styles.feturedTop}>
                                    <span className={styles.featuredTitle}>{i18n.t('Activities')}</span>
                                    <img style={{ width: '40px', height: '40px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./wo.svg' />
                                </div>
                                <div className={styles.featuredContainer}>
                                    <span className={styles.featuredNumber}>{this.state.activitiesCount}</span>
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total number of all activities in this season')}</span>
                            </div>
                            <div className={styles.featuredItem}>
                                <div className={styles.feturedTop}>
                                    <span className={styles.featuredTitle}>{i18n.t('Materials')}</span>
                                    <img style={{ width: '40px', height: '40px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./mu.svg' />
                                </div>
                                <div className={styles.featuredContainer}>
                                    <span className={styles.featuredNumber}>{this.state.materialsNumber}</span>
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total number of different materials used in activities')}</span>
                            </div>
                            <div className={styles.featuredItem}>
                                <div className={styles.feturedTop}>
                                    <span className={styles.featuredTitle}>{i18n.t('Yields')}</span>
                                    <img style={{ width: '40px', height: '40px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./yi.svg' />
                                </div>
                                <div className={styles.featuredContainer}>
                                    <span className={styles.featuredNumber}>{this.state.yieldsNumber}</span>
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total number of different cultures that have yield in activities')}</span>
                            </div>
                        </div>
                        {this.state.activitiesCount === '0' && <div className={styles.noTables}>Nema unetih radnih naloga u ovoj sezoni!</div>}
                        {this.state.area === '0' && <div className={styles.noTables}>Nema unetih tabli u ovoj sezoni!</div>}
                        {this.state.activitiesCount != '0' && <div className={styles.reportContainer}>
                            <div className={styles.consumption}>
                                <div className={styles.feturedTop}>
                                    <p className={styles.consumptionTitle}>{i18n.t('Material consumption')}</p>
                                    <img style={{ width: '55px', height: '55px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./tm.svg' />
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total amount of materials consumed in work orders in this season')}</span>
                                <TextField
                                    label={i18n.t('Materials list')}
                                    select
                                    helperText={i18n.t('Choose material from list')}
                                    value={this.state.material}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px', display: 'grid' }}
                                    onChange={(e) => {
                                        this.setState({
                                            material: e.target.value,
                                        }, this.getMaterialConsumption);
                                    }} >
                                    <option value='' />
                                    {this.state.materialsNaziv.map((material) => <option key={material.id_materijal} value={material.id_materijal}>{material.naziv}</option>)}
                                </TextField>
                                <div className={styles.showContainer}>{jediniceToShow}</div>
                            </div>
                            <div className={styles.consumption}>
                                <div className={styles.feturedTop}>
                                    <p className={styles.consumptionTitle}>{i18n.t('Yield')}</p>
                                    <img style={{ width: '55px', height: '55px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./py.svg' />
                                </div>
                                <span className={styles.featuredSub}>{i18n.t('Total yield by culture for this season entered through work orders')}</span>
                                <TextField
                                    label={i18n.t('Cultures list')}
                                    select
                                    helperText={i18n.t('Choose culture from list')}
                                    value={this.state.prinos}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px', display: 'grid' }}
                                    onChange={(e) => {
                                        this.setState({
                                            prinos: e.target.value,
                                        }, this.getPrinosConsumption);
                                    }} >
                                    <option value='' />
                                    {this.state.prinosNaziv.map((material) => <option key={material.id_materijal} value={material.id_materijal}>{material.naziv}</option>)}
                                </TextField>
                                <div className={styles.showContainer}>{jediniceToShowPrinos}</div>
                            </div>
                        </div>}
                    </div>
                }
            </div>
        );
    }
}
