import axios from '../../utils/AxiosWrapper';
export const Type = {

    GET_VIEW_ACTIVITIES_CALL: 'GET_VIEW_ACTIVITIES_CALL',
    GET_VIEW_ACTIVITIES_SUCCESS: 'GET_VIEW_ACTIVITIES_SUCCESS',
    GET_VIEW_ACTIVITIES_FAILED: 'GET_VIEW_ACTIVITIES_FAILED',

    GET_ACTIVITY_BY_ID_CALL: 'GET_ACTIVITY_BY_ID_CALL',
    GET_ACTIVITY_BY_ID_SUCCESS: 'GET_ACTIVITY_BY_ID_SUCCESS',
    GET_ACTIVITY_BY_ID_FAILED: 'GET_ACTIVITY_BY_ID_FAILED',

    //DELETE
    DELETE_ACTIVITY_CALL: 'DELETE_ACTIVITY_CALL',
    DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
    DELETE_ACTIVITY_FAILED: 'DELETE_ACTIVITY_FAILED',
    //DROPDOWN
    GET_STATUS_CALL: 'GET_STATUS_CALL',
    GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
    GET_STATUS_FAILED: 'GET_STATUS_FAILED',
    GET_MATERIAL_GROUP_CALL: 'GET_MATERIAL_GROUP_CALL',
    GET_MATERIAL_GROUP_SUCCESS: 'GET_MATERIAL_GROUP_SUCCESS',
    GET_MATERIAL_GROUP_FAILED: 'GET_MATERIAL_GROUP_FAILED',
    GET_MATERIAL_SUBGROUP_CALL: 'GET_MATERIAL_SUBGROUP_CALL',
    GET_MATERIAL_SUBGROUP_SUCCESS: 'GET_MATERIAL_SUBGROUP_SUCCESS',
    GET_MATERIAL_SUBGROUP_FAILED: 'GET_MATERIAL_SUBGROUP_FAILED',
    GET_MATERIAL_DROPDOWN_CALL: 'GET_MATERIAL_DROPDOWN_CALL',
    GET_MATERIAL_DROPDOWN_SUCCESS: 'GET_MATERIAL_DROPDOWN_SUCCESS',
    GET_MATERIAL_DROPDOWN_FAILED: 'GET_MATERIAL_DROPDOWN_FAILED',

    GET_IMPLEMENTS_GROUP_CALL: 'GET_IMPLEMENTS_GROUP_CALL',
    GET_IMPLEMENTS_GROUP_SUCCESS: 'GET_IMPLEMENTS_GROUP_SUCCESS',
    GET_IMPLEMENTS_GROUP_FAILED: 'GET_IMPLEMENTS_GROUP_FAILED',
    GET_IMPLEMENTS_SUBGROUP_CALL: 'GET_IMPLEMENTS_SUBGROUP_CALL',
    GET_IMPLEMENTS_SUBGROUP_SUCCESS: 'GET_IMPLEMENTS_SUBGROUP_SUCCESS',
    GET_IMPLEMENTS_SUBGROUP_FAILED: 'GET_IMPLEMENTS_SUBGROUP_FAILED',
    GET_IMPLEMENTS_DROPDOWN_CALL: 'GET_IMPLEMENTS_DROPDOWN_CALL',
    GET_IMPLEMENTS_DROPDOWN_SUCCESS: 'GET_IMPLEMENTS_DROPDOWN_SUCCESS',
    GET_IMPLEMENTS_DROPDOWN_FAILED: 'GET_IMPLEMENTS_DROPDOWN_FAILED',

    GET_MACHINES_GROUP_CALL: 'GET_MACHINES_GROUP_CALL',
    GET_MACHINES_GROUP_SUCCESS: 'GET_MACHINES_GROUP_SUCCESS',
    GET_MACHINES_GROUP_FAILED: 'GET_MACHINES_GROUP_FAILED',
    GET_MACHINES_SUBGROUP_CALL: 'GET_MACHINES_SUBGROUP_CALL',
    GET_MACHINES_SUBGROUP_SUCCESS: 'GET_MACHINES_SUBGROUP_SUCCESS',
    GET_MACHINES_SUBGROUP_FAILED: 'GET_MACHINES_SUBGROUP_FAILED',
    GET_MACHINES_DROPDOWN_CALL: 'GET_MACHINES_DROPDOWN_CALL',
    GET_MACHINES_DROPDOWN_SUCCESS: 'GET_MACHINES_DROPDOWN_SUCCESS',
    GET_MACHINES_DROPDOWN_FAILED: 'GET_MACHINES_DROPDOWN_FAILED',

    GET_WORKING_OPERATION_GROUP_CALL: 'GET_WORKING_OPERATION_GROUP_CALL',
    GET_WORKING_OPERATION_GROUP_SUCCESS: 'GET_WORKING_OPERATION_GROUP_SUCCESS',
    GET_WORKING_OPERATION_GROUP_FAILED: 'GET_WORKING_OPERATION_GROUP_FAILED',
    GET_WORKING_OPERATION_DROPDOWN_CALL: 'GET_WORKING_OPERATION_DROPDOWN_CALL',
    GET_WORKING_OPERATION_DROPDOWN_SUCCESS: 'GET_WORKING_OPERATION_DROPDOWN_SUCCESS',
    GET_WORKING_OPERATION_DROPDOWN_FAILED: 'GET_WORKING_OPERATION_DROPDOWN_FAILED',

    GET_CROP_GROUP_DROPDOWN_CALL: 'GET_CROP_GROUP_DROPDOWN_CALL',
    GET_CROP_GROUP_DROPDOWN_SUCCESS: 'GET_CROP_GROUP_DROPDOWN_SUCCESS',
    GET_CROP_GROUP_DROPDOWN_FAILED: 'GET_CROP_GROUP_DROPDOWN_FAILED',
    GET_CROP_SUBGROUP_DROPDOWN_CALL: 'GET_CROP_SUBGROUP_DROPDOWN_CALL',
    GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: 'GET_CROP_SUBGROUP_DROPDOWN_SUCCESS',
    GET_CROP_SUBGROUP_DROPDOWN_FAILED: 'GET_CROP_SUBGROUP_DROPDOWN_FAILED',
    GET_CROP_CALL: 'GET_CROP_CALL',
    GET_CROP_SUCCESS: 'GET_CROP_SUCCESS',
    GET_CROP_FAILED: 'GET_CROP_FAILED',

    GET_UNIT_DROPDOWN_CALL: 'GET_UNIT_DROPDOWN_CALL',
    GET_UNIT_DROPDOWN_SUCCESS: 'GET_UNIT_DROPDOWN_SUCCESS',
    GET_UNIT_DROPDOWN_FAILED: 'GET_UNIT_DROPDOWN_FAILED',

    //SETTERS
    SET_FIELD_ROW_IN_REDUCER: 'SET_FIELD_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    ADD_ACTIVITY_CALL: 'ADD_ACTIVITY_CALL',
    ADD_ACTIVITY_SUCCESS: 'ADD_ACTIVITY_SUCCESS',
    ADD_ACTIVITY_FAILED: 'ADD_ACTIVITY_FAILED',

    DELETE_CHILD_CALL: 'DELETE_CHILD_CALL',
    DELETE_CHILD_SUCCESS: 'DELETE_CHILD_SUCCESS',
    DELETE_CHILD_FAILED: 'DELETE_CHILD_FAILED',

    UPDATE_CHILD_CALL: 'UPDATE_CHILD_CALL',
    UPDATE_CHILD_SUCCESS: 'UPDATE_CHILD_SUCCESS',
    UPDATE_CHILD_FAILED: 'UPDATE_CHILD_FAILED',

    GET_DISTRIBUTION_CALL: 'GET_DISTRIBUTION_CALL',
    GET_DISTRIBUTION_SUCCESS: 'GET_DISTRIBUTION_SUCCESS',
    GET_DISTRIBUTION_FAILED: 'GET_DISTRIBUTION_FAILED'
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_FIELD_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getActivities(search, filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_VIEW_ACTIVITIES_CALL
        });

        var config = {
            params: {
                search,
                filters
            }
        };

        axios.get('api/activity/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_ACTIVITIES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_ACTIVITIES_FAILED
                });
            });
    }
}

export function getById(id, opt) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ACTIVITY_BY_ID_CALL
        });

        axios.get(`api/activity/getById?id=` + id)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ACTIVITY_BY_ID_SUCCESS,
                    data: response.data,
                    options: opt
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ACTIVITY_BY_ID_FAILED
                });
            });
    }
}

export function getDistribution(id) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_DISTRIBUTION_CALL,
        });

        axios.get(`api/activity/distributionGetById?id=` + id)
            .then(function (response) {
                dispatch({
                    type: Type.GET_DISTRIBUTION_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_DISTRIBUTION_FAILED
                });
            });
    }
}

export function areaChanged(index, text, item, zaRaspodelu) {
    let ukPovrsina = parseFloat(item.povrsina);
    let ukObradjenaPovrsina = text;
    let ukUtroseno = parseFloat(item.utroseno);
    let ukPrinos = parseFloat(item.prinos);
    function round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    let uk = zaRaspodelu.filter((row) => row.id_tabla !== item.id_tabla);
    uk.map((item) => {
        ukPovrsina += parseFloat(item.povrsina);
        ukObradjenaPovrsina += parseFloat(item.obradjena_povrsina);
        ukUtroseno += parseFloat(item.utroseno);
        ukPrinos += parseFloat(item.prinos);
    });

    let pri = null,
        utr = null;

    let raspodeljeno = zaRaspodelu.map((currentValue) => {

        if (currentValue.id_tabla == item.id_tabla) {
            if (ukUtroseno) {
                utr = round(round(ukUtroseno, 4) * round(text, 4) / round(ukObradjenaPovrsina, 4), 4).toString();
            } else if (ukPrinos) {
                pri = round(round(ukPrinos, 4) * round(text, 4) / round(ukObradjenaPovrsina, 4), 4).toString();
            }
            return {
                obradjena_povrsina: round(text, 4).toString(),
                utroseno: utr,
                prinos: pri,
                obradjena_povrsina_izmenjena: true,
                id_region: currentValue.id_region,
                id_radni_nalog: currentValue.id_radni_nalog,
                id_materijal: currentValue.id_materijal,
                id_tabla: currentValue.id_tabla,
                oznaka_table: currentValue.tabla,
                povrsina: currentValue.povrsina,
                id_radni_nalog_materijal: currentValue.id_radni_nalog_materijal,
                id_glavna_tabla: currentValue.id_glavna_tabla,
                naziv: currentValue.materijal
            }
        } else {

            if (ukUtroseno) {
                utr = round(round(ukUtroseno, 4) * round(currentValue.obradjena_povrsina, 4) / round(ukObradjenaPovrsina, 4), 4).toString();
            } else if (ukPrinos) {
                pri = round(round(ukPrinos, 4) * round(currentValue.obradjena_povrsina, 4) / round(ukObradjenaPovrsina, 4), 4).toString();
            }
            return {
                utroseno: utr,
                prinos: pri,
                obradjena_povrsina_izmenjena: true,
                obradjena_povrsina: currentValue.obradjena_povrsina,
                id_region: currentValue.id_region,
                id_radni_nalog: currentValue.id_radni_nalog,
                id_materijal: currentValue.id_materijal,
                id_tabla: currentValue.id_tabla,
                oznaka_table: currentValue.tabla,
                povrsina: currentValue.povrsina,
                id_radni_nalog_materijal: currentValue.id_radni_nalog_materijal,
                id_glavna_tabla: currentValue.id_glavna_tabla,
                naziv: currentValue.materijal
            };
        }
    });

    return (dispatch) => {
        dispatch({
            type: Type.GET_DISTRIBUTION_CALL
        });

        return axios.post('/api/activity/updateCalculate', raspodeljeno)
            .then(function (response) {
                dispatch({
                    type: Type.GET_DISTRIBUTION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_DISTRIBUTION_FAILED
                });
            });
    }
}

export function deleteActivity(selection) {
    return (dispatch, getState) => {
        const { appReducer: { clientProfile } } = getState();
        let filters = {}
        filters.id_sezona = clientProfile.id_default_sezona;
        dispatch({
            type: Type.DELETE_ACTIVITY_CALL,
        });

        axios.post(`api/activity/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_ACTIVITY_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
                dispatch(getActivities(null, filters));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_ACTIVITY_FAILED
                });
            });
    }
}

export function addActivity(activity) {
    return (dispatch, getState) => {
        const { appReducer: { clientProfile } } = getState();
        let filters = {}
        filters.id_sezona = clientProfile.id_default_sezona;
        dispatch({
            type: Type.ADD_ACTIVITY_CALL,
        });
        axios.post(`api/activity/create`, activity)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_ACTIVITY_SUCCESS,
                });
                dispatch(getActivities(null, filters));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ACTIVITY_FAILED
                });
            });
    }
}

export function deleteChildFromActivity(name, id, act_id) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_CHILD_CALL,
        });
        axios.post(`api/activity/delete_child`, { id: id, name: name, id_radni_nalog: act_id })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_CHILD_SUCCESS,
                });
                dispatch(getById(act_id))
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_CHILD_FAILED
                });
            });
    }
}

export function updateChild(id, name, data) {
    return (dispatch) => {

        dispatch({
            type: Type.UPDATE_CHILD_CALL,
        });
        axios.post(`api/activity/update_child`, { id: id, name: name, data: data })
            .then(function () {
                dispatch(getById(id));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_CHILD_FAILED
                });
            });
    }
}

export function getMaterialDropdown(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_DROPDOWN_CALL
        });

        axios.get(`api/material/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_FAILED
                });
            });
    }
}
export function getStatusDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STATUS_CALL
        });

        axios.get('api/status/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATUS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATUS_FAILED
                });
            });
    }
}
export function getMaterialGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_GROUP_CALL
        });

        axios.get('api/material_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_FAILED
                });
            });
    }
}

export function getMaterialSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_SUBGROUP_CALL
        });

        axios.get(`api/material_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_FAILED
                });
            });
    }
}

export function getImplement(id_subgroup) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENTS_DROPDOWN_CALL
        });

        axios.get(`api/implement/drop_down${id_subgroup ? '?id_podgrupa=' + id_subgroup : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_DROPDOWN_FAILED
                });
            });
    }
}

export function getImplementGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENTS_GROUP_CALL
        });

        axios.get('api/implement_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_GROUP_FAILED
                });
            });
    }
}

export function getImplementSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENTS_SUBGROUP_CALL
        });

        axios.get(`api/implement_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENTS_SUBGROUP_FAILED
                });
            });
    }
}

export function getMachinesDropdown(id_subgroup) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_DROPDOWN_CALL
        });

        axios.get(`api/machine/drop_down${id_subgroup ? '?id_podgrupa=' + id_subgroup : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_DROPDOWN_FAILED
                });
            });
    }
}

export function getMachinesGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_GROUP_CALL
        });

        axios.get('api/machine_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_GROUP_FAILED
                });
            });
    }
}

export function getMachinesSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_SUBGROUP_CALL
        });

        axios.get(`api/machine_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_SUBGROUP_FAILED
                });
            });
    }
}

export function getWODropdown(id_group) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_WORKING_OPERATION_DROPDOWN_CALL
        });

        axios.get(`api/working_operation/drop_down${id_group ? '?id_grupa=' + id_group : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_FAILED
                });
            });
    }
}

export function getWOGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_GROUP_CALL
        });

        axios.get('api/working_operation_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_GROUP_FAILED
                });
            });
    }
}

export function getCrop(id_subgroup) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_CALL
        });

        axios.get(`api/crop/drop_down${id_subgroup ? '?id_podgrupa=' + id_subgroup : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_FAILED
                });
            });
    }
}

export function getCropGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_GROUP_DROPDOWN_CALL
        });

        axios.get('api/crop_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getCropSubgroup(id_group) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/crop_subgroup/drop_down${id_group ? '?id_grupa=' + id_group : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getUnit() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_UNIT_DROPDOWN_CALL
        });

        axios.get('api/unit/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_UNIT_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_UNIT_DROPDOWN_FAILED
                });
            });
    }
}




