import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { resetPassword, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, } from '@material-ui/core';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}

export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.usernameCheckFetchingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('usernameCheckFetchingFailed');
        }
        if (nextProps.usernameCheck === true) {
            toast.success(i18n.t('Email has been sent to your e-mail address!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.setState({
                username: '',
            })
            this.props.setInitialState('usernameCheck');
        }
    }

    forgotPassword() {
        if (this.state.username) {
            this.props.resetPassword(this.state.username)
        } else {
            toast.error(i18n.t('Username is required.'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    render() {
        return (
            <div>
                <div className="login">
                    <Paper className="roundedBox" >
                        <div className="loginText">
                            Enter your username!
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <div>
                                <div className="logo">
                                    <img src={require('../../assets/images/login_agrolife.svg')} width="300" height="70" />
                                </div>
                                <TextField
                                    label={i18n.t('Username')}
                                    required
                                    onChange={(event) => this.setState({ username: event.target.value })}
                                />
                                <br />
                                <br />
                                <Button
                                    style={{ alignSelf: 'center', backgroundColor: '#126038', color: 'white' }}
                                    onClick={() => this.forgotPassword()}
                                >
                                    {i18n.t('Submit')}
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        usernameCheck: state.appReducer.usernameCheck,
        usernameCheckFetching: state.appReducer.usernameCheckFetching,
        usernameCheckFetchingFailed: state.appReducer.usernameCheckFetchingFailed,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (username) => dispatch(resetPassword(username)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);