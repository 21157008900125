import React from 'react';
import { Button, IconButton, TextField, Card, CardHeader, CardContent, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, Menu, MenuItem, InputLabel } from '@material-ui/core';
import styles from './newactivity.module.css';
import status from './status.module.css';
import i18n from '../../../i18n/i18n';
import { wentRight, wentWrong } from '../../../utils/ToastConfig';
import axios from '../../../utils/AxiosWrapper';
import WorkingOperation from './../working_operation/WorkingOperation';
import DateAndTime from '../date_and_time/DateAndTime';
import Fields from '../fields/Fields';
import Materials from '../materials/Materials';
import Products from '../products/Products';
import Machines from '../machines/Machines';
import Implements from '../implements/Implements';
import _ from 'lodash';

export default class NewActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityObj: {},
            workingOperationOpen: false,
            workingOperationString: '',
            dateAndTimeOpen: false,
            fieldsOpen: false,
            materialsOpen: false,
            productsOpen: false,
            machinesOpen: false,
            implementsOpen: false,
            commentOpen: false,
            comment: '',
            statusOpen: true,
            tooltipOpen: false,
            tooltipProgress: false,
            tooltipFinished: false,
            tooltipLocked: false,
            tooltipPlanned: false,
            copy: false,
        };
    };

    componentDidMount = () => {
        this.handleStatus(0);
        if (this.props.location) {
            if (this.props.location.state) {
                if (this.props.location.state.propObj) {
                    if (this.props.location.state.propObj.id === undefined) this.setState({ copy: true })
                    this.setState({ activityObj: this.props.location.state.propObj }, () => {
                        this.setState({ workingOperationString: this.props.location.state.propObj.radna_operacija }, () => {
                            if (this.props.location.state.propObj.komentar !== null) {
                                this.setState({ comment: this.props.location.state.propObj.komentar })
                            }
                            else this.deleteComment();
                        })
                    });
                }
            }
        }
    };

    goTo = (path) => this.props.history.push(path);

    handleWorkingOperation = () => this.setState({ workingOperationOpen: !this.state.workingOperationOpen });
    handleDateAndTime = () => this.setState({ dateAndTimeOpen: !this.state.dateAndTimeOpen });
    handleFields = () => this.setState({ fieldsOpen: !this.state.fieldsOpen });
    handleMaterials = () => this.setState({ materialsOpen: !this.state.materialsOpen });
    handleProducts = () => this.setState({ productsOpen: !this.state.productsOpen });
    handleMachines = () => this.setState({ machinesOpen: !this.state.machinesOpen });
    handleImplements = () => this.setState({ implementsOpen: !this.state.implementsOpen });
    handleStatusOpen = () => this.setState({ statusOpen: !this.state.statusOpen });

    handleWorkingOperationObj = (workingOperation, workingOperationDescription) => {
        let obj = this.state.activityObj;
        obj.id_radna_operacija = workingOperation.value;
        obj.opis_radna_operacija = workingOperationDescription;
        this.setState({ activityObj: obj }, () => {
            this.setState({ workingOperationString: workingOperation.label }, () => {
                this.handleWorkingOperation();
            });
        });
    };

    handleTreatedSurfaceChange = (id, povrsina) => {
        let obj = this.state.activityObj;
        var index = obj.table.findIndex(item => item.id_tabla === id);
        obj.table[index].obradjena_povrsina = document.getElementById(('search'+ id)).value
        this.setState({ activityObj: obj });
    };

    updateTreatedSurfaceChange() {
        this.state.activityObj.table.forEach(element => {
            if (element.obradjena_povrsina === null) {
            this.handleTreatedSurfaceChange(element.id_tabla)
            }
        });
    }
    handleTreatedSurfaceChangeValue = (event, povrsina, id) =>{
        let p = Number(povrsina).toFixed(2)
        let temp = Number(event.target.value)
        console.log(temp)
        if (temp > p || temp < 0 ) {
            document.getElementById(('search'+ id)).value = p
        }
    }
    handleDateAndTimeObj = (startDate, endDate, startTime, endTime) => {
        let obj = this.state.activityObj;
        obj.datum_pocetka = startDate;
        obj.datum_zavrsetka = endDate;
        obj.vreme_pocetka = startTime;
        obj.vreme_zavrsetka = endTime;
        this.setState({ activityObj: obj }, () => this.handleDateAndTime());
    };

    handleFieldsObj = (fields) => {
        let obj = this.state.activityObj;
        fields.forEach(element => {
            if (element.obradjena_povrsina === null) {
                element.obradjena_povrsina = element.povrsina
            }
        });
        obj.table = fields;
        this.setState({ activityObj: obj }, () => this.handleFields());
        this.updateTreatedSurfaceChange();
    
    };

    handleMaterialsObj = (material) => {
        let obj = this.state.activityObj;
        if (this.state.activityObj.materijali === undefined) {
            obj.materijali = [];
            obj.materijali.push(material);
        }
        else obj.materijali.push(material);
        this.setState({ activityObj: obj }, () => this.handleMaterials());
    };

    handleProductsObj = (product) => {
        let obj = this.state.activityObj;
        if (this.state.activityObj.materijali === undefined) {
            obj.materijali = [];
            obj.materijali.push(product);
        }
        else obj.materijali.push(product);
        this.setState({ activityObj: obj }, () => this.handleProducts());
    };

    handleMachinesObj = (machine) => {
        let obj = this.state.activityObj;
        obj.pogonskeMasine = machine;
        this.setState({ activityObj: obj }, () => this.handleMachines());
    };

    handleImplementsObj = (machine) => {
        let obj = this.state.activityObj;
        obj.prikljucneMasine = machine;
        this.setState({ activityObj: obj }, () => this.handleImplements());
    };

    handleComment = (comment) => {
        let obj = this.state.activityObj;
        obj.komentar = comment;
        this.setState({ activityObj: obj });
    };

    handleStatus = (id) => {
        let obj = this.state.activityObj;
        obj.id_status = id;
        this.setState({ activityObj: obj }, () => {
            this.setState({
                statusOpen: !this.state.statusOpen
            })
        });
    };

    handleInitialStatus = (id) => {
        let obj = this.state.activityObj;
        obj.id_status = id;
        this.setState({ activityObj: obj });
    };


    deleteWorkingOperation = () => {
        let obj = this.state.activityObj;
        delete obj.id_radna_operacija;
        delete obj.opis_radna_operacija;
        this.setState({ activityObj: obj }, () => {
            this.setState({ workingOperationString: '' });
        });
    };

    deleteDateAndTime = () => {
        let obj = this.state.activityObj;
        delete obj.datum_pocetka;
        delete obj.datum_zavrsetka;
        delete obj.vreme_pocetka;
        delete obj.vreme_zavrsetka;
        this.setState({ activityObj: obj });
    };

    deleteFields = (row) => {
        let obj = this.state.activityObj;
        let fields = obj.table;
        delete obj.table;
        fields.splice(row, 1);
        obj.table = fields;
        if (obj.table.length === 0) obj.table = undefined;
        this.setState({ activityObj: obj });
    };

    deleteMaterials = (row) => {
        let obj = this.state.activityObj;
        let materials = obj.materijali;
        delete obj.materijali;
        materials.splice(row, 1);
        obj.materijali = materials;
        if (obj.materijali.length === 0) obj.materijali = undefined;
        this.setState({ activityObj: obj });
    };

    deleteProducts = (row) => {
        let obj = this.state.activityObj;
        let products = obj.materijali;
        delete obj.materijali;
        products.splice(row, 1);
        obj.materijali = products;
        if (obj.materijali.length === 0) obj.materijali = undefined;
        this.setState({ activityObj: obj });
    };

    deleteMachines = (row) => {
        let obj = this.state.activityObj;
        let machines = obj.pogonskeMasine;
        delete obj.pogonskeMasine;
        machines.splice(row, 1);
        obj.pogonskeMasine = machines;
        if (obj.pogonskeMasine.length === 0) obj.pogonskeMasine = undefined;
        this.setState({ activityObj: obj });
    };

    deleteImplements = (row) => {
        let obj = this.state.activityObj;
        let machines = obj.prikljucneMasine;
        delete obj.prikljucneMasine;
        machines.splice(row, 1);
        obj.prikljucneMasine = machines;
        if (obj.prikljucneMasine.length === 0) obj.prikljucneMasine = undefined;
        this.setState({ activityObj: obj });
    };

    deleteComment = () => {
        let obj = this.state.activityObj;
        delete obj.komentar;
        this.setState({ activityObj: obj });
    };

    handleAdd = () => {

        this.state.activityObj.table.forEach(element => {
            if (element.obradjena_povrsina === null) {
            this.handleTreatedSurfaceChange(element.id_tabla)
            }
        });
        this.state.activityObj.table.forEach(element => {
            if (element.obradjena_povrsina === '') {
                element.obradjena_povrsina = 0
            }
        });

        const { activityObj } = this.state;
        console.log(activityObj)
        if (activityObj.komentar === undefined) {
            activityObj.komentar = null
        }
        axios.get('api/season/activity_season')
            .then(res => {
                let activity = {
                    activityObj: activityObj,
                    season: res.data.data[0].id_default_sezona
                }
                // this.props.addActivity(activity);
                axios.post(`api/activity/create`, activity)
                    .then(() => {
                        wentRight(i18n.t('Successfully added activity'))
                        this.goTo('/')
                    })
                    .catch(() => wentWrong(i18n.t('Something went wrong')))

                if (this.props.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 16 } })
                else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 13 } })

            })
            .catch(err => console.log('Error: ', err))
    };

    isOdd = (num) => { return num % 2; };

    render() {
        let materialList = [], productList = [];

        if (this.state.activityObj.materijali) {
            this.state.activityObj.materijali.forEach((item, i) => {
                if (item.indikator_prinosa === null) {
                    let obj = item;
                    obj.matId = i;
                    materialList.push(obj);
                }
                else if (item.indikator_prinosa === 1) {
                    let obj = item;
                    obj.matId = i;
                    productList.push(obj);
                }
            });
        };

        let statusColor = '#58B4E5', statusText = i18n.t('Choose status') + '*', statusIcon = './status/otvoren.svg';
        if (this.state.activityObj) {
            if (this.state.activityObj.id_status) {
                switch (this.state.activityObj.id_status) {
                    case 1:
                        statusColor = '#73db4d';
                        statusText = `${i18n.t('Status')}: ${i18n.t('Finished')}`;
                        statusIcon = './status/zavrsen.svg';
                        break;
                    case 2:
                        statusColor = '#f3e741';
                        statusText = `${i18n.t('Status')}: ${i18n.t('In progress')}`;
                        statusIcon = './status/utoku.svg';
                        break;
                    case 3:
                        statusColor = '#058457';
                        statusText = `${i18n.t('Status')}: ${i18n.t('Open ')}`;
                        statusIcon = './status/otvoren.svg';
                        break;
                    case 4:
                        statusColor = '#e40f0f';
                        statusText = `${i18n.t('Status')}: ${i18n.t('Locked')}`;
                        statusIcon = './status/zakljucan.svg';
                        break;
                    case 5:
                        statusColor = '#2e63c5';
                        statusText = `${i18n.t('Status')}: ${i18n.t('Planned')}`;
                        statusIcon = './status/planski.svg';
                        break;
                    default:
                        statusColor = '#49c954';
                        statusText = i18n.t('Choose status');
                        statusIcon = './status/zavrsen.svg';
                        break;
                }
            }
        };

        let saveDisabled = true;
        if (this.state.activityObj.id_radna_operacija) {
            if (this.state.activityObj.datum_pocetka) {
                if (this.state.activityObj.table && this.state.activityObj.table.length > 0) {
                    if (this.state.activityObj.id_status !== 0) {
                        saveDisabled = false;
                    }
                }
            }
        }

        let editText = false;
        if (this.props.location) {
            if (this.props.location.state) {
                if (this.props.location.state.propObj) {
                    if (this.props.location.state.propObj.id !== undefined) {
                        if (this.props.location.state.edit === true) {
                            editText = true;
                        }
                    }
                }
            }
        }

        return (
            <div className={styles.page}>
                <div className={styles.back_container} onClick={() => this.goTo('/activities')}>
                    <img role='button' alt="none" src='./back.svg' height='24px' onClick={() => this.goTo('/activities')} />
                    <div className={styles.back}>
                        {i18n.t('Back to activity list')}
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.title}>
                        {editText ? i18n.t('Edit activity') : i18n.t('Add activity')}
                    </div>

                    <div className={styles.sections_container}>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./production.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Working operation')}*</div>
                                    {this.state.activityObj.id_radna_operacija === undefined && <div className={styles.add_button} onClick={() => this.handleWorkingOperation()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleWorkingOperation()} />
                                    </div>}
                                </div>
                                {this.state.activityObj.id_radna_operacija && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleWorkingOperation()} />}
                            </div>
                            {this.state.activityObj.id_radna_operacija === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have not selected a working operation')}</div>
                                </div>
                            }
                            {this.state.activityObj.id_radna_operacija &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            <div style={{ minWidth: 'fit-content' }}>
                                                <div className={styles.working_operation_color}>
                                                    {i18n.t('Working operation')}:
                                                </div>
                                                <div className={styles.working_operation}>
                                                    {this.state.workingOperationString}
                                                </div>
                                            </div>
                                            {this.state.activityObj.opis_radna_operacija &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Working operation description')}:
                                                    </div>
                                                    <div className={styles.working_operation} style={{ textAlign: 'justify' }}>
                                                        {this.state.activityObj.opis_radna_operacija}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} onClick={() => this.deleteWorkingOperation()} />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./date.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Date and time')}*</div>

                                    {this.state.activityObj.vreme_pocetka === undefined &&
                                        this.state.activityObj.vreme_zavrsetka === undefined &&
                                        this.state.activityObj.datum_pocetka === undefined &&
                                        this.state.activityObj.datum_zavrsetka === undefined &&
                                        <div className={styles.add_button} onClick={() => this.handleDateAndTime()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleDateAndTime()} />
                                        </div>
                                    }
                                </div>
                            </div>
                            {this.state.activityObj.vreme_pocetka === undefined &&
                                this.state.activityObj.vreme_zavrsetka === undefined &&
                                this.state.activityObj.datum_pocetka === undefined &&
                                this.state.activityObj.datum_zavrsetka === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected dates and times')}</div>
                                </div>
                            }
                            {this.state.activityObj.vreme_pocetka !== undefined &&
                                this.state.activityObj.vreme_zavrsetka !== undefined &&
                                this.state.activityObj.datum_pocetka !== undefined &&
                                this.state.activityObj.datum_zavrsetka !== undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            {this.state.activityObj.datum_pocetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Start date')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.datum_pocetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.datum_zavrsetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('End date')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.datum_zavrsetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.vreme_pocetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Start time')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.vreme_pocetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.vreme_zavrsetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('End time')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.vreme_zavrsetka}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} onClick={() => this.deleteDateAndTime()} />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./field_3.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Fields')}*</div>
                                    {(this.state.activityObj.table === undefined || this.state.activityObj.table.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleFields()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleFields()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.table && this.state.activityObj.table.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleFields()} />}
                            </div>
                            {(this.state.activityObj.table === undefined || this.state.activityObj.table.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected fields')}</div>
                                </div>
                            }
                            {this.state.activityObj.table && this.state.activityObj.table.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border}/>
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Title')}</th>
                                                <th className={styles.table_header}>{i18n.t('Land area')}</th>
                                                <th className={styles.table_header}>{i18n.t('Treated surface')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.table.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.povrsina)).toFixed(2)} ha</td>
                                                    <td className={styles.table_row_text}>{<TextField  type="number"  pattern="[0-9]"    
                                                                                                    id={'search'+ item.id_tabla} 
                                                                                                    onChange={(e) => { this.handleTreatedSurfaceChangeValue(e, item.povrsina, item.id_tabla); this.handleTreatedSurfaceChange(item.id_tabla)}} 
                                                                                                    value= {item.obradjena_povrsina !== null ? item.obradjena_povrsina : (Number(item.povrsina)).toFixed(2)}
                                                                                                    >                                                
                                                        </TextField>}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteFields(i)} />
                                                    </td>
                                                </tr>
                                                     
                                            })}
                                            
                                        </table>
                                    </div>
                                    {this.state.activityObj.table.length > 0 &&<div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
        
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div>
                                                    {i18n.t('Total land area')}: {(this.state.activityObj.table.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha
                                                    </div>
                                                    <div style={{ marginLeft: '9%'}}>
                                                    {i18n.t('Total treated area')}: {(this.state.activityObj.table.reduce((prev, item) => 
                                                    item.obradjena_povrsina === null ? prev + Number(item.povrsina) : prev + Number(item.obradjena_povrsina)
                                                    , 0)).toFixed(2)} ha
                                                    </div>
                                                </div>
                                            </div>}
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./material.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Materials')}</div>
                                    <div className={styles.add_button} onClick={() => this.handleMaterials()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleMaterials()} />
                                    </div>
                                </div>
                                {/* <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleMaterials()} /> */}
                            </div>
                            {materialList.length === 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no added materials')}</div>
                                </div>
                            }
                            {materialList.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Material')}</th>
                                                <th className={styles.table_header}>{i18n.t('Amount spent')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {materialList.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.utroseno)).toFixed(2)} {item.jedinica_mere}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteMaterials(item.matId)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./plant.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Products')}</div>
                                    <div className={styles.add_button} onClick={() => this.handleProducts()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleProducts()} />
                                    </div>
                                </div>
                                {/* <img src="./edit.svg" height='26px' className={styles.edit_icon} /> */}
                            </div>
                            {productList.length === 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no added products')}</div>
                                </div>
                            }
                            {productList.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Yield')}</th>
                                                <th className={styles.table_header}>{i18n.t('Amount')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {productList.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.prinos)).toFixed(2)} {item.jedinica_mere}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteProducts(item.matId)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./tractor_2.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Machines')}</div>
                                    {(this.state.activityObj.pogonskeMasine === undefined || this.state.activityObj.pogonskeMasine.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleMachines()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleMachines()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.pogonskeMasine && this.state.activityObj.pogonskeMasine.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleMachines()} />}
                            </div>
                            {(this.state.activityObj.pogonskeMasine === undefined || this.state.activityObj.pogonskeMasine.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected machines')}</div>
                                </div>
                            }
                            {this.state.activityObj.pogonskeMasine && this.state.activityObj.pogonskeMasine.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Machines')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.pogonskeMasine.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteMachines(i)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./tractor_2.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Implements')}</div>
                                    {(this.state.activityObj.prikljucneMasine === undefined || this.state.activityObj.prikljucneMasine.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleImplements()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleImplements()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.prikljucneMasine && this.state.activityObj.prikljucneMasine.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleImplements()} />}
                            </div>
                            {(this.state.activityObj.prikljucneMasine === undefined || this.state.activityObj.prikljucneMasine.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected implements')}</div>
                                </div>
                            }
                            {this.state.activityObj.prikljucneMasine && this.state.activityObj.prikljucneMasine.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Implements')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.prikljucneMasine.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteImplements(i)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section} style={{ marginBottom: '0px' }}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./edit_3.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Description')}</div>
                                    {this.state.commentOpen &&
                                        <div className={styles.add_button} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => this.setState({ commentOpen: !this.state.commentOpen }, () => {
                                            if (this.state.comment !== '') this.handleComment(this.state.comment)
                                        })}>
                                            <img src="./save.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                            <div style={{ fontSize: 14, marginLeft: '8px' }} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })}>{i18n.t('Save')}</div>
                                        </div>
                                    }
                                    {this.state.commentOpen === false && this.state.comment === '' && this.state.activityObj.komentar === undefined &&
                                        <div className={styles.add_button} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                        </div>
                                    }
                                    {/* {this.state.commentOpen === false && this.state.comment !== '' && this.state.activityObj.komentar !== undefined &&
                                        <div className={styles.add_button} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })}>
                                            <img src="./edit.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                        </div>
                                    } */}
                                </div>
                                {this.state.commentOpen === false && this.state.comment !== '' && this.state.activityObj.komentar !== undefined && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />}

                            </div>
                            {this.state.activityObj.komentar !== undefined && this.state.comment !== '' && this.state.commentOpen === false &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            <div style={{ minWidth: 'fit-content' }}>
                                                <div className={styles.working_operation_color}>
                                                    {i18n.t('Description')}:
                                                </div>
                                                <div className={styles.working_operation}>
                                                    {this.state.comment}
                                                </div>
                                            </div>
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} style={{ marginTop: '22px' }} onClick={() => this.setState({ comment: '' }, () => {
                                            this.deleteComment();
                                        })} />
                                    </div>
                                </div>
                            }
                            {this.state.activityObj.komentar === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no activity desrciption')}</div>
                                </div>
                            }
                        </div>

                        {this.state.commentOpen &&
                            <TextField
                                label={i18n.t('Activity description')}
                                value={this.state.comment}
                                style={{ width: '100%', marginTop: '10px', margin: '10px 8px 0px 8px' }}
                                multiline
                                rows={4}
                                variant='outlined'
                                onChange={(e) => { this.setState({ comment: e.target.value }); }} >
                            </TextField>
                        }

                        <div style={{ position: 'relative', height: '200px' }}>
                            <nav className={status["menu"]}>
                                <input type="checkbox" href="#" className={status["menu-open"]} name="menu-open" id="menu-open" checked={this.state.statusOpen} /><label className={status["menu-open-button"]} style={{ background: statusColor }} onClick={() => this.handleStatusOpen()}>
                                    {this.state.activityObj.id_status === 0 && this.state.statusOpen === false && <div>
                                        {/* <span className={`${status.lines} ${status["line-1"]}`}></span>
                                        <span className={`${status.lines} ${status["line-2"]}`}></span>
                                        <span className={`${status.lines} ${status["line-3"]}`}></span> */}
                                        <img src="./add.svg" className={styles.add_icon_menu} height='28px' />
                                    </div>}
                                    {this.state.activityObj.id_status !== 0 && this.state.statusOpen === false &&
                                        <img src={statusIcon} height="32px" style={this.state.activityObj.id_status === 3 ? { padding: '14px 0px 0px 5px' } : { padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                    }
                                    {this.state.statusOpen && <img src="./status/close.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />}
                                </label>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(3) }} className={`${status["menu-item"]} ${status["open"]}`} onMouseOver={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })} onMouseOut={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipOpen && this.state.statusOpen) ? status.tooltip_open_hover : status.tooltip_open}>{i18n.t('Open ')}</div>
                                    <img src="./status/otvoren.svg" height="32px" style={{ padding: '14px 0px 0px 5px' }} className={status.icon_filter} />
                                </a>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(2) }} className={`${status["menu-item"]} ${status["progress"]}`} onMouseOver={() => this.setState({ tooltipProgress: !this.state.tooltipProgress })} onMouseOut={() => this.setState({ tooltipProgress: !this.state.tooltipProgress })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipProgress && this.state.statusOpen) ? status.tooltip_progress_hover : status.tooltip_progress}>{i18n.t('In progress')}</div>
                                    <img src="./status/utoku.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} /> </a>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(1) }} className={`${status["menu-item"]} ${status["finished"]}`} onMouseOver={() => this.setState({ tooltipFinished: !this.state.tooltipFinished })} onMouseOut={() => this.setState({ tooltipFinished: !this.state.tooltipFinished })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipFinished && this.state.statusOpen) ? status.tooltip_finished_hover : status.tooltip_finished}>{i18n.t('Finished')}</div>
                                    <img src="./status/zavrsen.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                </a>
                                <a className={`${status["menu-item"]} ${status["purple"]}`} style={{ display: 'none' }}>
                                    <i style={{ display: 'none' }}></i>
                                </a>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(4) }} className={`${status["menu-item"]} ${status["locked"]}`} onMouseOver={() => this.setState({ tooltipLocked: !this.state.tooltipLocked })} onMouseOut={() => this.setState({ tooltipLocked: !this.state.tooltipLocked })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipLocked && this.state.statusOpen) ? status.tooltip_locked_hover : status.tooltip_locked}>{i18n.t('Locked')}</div>
                                    <img src="./status/zakljucan.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                </a>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(5) }} className={`${status["menu-item"]} ${status["planned"]}`} onMouseOver={() => this.setState({ tooltipPlanned: !this.state.tooltipPlanned })} onMouseOut={() => this.setState({ tooltipPlanned: !this.state.tooltipPlanned })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipPlanned && this.state.statusOpen) ? status.tooltip_planned_hover : status.tooltip_planned}>{i18n.t('Planned')}</div>
                                    <img src="./status/planski.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                </a>
                            </nav>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '-50px', fontSize: '22px', opacity: this.state.statusOpen ? '0' : '1', transition: 'opacity 0.5', fontWeight: '500', visibility: this.state.statusOpen ? 'hidden' : 'initial' }}>{statusText}</div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <div className={styles.save_button} onClick={() => this.goTo('/')}>
                                {i18n.t('Cancel')}
                            </div>
                            <div className={saveDisabled ? styles.save_button_disabled : styles.save_button} onClick={() => {
                                if (this.state.activityObj.id_radna_operacija &&
                                    this.state.activityObj.datum_pocetka &&
                                    this.state.activityObj.table &&
                                    this.state.activityObj.table.length > 0 &&
                                    this.state.activityObj.id_status !== 0
                                ) {
                                    this.handleAdd();
                                }
                                else wentWrong(i18n.t('Enter required fields marked with *'))
                            }}>
                                {editText ? i18n.t('Edit') : (this.state.copy ? i18n.t('Copy') : i18n.t('Add'))}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>{i18n.t('Enter required fields marked with *')}</div>
                    </div>
                </div>
                {this.state.workingOperationOpen && <WorkingOperation open={this.state.workingOperationOpen} handleClose={this.handleWorkingOperation} handleWorkingOperationObj={this.handleWorkingOperationObj} workingOperation={{ label: this.state.workingOperationString, value: this.state.activityObj.id_radna_operacija }} workingOperationDescription={this.state.activityObj.opis_radna_operacija} />}
                {this.state.dateAndTimeOpen && <DateAndTime open={this.state.dateAndTimeOpen} handleClose={this.handleDateAndTime} handleDateAndTimeObj={this.handleDateAndTimeObj} activityObj={this.state.activityObj} />}
                {this.state.fieldsOpen && <Fields open={this.state.fieldsOpen} handleClose={this.handleFields} handleFieldsObj={this.handleFieldsObj} activityObj={this.state.activityObj} />}
                {this.state.materialsOpen && <Materials open={this.state.materialsOpen} handleClose={this.handleMaterials} handleMaterialsObj={this.handleMaterialsObj} />}
                {this.state.productsOpen && <Products open={this.state.productsOpen} handleClose={this.handleProducts} handleProductsObj={this.handleProductsObj} />}
                {this.state.machinesOpen && <Machines open={this.state.machinesOpen} handleClose={this.handleMachines} handleMachinesObj={this.handleMachinesObj} activityObj={this.state.activityObj} />}
                {this.state.implementsOpen && <Implements open={this.state.implementsOpen} handleClose={this.handleImplements} handleImplementsObj={this.handleImplementsObj} activityObj={this.state.activityObj} />}
            </div>
        );
    }
}