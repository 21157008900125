/**
 * Created by pajicv on 5/10/18.
 */

import immutable from 'seamless-immutable';

import * as LayerActions from '../../actions/map/LayerActions';
import * as MapActions from '../../actions/map/MapActions';

import { FEATURE_LOAD_STEPS, SENTINEL_HUB_API_KEY, DAY_INTERVAL, getTodayDate } from '../../utils/constants';

export const INITIAL_STATE = immutable({
    layers: {
        byId: {}
    },
    layerGroups: {
        byId: {}
    },
    editedLayerId: 'parcels',
    newParcel: null,
    newParcelSaveSuccess: false,
    newField: null,
    parcelFeatures: null,
    parcelFeaturesLoaded: null,
    parcelFeaturesLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    fieldFeatures: null,
    fieldFeaturesLoaded: null,
    fieldFeaturesLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    noteFeatures: null,
    noteFeaturesLoaded: null,
    noteFeaturesLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    agriResearchInstitutesLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    riverBasinAuthLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    agriUniversitiesLoadingStep: FEATURE_LOAD_STEPS.IDLE,
    seasonalForecast: [],
    seasonalForecastFetching: false,
    seasonalForecastFetchingFailed: false,
    mapInitFetching: true,
    mapInitFetchingFailed: false
});


function getLayersInGroup(state, layerGroupId) {

    if(layerGroupId === "weatherLayers") return [state.layers.byId["forecast10days"]];

    const layerGroup = state.layerGroups.byId[layerGroupId];

    const layers = layerGroup.layers.map(layerId => state.layers.byId[layerId]);

    return layers;

}

function calculateLayerGroupVisibility(layers) {

    return layers.reduce((accumulator, layer) => accumulator || layer.visible, false)

}

function toggleOverlayVisibilityChange(state, payload) {

    const {leaf, id, visible} = payload;

    let newState;

    if(leaf) {

        const layer = state.layers.byId[id];

        const layerGroup = state.layerGroups.byId[layer.layerGroupId];

        newState = immutable.setIn(state, ["layers", "byId", id, "visible"], visible);

        const layersInGroup = getLayersInGroup(newState, layer.layerGroupId);

        const layerGroupVisibility = calculateLayerGroupVisibility(layersInGroup);

        newState = immutable.setIn(newState, ["layerGroups", "byId", layer.layerGroupId, "visible"], layerGroupVisibility);

    } else {

            const layers = state.layerGroups.byId[id].layers;

            newState = immutable.setIn(state, ["layerGroups", "byId", id, "visible"], visible);

            const reducingFunc = (accumulator, layerId) => immutable.setIn(accumulator, ["layers", "byId", layerId, "visible"], visible);

            newState = layers.reduce(reducingFunc, newState);

    }

    return newState;
}

function setActiveBaseLayer(state, payload) {

    const { baseLayerId, visible } = payload;

    const { activeBaseLayerId } = state;

    if(baseLayerId === activeBaseLayerId) return state;

    let newState = immutable.setIn(state, ['layers', 'byId', baseLayerId, 'visible'], true);

    newState = immutable.setIn(newState, ['layers', 'byId', activeBaseLayerId, 'visible'], false);

    newState = immutable.setIn(newState, ['activeBaseLayerId'], baseLayerId);

    return newState;

}

function changeEditedLayer(state, payload) {

    const {layerId, isEdited} = payload;

    if(isEdited) {
        return immutable.setIn(state, ['editedLayerId'], layerId);
    } else {
        return immutable.setIn(state, ['editedLayerId'], null);
    }

}

export default function reducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case LayerActions.IMAGERY_DATE_SELECTED:
            return immutable.setIn(state, ["layers", "byId", action.layerId, "date"], action.selectedDate);

        case LayerActions.LAYER_VISIBILITY_CHANGE:
            return toggleOverlayVisibilityChange(state, action.payload);

        case LayerActions.BASE_LAYER_CHANGE:
            return setActiveBaseLayer(state, action.payload);

        case LayerActions.EDITED_LAYER_CHANGE:
            return changeEditedLayer(state, action.payload);

        //Parcel GET call
        case LayerActions.PARCEL_GET_CALL: {
            const parcelFeaturesLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ parcelFeaturesLoadingStep });
        }

        case LayerActions.PARCEL_GET_SUCCESS: {
            const parcelFeaturesLoaded = action.data;
            const parcelFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            return state.merge({ parcelFeaturesLoaded, parcelFeaturesLoadingStep });
        }

        case LayerActions.PARCEL_GET_FAILED: {
            const parcelFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            const parcelFeaturesLoaded = null;
            return state.merge({ parcelFeaturesLoadingStep, parcelFeaturesLoaded });
        }

        //Field GET call
        case LayerActions.FIELD_GET_CALL: {
            const fieldFeaturesLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ fieldFeaturesLoadingStep });
        }

        case LayerActions.FIELD_GET_SUCCESS: {
            const fieldFeaturesLoaded = action.data;
            const fieldFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            return state.merge({ fieldFeaturesLoaded, fieldFeaturesLoadingStep });
        }

        case LayerActions.FIELD_GET_FAILED: {
            const fieldFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            const fieldFeaturesLoaded = null;
            return state.merge({ fieldFeaturesLoadingStep, fieldFeaturesLoaded });
        }

        //Note GET call
        case LayerActions.NOTE_GET_CALL: {
            const noteFeaturesLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ noteFeaturesLoadingStep });
        }

        case LayerActions.NOTE_GET_SUCCESS: {
            const noteFeaturesLoaded = action.data;
            const noteFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            return state.merge({ noteFeaturesLoaded, noteFeaturesLoadingStep });
        }

        case LayerActions.NOTE_GET_FAILED: {
            const noteFeaturesLoadingStep = FEATURE_LOAD_STEPS.SUCCESS;
            const noteFeaturesLoaded = null;
            return state.merge({ noteFeaturesLoadingStep, noteFeaturesLoaded });
        }

        //Institutions GET call
        case LayerActions.AGRI_RESEARCH_INSTITUTES_GET_CALL: {
            const agriResearchInstitutesLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ agriResearchInstitutesLoadingStep });
        }

        case LayerActions.AGRI_RESEARCH_INSTITUTES_GET_SUCCESS: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'agriResearchInstitutes', 'markers'], action.data);
            return immutable.setIn(newState, ['agriResearchInstitutesLoadingStep'], FEATURE_LOAD_STEPS.SUCCESS);
        }

        case LayerActions.AGRI_RESEARCH_INSTITUTES_GET_FAILED: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'agriResearchInstitutes', 'markers'], []);
            return immutable.setIn(newState, ['agriResearchInstitutesLoadingStep'], FEATURE_LOAD_STEPS.IDLE);
        }

        //River basin GET call
        case LayerActions.RIVER_BASIN_AUTH_GET_CALL: {
            const riverBasinAuthLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ riverBasinAuthLoadingStep });
        }

        case LayerActions.RIVER_BASIN_AUTH_GET_SUCCESS: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'riverBasinAuthorities', 'markers'], action.data);
            return immutable.setIn(newState, ['riverBasinAuthLoadingStep'], FEATURE_LOAD_STEPS.SUCCESS);
        }

        case LayerActions.RIVER_BASIN_AUTH_GET_FAILED: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'riverBasinAuthorities', 'markers'], []);
            return immutable.setIn(newState, ['riverBasinAuthLoadingStep'], FEATURE_LOAD_STEPS.IDLE);
        }

        //Universities GET call
        case LayerActions.AGRI_UNIVERSITIES_GET_CALL: {
            const agriUniversitiesLoadingStep = FEATURE_LOAD_STEPS.LOADING;
            return state.merge({ agriUniversitiesLoadingStep });
        }

        case LayerActions.AGRI_UNIVERSITIES_GET_SUCCESS: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'agriUniversities', 'markers'], action.data);
            return immutable.setIn(newState, ['agriUniversitiesLoadingStep'], FEATURE_LOAD_STEPS.SUCCESS);
        }

        case LayerActions.AGRI_UNIVERSITIES_GET_FAILED: {
            let newState = immutable.setIn(state, ['layers', 'byId', 'agriUniversities', 'markers'], []);
            return immutable.setIn(newState, ['agriUniversitiesLoadingStep'], FEATURE_LOAD_STEPS.IDLE);
        }

        //Parcel POST call
        case LayerActions.PARCEL_SAVE_SUCCESS: {
            let newState = immutable.setIn(state, ['newParcel'], action.payload);
            newState = immutable.setIn(newState, ['newParcelSaveSuccess'], true);
            return newState;
        }

        case LayerActions.NEW_PARCEL_ADD_TO_MAP_SUCCESS: {
            let newState = immutable.setIn(state, ['newParcel'], null);
            newState = immutable.setIn(newState, ['newParcelSaveSuccess'], false);
            return newState;
        }

        case LayerActions.LOADED_PARCELS_DRAW_SUCCESS: {
            const parcelFeaturesLoadingStep = FEATURE_LOAD_STEPS.IDLE;
            const parcelFeaturesLoaded = null;
            return state.merge({ parcelFeaturesLoadingStep, parcelFeaturesLoaded });
        }

        //Seasonal forecast GET call
        case LayerActions.SEASONAL_FORECAST_GET_CALL: {
            return state.merge({ seasonalForecastFetching: true });
        }

        case LayerActions.SEASONAL_FORECAST_GET_SUCCESS: {
            let newState = immutable.setIn(state, ['seasonalForecast'], action.data);
            return immutable.setIn(newState, ['seasonalForecastFetching'], false);
        }

        case LayerActions.SEASONAL_FORECAST_GET_FAILED: {
            let newState = immutable.setIn(state, ['seasonalForecast'], []);
            return immutable.setIn(newState, ['seasonalForecastFetching'], false);
        }

        //Map actions
        case MapActions.MAP_GET_INIT_CALL: {
            return state.merge({ mapInitFetching: true });
        }

        case MapActions.MAP_GET_INIT_SUCCESS: {
            return immutable.merge(state, { ...action.data, mapInitFetching: false });
        }

        case MapActions.MAP_GET_INIT_FAILED: {
            return immutable.merge(state,{mapInitFetching: false, mapInitFetchingFailed: true});
        }

        default:
            return state;
    }

}