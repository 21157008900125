import React from 'react';
import axios from '../../utils/AxiosWrapper';
import i18n from '../../i18n/i18n';

import { Map, TileLayer, WMSTileLayer, Popup, Marker } from 'react-leaflet';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'proj4leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { GoogleLayer } from "react-leaflet-google"

import { zoomFormat } from './ZoomAndCenter';
import { createGrid } from './../../utils/MapUtils';
import { calculateArea } from '../../utils/MapUtils';
import { getDaysBetween } from './../../utils/constants';
import { wentWrong, wentRight } from '../../utils/ToastConfig';

import DatePicker from './../Map/LayerTree/Node/Tools/DatePicker';
import SensorDialog from './../sensor/SensorDialog';
import AddField from '../catastral_data/field/AddField';
import AddNote from '../note/AddNote';
import SatelliteInfo from './satellite/SatelliteInfo';

import { Dialog, DialogTitle, TextField, Typography, Button, IconButton, Tooltip, Radio, Checkbox, Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Restore, ExpandLess, ExpandMore, Search, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Select from 'react-select';
import classNames from 'classnames';
import MoonLoader from 'react-spinners';
import * as querystring from 'querystring';

const iframeStyle = {
    minWidth: '560px',
    minHeight: '500px',
    zIndex: 20000,
    opacity: 1,
    backgroundColor: 'white',
    border: '1px solid grey'
};

export class Maps extends React.Component {
    constructor(props) {
        super(props);

        this.EMPTY_FEATURE = { type: null, isDrawn: false, isCompleted: false, geometry: null, attributes: null };
        this.state = {
            add: false,
            newFeature: { ...this.EMPTY_FEATURE },
            season: [], seasons: [], layer_season: [], field_list: [], field_selected: [],
            center: [45.75, 19.44], bounds: [], zoom_latitude: null, zoom_longitude: null,
            zoom: 6, mounting: true, open: true, zoomMenu: false,
            minZoom: 6, date: null, tomorrow: null, weather_date: null, max_date: null,
            day_interval: 24 * 60 * 60 * 1000, forecasts: [], geoserver_region: '',
            row: {}, sensors: [], openSensorDialog: false, sensor_temp: null, flyto_state: false, satteliteDialog: false,
            stateType: [], states: [39, 19, 82, 166, 22, 873, 879, 890, 911, 1023], filter: null, meteoShown: false, clearStateColor: '#dbdbdb',
            currentSeason: null, currentLayer: null,

            baseLayersOpen: true, osmLayerShown: true, googleLayerShown: false,
            cadastralLayersOpen: true, fieldsLayerShown: true, parcelLayerShown: false,
            administrativeLayersOpen: true, administrativeLayerData: {}, selectedAdministrative: ['states'],
            satteliteLayersOpen: false, selectedSatellite: null, satelliteLayerData: {},
            transportLayersOpen: false, transportLayerData: {}, selectedTransport: [],
            fieldNotesLayersOpen: false, notesLayersShown: false, addNote: false, note_x: null, note_y: null,

            satteliteLayersIds: ["ndvi", "savi", "sr", "bathymetric", "ndwi", "ndmi", "lai", "evi", "burn"],
            satteliteLayers: ["NDVI", "SAVI", "SR", "BATHYMETRIC", "NDWI", "NDMI", "LAI", "EVI", "NBR"],
            satelliteLayersNames: ['NDVI', 'SAVI', 'SR', 'RGB', 'NDWI', 'NDMI', 'LAI', 'EVI', 'NBR'],
        };
    }

    componentDidMount() {
        let user = this.getUser();
        if (user.id_region === 13) this.setState({ center: [9.0587, 7.824] });
        else if (user.id_region === 25) this.setState({ center: [31.7649, 34.954] });
        else if (user.id_region === 20) this.setState({ center: [44.1926, 17.6498] });
        else if (user.id_region === 23) this.setState({ center: [42.7717, 19.2129] });

        let show_field = JSON.parse(localStorage.getItem('row'));
        let show_field_array = [];

        if (show_field !== null) {
            show_field_array.push({ label: show_field.naziv, value: show_field.id });
            this.setState({ field_selected: [] }, () => {
                this.setState({ field_selected: show_field_array });
                localStorage.removeItem('row');
            });
        };

        this.setState({ weather_date: this.getTodayDate(), date: this.getTodayDate(), max_date: this.getTodayDate() });

        axios.get('api/season/drop_down')
            .then(res => { this.setState({ seasons: res.data.data }); })
            .catch(err => { console.log(err) });

        axios.get('api/season/activity_season')
            .then(res => {
                this.setState({
                    currentSeason: res.data.data[0].id_default_sezona,
                    season: [...this.state.season, {
                        label: res.data.data[0].naziv,
                        value: res.data.data[0].id_default_sezona
                    }]
                },
                    () => {
                        axios.get('api/layer/layer_by_season', { params: { season: this.state.currentSeason } })
                            .then(res => {
                                this.setState({ currentLayer: res.data.data[0].id }, () => {
                                    this.loadFields();
                                });
                            })
                            .catch(err => { console.log(err) });
                    });
            })
            .catch(err => { console.log(err) });

        axios.get('api/region/geoserver').then(res => { if (res.data.data[0]) this.setState({ geoserver_region: res.data.data[0].geoserver }); }).catch(err => { console.log(err) });

        axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 2 } });
            
        const map = this.map.leafletElement;
        if(user.id_klijent !== 32052) {
            map.pm.addControls({ position: 'topleft', drawMarker: true, drawCircleMarker: false, drawPolyline: false, drawRectangle: false, drawPolygon: true, drawCircle: false, editMode: true, dragMode: false, cutPolygon: false, removalMode: true })
        }else{
            return null
        }

        map.on("pm:create", (e) => {
            const that = this;

            that.drawnFeature = e.layer;

            if (e.layer._latlngs) {
                let newFeature = { ...that.state.newFeature };
                newFeature.type = that.props.editedLayerId;
                newFeature.geometry = e.layer.getLatLngs();
                newFeature.isDrawn = true;
                newFeature.attributes = { povrsina: calculateArea(newFeature.geometry) };

                this.setState({ newFeature, add: true });
            }
            else if (e.layer._latlng) {
                let x = e.layer._latlng.lng;
                let y = e.layer._latlng.lat;
                this.setState({ note_x: x, note_y: y }, () => { this.setState({ addNote: true }); })
            }
        });

        map.on('moveend', e => {
            if (this.state.forecast10daysShown === true && this.state.weather_date !== null) {
                this.loadForecast(this.state.forecast10daysShown, map, this.state.weather_date);
            }
        });

        map.on('pm:drawstart', (e) => {
            map.eachLayer(layer => {
                if (layer.feature) {
                    if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {
                        layer.unbindPopup();
                    }
                }
            });
        });

        map.on('pm:remove', (e) => {
            if (e.layer.feature) {
                if (e.layer.feature.geometry.type === "Polygon" && e.layer.feature.properties.boja !== undefined) {
                    axios.get('api/field/check_field', { params: { id_tabla: e.layer.feature.id } })
                        .then(res => {
                            if (res.data) {
                                if ((res.data.data[0].count === "0")) {
                                    axios.post('api/field/delete_geom', { selection: [e.layer.feature.id] })
                                        .then(res => { wentRight(i18n.t('Field geometry succesfully deleted.')); })
                                        .catch(err => { wentWrong(i18n.t('Something went wrong.')); })
                                }
                                else {
                                    wentWrong(i18n.t('There are activities tied to this field.'));
                                    this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                    this.loadFields();
                                }
                            }
                        })
                        .catch(err => { console.log(err); })
                }
            }
            if (e.layer.options.icon) {
                if (e.layer.options.icon.options.iconUrl.includes('icon')) {
                    let id = null;
                    id = e.layer.pm._layer.options.icon.options.id;
                    if (e.layer.pm._layer && id !== null) {
                        axios.post('api/note/delete', { selection: [id] })
                            .then(res => { wentRight(i18n.t('Successfully deleted.')); })
                            .catch(err => { console.log(err); wentWrong(i18n.t('Something went wrong.')) })
                    }
                }
            }
        });
    };

    getTodayDate = () => {
        let date = new Date();
        let day = date.getDate();
        let month = new String(date.getMonth() + 1);
        let year = date.getFullYear();
        if (month.length === 1) month = '0' + month;
        return year + '-' + month + '-' + day;
    };

    getForecast = (mapBounds, day) => {
        const that = this;
        axios.post(`api/forecast/10days`, { locations: createGrid(mapBounds, 6, 4), day: 1 })
            .then(response => { that.setState({ forecasts: response.data.data }, () => { this.setState({ spinner: false }) }); })
            .catch(error => { console.log(error); that.setState({ forecasts: [], spinner: false }); wentWrong(i18n.t('Something went wrong.')); });
    };

    getSensorData = () => {
        let user = this.getUser()
        axios.get('map/read_map', { params: {  id_opstina : user.id_opstina } })
            .then(res => {
                this.setState({ sensors: res.data.data });
                if (this.state.states.includes(user.id_opstina)) {
                    let x = res.data.data.map(item => Number(item.x))
                    let y = res.data.data.map(item => Number(item.y))
                    let minx = Math.min(...x);
                    let miny = Math.min(...y);
                    let maxx = Math.max(...x);
                    let maxy = Math.max(...y);
                    let centerx = (minx + maxx) / 2;
                    let centery = (miny + maxy) / 2;

                    switch (user.id_opstina) {
                        case 19: this.map.leafletElement.flyTo([centery, centerx], 10, { animate: true, duration: 0.5 }); break;
                        case 22: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 39: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 82: this.map.leafletElement.flyTo([centery, centerx], 12, { animate: true, duration: 0.5 }); break;
                        case 166: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 873: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 879: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 890: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 911: this.map.leafletElement.flyTo([centery, centerx], 11, { animate: true, duration: 0.5 }); break;
                        case 1023: this.map.leafletElement.flyTo([centery, centerx], 10, { animate: true, duration: 0.5 }); break;
                    };
                }
            })
            .catch(err => { console.log(err) })
    }

    saveField = (attributes) => {
        const { newFeature: { geometry } } = this.state;
        axios.post('api/field/create', { ...attributes, geometry, id_layer: this.state.currentLayer })
            .then(res => {
                axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 10 } })
                this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } }, () => {
                    this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                    this.setState({ mounting: false }, () => { this.loadFields(); })
                });
            })
            .catch(err => { console.log(err); })
    };

    saveNote = (attributes) => {
        axios.post('api/note/create', { ...attributes, x: this.state.note_x, y: this.state.note_y })
            .then(res => {
                axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 12 } })
                this.setState({ addNote: false, note_x: null, note_y: null }, () => {
                    this.map.leafletElement.removeLayer(this.drawnFeature);
                    this.map.leafletElement.eachLayer(layer => {
                        if (layer._icon) {
                            if (layer._icon.src) {
                                if (layer._icon.src.includes('icon')) {
                                    layer.remove();
                                }
                            }
                        }
                    });
                    this.setState({ fieldNotesLayersOpen: true, notesLayersShown: true }, () => {
                        if (this.state.fieldsLayerShown === true) {
                            this.loadFields();
                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                        }
                        this.loadNotes();
                    })
                })
            })
    }

    loadForecast = (forecast, map, date) => {
        if (forecast === true) {
            this.setState({ spinner: true }, () => {
                const bounds = map.getBounds();
                const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

                this.setState({ weather_date: date, max_date: this.getTodayDate() }, () => {
                    const day = getDaysBetween(this.getTodayDate(), this.state.weather_date);
                    this.getForecast(mapBounds, day);
                });
            });
        }
    }

    loadFields = () => {
        let user = this.getUser();
        const map = this.map.leafletElement;

        let show_field = JSON.parse(localStorage.getItem('row'));
        let show_field_array = [];

        if (show_field !== null) { localStorage.removeItem('row'); }

        const bounds = map.getBounds();
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const bbox = mapBounds.join(',');
        const crs = 'EPSG:4326';
        let seasons = [];
        if (this.state.season) this.state.season.map((item, i) => { seasons.push(item.value) })

        let that = this;
        let mounting = this.state.mounting;
        let fields = [];
        if (that.state.field_selected) fields = that.state.field_selected.map((item, i) => item.value)

        axios.get('api/field/field_list', { params: { seasons } })
            .then(res => {
                let field_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    field_list.push({ id: res.data.data[i].id, name: res.data.data[i].naziv });
                }
                this.setState({ field_list });
            })
            .catch(err => { console.log(err) })

        axios.get('api/field?' + querystring.stringify({ bbox, seasons, crs, fields }))
            .then(response => {
                let parsed = JSON.parse(response.data.data.geojson);

                if (fields.length > 0) {
                    zoom_data = zoomFormat(parsed);
                    if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom + 1 });
                }
                else {
                    if (parsed.features.length === 0) {
                        axios.get('api/opstina/geom_opstina')
                            .then(res => {
                                parsed = (JSON.parse(res.data.data.rows[0].geojson));
                                if (parsed.features.length !== 0) {
                                    if (mounting === true) {
                                        zoom_data = zoomFormat(parsed);
                                        if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom + 1 });
                                    }
                                }
                            })
                            .catch(err => { console.log(err) })
                    }
                }

                let zoom_data = {};
                if (parsed.features.length !== 0) {
                    if (mounting === true) {
                        zoom_data = zoomFormat(parsed);
                        if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom });
                    }
                }

                L.geoJSON(parsed).addTo(map);

                map.eachLayer(layer => {
                    let popup1 = '';
                    let popup2 = '';
                    if (layer.feature) {
                        if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {

                            let field = layer.feature.properties;
                            let slash = ' / ', new_line = '</br>';
                            if (field.kultura_podgrupa_naziv === null) { slash = ''; new_line = ''; }

                            if (field.kultura_naziv === null) field.kultura_naziv = '';
                            if (field.kultura_grupa_naziv === null) field.kultura_grupa_naziv = '';
                            if (field.kultura_podgrupa_naziv === null) field.kultura_podgrupa_naziv = '';

                            popup1 = L.popup().setContent(`
                                    <p style="text-align: center;font-size:16px;font-family:roboto; overflow-wrap:anywhere">
                                        ${i18n.t('Naziv')}: ${field.naziv}
                                        <p style="font-size:14px;font-family:roboto">
                                            ${field.kultura_naziv}${new_line}
                                            ${field.kultura_grupa_naziv}${slash}${field.kultura_podgrupa_naziv}
                                        </p>
                                        <p style="font-size:16px;font-family:roboto">${i18n.t('Površina')}: ${field.povrsina} ha</p>
                                        <p style="font-size:16px;font-family:roboto">${i18n.t('Opis')}: ${field.opis}</p>
                                    </p>`
                            );

                            popup2 = L.popup().setContent(`
                                    <p style="text-align: center;font-size:16px;font-family:roboto; overflow-wrap:anywhere">
                                        ${i18n.t('Naziv')}: ${field.naziv}
                                        <p style="font-size:14px;font-family:roboto">
                                            ${field.kultura_naziv}${new_line}
                                            ${field.kultura_grupa_naziv}${slash}${field.kultura_podgrupa_naziv}
                                        </p>
                                        <p style="font-size:16px;font-family:roboto">${i18n.t('Površina')}: ${field.povrsina} ha</p>
                                    </p>`
                            );

                            {user.id_klijent === 32052 ? layer.bindPopup(popup1) : layer.bindPopup(popup2)}
                            
                            //layer.bindPopup(popup2);

                            layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.3, weight: 2 });
                            layer.on('mouseover', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.4, weight: 2 }); });
                            layer.on('mouseout', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.3, weight: 2 }); });

                            layer.on('pm:edit', (e) => {
                                axios.post('api/field/update_geom', {
                                            id: e.target.feature.id,
                                            geometry: e.target.getLatLngs(),
                                        })
                                            .then(res => {
                                                that.setState({ add: false, newFeature: { ...that.EMPTY_FEATURE } }, () => {
                                                    that.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                    that.setState({ mounting: false }, () => { that.loadFields(); })
                                                })
                                            })
                                            .catch(err => console.log(err))
                            });
                        }
                    }
                });
            })
            .catch(err => console.log(err));
        if (mounting === false) { this.setState({ mounting: true }) };
    };

    loadNotes = () => {
        const map = this.map.leafletElement;

        axios.get('api/note?', {})
            .then(res => {
                let parsed = JSON.parse(res.data.data.geojson);
                let markers = [];
                parsed.features.map((item, i) => {
                    let marker_coordinates = [item.geometry.coordinates[1], item.geometry.coordinates[0]];
                    let vrsta_beleske = item.properties.vrsta_beleske_id;

                    let Icon = null;
                    if (vrsta_beleske === 12) Icon = L.icon({ iconUrl: './note/fire-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 13) Icon = L.icon({ iconUrl: './note/disease-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 14) Icon = L.icon({ iconUrl: './note/pest-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 15) Icon = L.icon({ iconUrl: './note/steal-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 17) Icon = L.icon({ iconUrl: './note/mark-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 18) Icon = L.icon({ iconUrl: './note/weed-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 19) Icon = L.icon({ iconUrl: './note/analysis-icon-new.png', iconSize: [32, 32], id: item.id });
                    else Icon = L.icon({ iconUrl: './note/other-icon-new.png', iconSize: [32, 32], id: item.id });

                    let naslov = '', vrsta_beleske_naziv = '', datum_dogadjaja = '', opis = '';
                    if (item.properties.naslov !== null) naslov = `<div style="font-size:18px;font-weight:bold">${item.properties.naslov}</div>`;
                    if (item.properties.vrsta_beleske_naziv !== null) vrsta_beleske_naziv = `<div style="font-size:14px;font-weight:bold">${item.properties.vrsta_beleske_naziv}</div>`;
                    if (item.properties.datum_dogadjaja !== null) datum_dogadjaja = `<div style="margin-top:8px">${item.properties.datum_dogadjaja}</div>`;
                    if (item.properties.opis !== null) opis = `<div style="margin-top:4px">${item.properties.opis}</div>`;

                    let marker = L.marker(marker_coordinates, { icon: Icon }).bindPopup(
                        `<div>` + naslov + vrsta_beleske_naziv + datum_dogadjaja + opis + `</div>`
                    );
                    markers.push(marker);
                })
                L.layerGroup(markers).addTo(map)
            })
            .catch(err => { console.log(err) })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    handleClick = (item) => { this.setState({ openSensorDialog: !this.state.openSensorDialog, row: item }) };
    handleClickSatellite = (item) => { this.setState({ satteliteDialog: !this.state.satteliteDialog, row: item }) };
    handleCloseZoomMenu = () => { this.setState({ zoomMenu: !this.state.zoomMenu }); };

    handleFields = () => {
        this.setState({ fieldsLayerShown: !this.state.fieldsLayerShown }, () => {
            if (this.state.fieldsLayerShown === true) this.loadFields();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer.feature) {
                    if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {
                        layer.remove();
                    }
                }
            });
        });
    };

    handleNotes = () => {
        this.setState({ notesLayersShown: !this.state.notesLayersShown }, () => {
            if (this.state.notesLayersShown === true) this.loadNotes();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer._icon) {
                    if (layer._icon.src) {
                        if (layer._icon.src.includes('icon')) {
                            layer.remove();
                        }
                    }
                }
            });
        });
    };

    handleSatellites = (key, index) => {

        this.setState({ satelliteLayerData: {} }, () => {
            if (this.state.selectedSatellite === key) {
                this.setState({ selectedSatellite: null });
                this.map.leafletElement.options.minZoom = 6;
            }
            else {
                this.setState({ selectedSatellite: key }, () => {
                    let zoom = this.map.leafletElement.getZoom();
                    if (zoom < 10) {
                        this.map.leafletElement.options.minZoom = 10;
                        this.map.leafletElement.flyTo(this.state.center, 10, { animate: true, duration: 0.5 });
                    }
                })
            };
            this.setState({ satelliteLayerData: key });
        })
    };

    handleTransports = (key) => {
        if (this.state.selectedTransport.includes(key)) {
            this.setState({ selectedTransport: this.state.selectedTransport.filter(item => item !== key) });
        }
        else this.setState({ selectedTransport: [...this.state.selectedTransport, key] });
    };

    handleTransportGroup = () => { this.setState({ transportLayersOpen: !this.state.transportLayersOpen }); };
    handleMeteo = () => {
        if (this.state.meteoShown === true) { this.setState({ filter: null }); };

        this.setState({ meteoShown: !this.state.meteoShown }, () => {
            if (this.state.meteoShown === true) { this.getSensorData(); };
        });
    };

    handleAdministrative = (item) => {
        if (this.state.selectedAdministrative.includes(item)) {
            this.setState({ selectedAdministrative: this.state.selectedAdministrative.filter(el => el !== item) });
        }
        else this.setState({ selectedAdministrative: [...this.state.selectedAdministrative, item] });

    };

    handleAdministrativeGroup = () => { this.setState({ administrativeLayersOpen: !this.state.administrativeLayersOpen }); };

    handleBaseLayers = () => { this.setState({ osmLayerShown: !this.state.osmLayerShown, googleLayerShown: !this.state.googleLayerShown }); };
    handleDateChange = (e) => { this.setState({ date: e.target.value }) };

    handleForecasts = () => {
        if (this.state.spinner === true) this.setState({ spinner: false });
        this.setState({ forecast10daysShown: !this.state.forecast10daysShown }, () => {
            if (this.state.forecast10daysShown === true && this.state.weather_date !== null) this.loadForecast(this.state.forecast10daysShown, this.map.leafletElement, this.state.weather_date);
        });
    };

    handleWeatherDateChange = (e) => {
        this.setState({ weather_date: e.target.value }, () => {
            if (this.state.forecast10daysShown === true) this.loadForecast(this.state.forecast10daysShown, this.map.leafletElement, this.state.weather_date);
        })
    };

    render() {
        let user = this.getUser();
        let lite_users = [32139, 32379];
        let lite_check = lite_users.includes(user.id_klijent);

        const { classes } = this.props;
        return (
            <div className="page" style={{ height: '100%' }}>
                <Map center={this.state.center} zoom={this.state.zoom} className={this.state.open ? classes.mapClosed : classes.mapOpen}
                    doubleClickZoom={false} minZoom={this.state.minZoom} maxZoom={18} ref={(c) => { this.map = c }}>
                    {this.state.osmLayerShown && <TileLayer zIndex={2000} attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />}
                    {this.state.googleLayerShown && <GoogleLayer zIndex={2000} googlekey={'AIzaSyBuZJYdqpIR_vERdvSmk0zNRrCQMBiMaz0'} maptype={'SATELLITE'} />}
                    {this.state.administrativeLayerData !== {} && this.state.selectedAdministrative !== [] &&
                        this.state.selectedAdministrative.map((item, i) => {
                            if (this.state.selectedAdministrative.includes(item)) {
                                let type = '';
                                if (this.state.selectedAdministrative[i] === "states") type = "OT_opstine";
                                else if (this.state.selectedAdministrative[i] === "localGovernments") type = "OT_katastarska_opstina";

                                if (this.state.geoserver_region !== '') {
                                    return <WMSTileLayer
                                        key={item}
                                        format={'image/png'}
                                        layers={`${this.state.geoserver_region}:${type}`}
                                        url={'map/geoserver'}
                                        tileSize={512}
                                        zIndex={2200}
                                        transparent={true} />
                                }
                            }
                        })
                    }
                    {this.state.transportLayerData !== {} && this.state.selectedTransport !== [] && user.id_region === 2 &&
                        this.state.selectedTransport.map((item, index) => {
                            if (this.state.selectedTransport.includes(item)) {
                                let layer = '';
                                if (this.state.selectedTransport[index] === ("port")) layer = "srbija:GT_luka";
                                else if (this.state.selectedTransport[index] === ("railroad")) layer = "srbija:gt_pruga_osm";
                                return <WMSTileLayer
                                    key={index}
                                    format={'image/png'}
                                    layers={layer}
                                    url={'map/geoserver'}
                                    tileSize={512}
                                    zIndex={2300}
                                    transparent={true} />
                            }
                        })
                    }
                    {this.state.sensors.length > 0 && this.state.sensors !== undefined && this.state.meteoShown === true && this.state.sensors.map(((item, i) => {
                        return <Marker key={i} position={[Number(item.y), Number(item.x)]} onClick={() => {
                            this.setState({ sensor_temp: null });
                            let data_path = '/data/optimized/' + item.uid + '/hourly/last/1h';
                            axios.post('/api/testMeteo/meteo', { path: data_path, method: "GET", userRegion: user.id_region })
                                .then(res => { this.setState({ sensor_temp: res.data.data.data["18_X_X_506"].aggr.avg[0] }); })
                                .catch(err => { console.log(err) })
                        }}>
                            <Popup className="popup-sensor">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '18px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.naziv.substring(0, 15)}
                                            {item.naziv.length > 15 && '...'}
                                            {this.state.sensor_temp !== null &&
                                                <Typography style={{ fontSize: '18px', marginTop: '0px', marginBottom: '0px' }}> {this.state.sensor_temp}°C</Typography>
                                            }
                                        </div>
                                    </Typography>
                                    <Button className={classes.open_button} onClick={() => { this.handleClick(item); }}>{i18n.t('More')}</Button>
                                </div>
                            </Popup>
                        </Marker>
                    }))}
                    {this.state.satelliteLayerData !== {} && this.state.selectedSatellite !== null && this.state.date !== null &&
                        <WMSTileLayer
                            url={"https://services.sentinel-hub.com/ogc/wms/4a0d1b51-6ef0-4545-a0e7-d46d38cf3c3d"}
                            time={this.state.date}
                            format={"image/jpeg"}
                            maxcc={20}
                            zIndex={2100}
                            layers={this.state.satelliteLayerData}
                            showLogo={false}
                        />
                    }
                </Map>
                {this.state.spinner === true && <div style={{ left: '38%', top: '40%', zIndex: 20000, position: 'absolute' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                <React.Fragment>
                    <div className={this.state.open ? classes.toggleButtonOpen : classes.toggleButtonClose}
                        onClick={() => {
                            setTimeout(() => { this.map.leafletElement.invalidateSize() }, 400);
                            this.setState({ open: !this.state.open });
                        }} >
                        {this.state.open ? <ChevronRight /> : <ChevronLeft />}
                    </div>
                    <div className={this.state.open ? classes.searchButtonOpen : classes.searchButtonClose}>
                        <IconButton onClick={() => {
                            this.setState({ zoomMenu: true })
                        }}>
                            <Search style={{ fontSize: 36 }} />
                        </IconButton>
                    </div>
                    <div className={this.state.open ? classes.seasonalForecastButtonOpen : classes.seasonalForecastButtonClose}>
                        <IconButton onClick={() => {
                            this.map.leafletElement.flyTo(this.state.center, this.state.zoom, { animate: true, duration: 0.5 })
                        }}>
                            <Restore style={{ fontSize: 36 }} />
                        </IconButton>
                    </div>
                    <Drawer
                        variant="permanent"
                        anchor="right"
                        className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open, })}
                        classes={{ paper: classNames({ [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open }), }}
                        open={this.state.open}>
                        <div className={classes.toolbar} />
                        <List style={{ minHeight: '-webkit-fill-available' }}>
                            {this.state.season && this.state.seasons &&
                                <Select style={{ marginLeft: '16px', minWidth: '90%' }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    value={this.state.season}
                                    defaultValue={this.state.season}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        this.setState({ season: e }, () => {
                                            this.setState({ mounting: false }, () => {
                                                this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                this.loadFields();
                                            })
                                        })
                                    }}
                                    options={this.state.seasons.map((item) => { return { label: item.naziv, value: item.id } })}
                                    className="map-selects"
                                    classNamePrefix="select"
                                    placeholder={`⠀⠀⠀ ⠀ ⠀⠀⠀ ⠀` + i18n.t('Select seasons')}
                                />}
                            {this.state.season.length > 0 &&
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleFields()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleFields()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./field-new2.png' />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('Fields')} className={classes.listItemText} />
                                            <Checkbox edge="end" onChange={() => this.handleFields()} checked={this.state.fieldsLayerShown} />
                                        </ListItem>
                                    </List>
                                </div>
                            }
                            {this.state.field_list && this.state.fieldsLayerShown === true && this.state.season.length > 0 &&
                                <Select style={{ marginLeft: '16px', minWidth: '90%', marginTop: '0px' }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    value={this.state.field_selected}
                                    defaultValue={this.state.field_selected}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        this.setState({ field_selected: e }, () => {
                                            if (e.length === 0) {
                                                this.setState({ mounting: true }, () => {
                                                    this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                    this.loadFields();
                                                })
                                            }
                                            else this.setState({ mounting: false }, () => {
                                                this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                this.loadFields();
                                            })
                                        })
                                    }}
                                    options={this.state.field_list.map((item) => { return { label: item.name, value: item.id } })}
                                    className="map-selects"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select fields')}
                                />}
                            <div>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={() => this.handleNotes()} className={classes.list_item}>
                                        <ListItemIcon onClick={() => this.handleNotes()}>
                                            <img style={{ width: '25px', height: '25px' }} src={'./note-icon-new2.png'} />
                                        </ListItemIcon>
                                        <ListItemText primary={i18n.t('Notes')} className={classes.listItemText} />
                                        <Checkbox edge="end" onChange={() => this.handleNotes()} checked={this.state.notesLayersShown} />
                                    </ListItem>
                                </List>
                            </div>

                            <div className={classes.menuGroupStyle} />

                            <div>
                                <ListItem button onClick={() => this.setState({ baseLayersOpen: !this.state.baseLayersOpen })} style={{ paddingTop: '16px' }}>
                                    <ListItemText primary={i18n.t('Base layers')} />
                                    {this.state.baseLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.baseLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleBaseLayers()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./osm-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary='Open Street Map' className={classes.listItemText} />
                                            <Radio edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.osmLayerShown} />
                                        </ListItem>
                                        <ListItem button onClick={() => this.handleBaseLayers()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./google-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary='Google Map' className={classes.listItemText} />
                                            <Radio edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.googleLayerShown} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </div>

                            <div>
                                <ListItem button onClick={() => this.handleAdministrativeGroup()}>
                                    <ListItemText primary={i18n.t('Administrative layers')} />
                                    {this.state.administrativeLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.administrativeLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <div>
                                            <ListItem button onClick={() => this.handleAdministrative("states")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleAdministrative("states")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={`./state.png`} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('States')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleAdministrative("states")} checked={this.state.selectedAdministrative.includes("states")} />
                                            </ListItem>
                                            <ListItem button onClick={() => this.handleAdministrative("localGovernments")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleAdministrative("localGovernments")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={`./local-gov.png`} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Local governments')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleAdministrative("localGovernments")} checked={this.state.selectedAdministrative.includes("localGovernments")} />
                                            </ListItem>
                                        </div>
                                    </List>
                                </Collapse>
                            </div>

                            {user.id_region === 2 &&
                                <div>
                                    <ListItem button onClick={() => this.handleTransportGroup()}>
                                        <ListItemText primary={i18n.t('Transport layers')} />
                                        {this.state.transportLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.state.transportLayersOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button onClick={() => this.handleTransports("railroad")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleTransports("railroad")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={'./railroad-icon.png'} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Railroads')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleTransports("railroad")} checked={this.state.selectedTransport.includes("railroad")} />
                                            </ListItem>
                                            <ListItem button onClick={() => this.handleTransports("port")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleTransports("port")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={'./port-icon.png'} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Ports')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleTransports("port")} checked={this.state.selectedTransport.includes("port")} />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </div>
                            }
                            <div className={classes.menuGroupStyle} />

                            <div>
                                <ListItem button onClick={() => this.setState({ satteliteLayersOpen: !this.state.satteliteLayersOpen })} style={{ paddingTop: '16px' }}>
                                    <ListItemText primary={i18n.t('Satellite Imagery')} />
                                    {this.state.satteliteLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.satteliteLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {this.state.satteliteLayers.map((key, index) => {
                                            return <div key={key}>
                                                <ListItem button onClick={() => this.handleSatellites(key, index)} className={classes.list_item}>
                                                    <ListItemIcon onClick={() => this.handleSatellites(key, index)}>
                                                        <img style={{ width: '25px', height: '25px', borderRadius: '5px' }} src={`./satellite/${key}.png`} />
                                                    </ListItemIcon>
                                                    <ListItemText style={{ lineHeight: '1px' }} primary={this.state.satelliteLayersNames[index]} className={classes.listItemText} />
                                                    <Radio edge="end" onChange={() => this.handleSatellites(key, index)} checked={this.state.selectedSatellite === key} />
                                                </ListItem>
                                                <Collapse disablePadding in={this.state.selectedSatellite === key}>
                                                    <List component="div" disablePadding style={{ marginTop: '-10px' }}>
                                                        <div style={{ display: 'flex', marginLeft: '-91px', }}>
                                                            <DatePicker date={this.state.date} onDateChange={this.handleDateChange} />
                                                            {/* <Tooltip
                                                                classes={{ tooltip: classes.htmlTooltip }} interactive placement={'left-end'}
                                                                title={<iframe style={iframeStyle} src={`https://manuals.agrolife.world/sentinel/${this.state.satteliteLayersIds[index]}${user.id_jezik === 1 ? '_rs' : ''}`} />}>
                                                                <IconButton color="secondary" style={{ marginRight: '1px' }}><span className="icon-info layerIcon" /></IconButton>
                                                            </Tooltip> */}
                                                            <span className="icon-info layerIcon" style={{ cursor: 'pointer', marginTop: '12px', marginRight: '12px' }} onClick={() => {
                                                                this.setState({ satteliteDialog: true });
                                                            }}></span>
                                                        </div>
                                                    </List>
                                                </Collapse>
                                            </div>
                                        })}
                                    </List>
                                </Collapse>
                            </div>

                            {this.state.sensors && lite_check === false &&
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleMeteo()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleMeteo()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./meteo-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('Meteo stations')} className={classes.listItemText} />
                                            <Checkbox edge="end" onChange={() => this.handleMeteo()} checked={this.state.meteoShown} />
                                        </ListItem>
                                        <div>
                                            {/* {this.state.meteoShown === true &&
                                                <div style={{ width: '92%', marginLeft: '11px', display: 'flex' }}>
                                                    <div style={{ width: '92%' }}>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            isClearable={false}
                                                            value={this.state.filter}
                                                            defaultValue={this.state.filter}
                                                            maxMenuHeight={200}
                                                            onChange={(e) => { this.setState({ filter: { label: e.label, value: e.value } }, () => { this.getSensorData(e.value); }); }}
                                                            options={[
                                                                { value: 39, label: 'Subotica' },
                                                                { value: 19, label: 'Sombor' },
                                                                { value: 82, label: 'Inđija' },
                                                                { value: 166, label: 'Vrbas' },
                                                                { value: 22, label: 'Bačka Topola' },
                                                                { value: 873, label: 'Srbac' },
                                                                { value: 879, label: 'Prijedor' },
                                                                { value: 890, label: 'Brod' },
                                                                { value: 911, label: 'Trebinje' },
                                                                { value: 1023, label: 'Ljubuški' },
                                                            ]}
                                                            placeholder={`⠀⠀⠀ ⠀ ⠀⠀⠀ ⠀` + i18n.t('Select states')} />
                                                    </div>
                                                    <span className={classes.sensor_span} style={{ backgroundColor: `${this.state.clearStateColor}` }}
                                                        onMouseEnter={() => { this.setState({ clearStateColor: '#e3e3e3' }) }}
                                                        onMouseLeave={() => { this.setState({ clearStateColor: '#dbdbdb' }) }}
                                                        onMouseDown={() => { this.setState({ clearStateColor: '#f0f0f0' }) }}
                                                        onClick={() => { this.setState({ filter: null }, () => { this.getSensorData(null) }); }}
                                                    >X</span>
                                                </div>
                                            } */}
                                            <div style={{ height: '10px' }} />
                                        </div>
                                    </List>
                                </div>
                            }
                        </List>
                    </Drawer>
                </React.Fragment>
                {this.state.add &&
                    <AddField
                        edit={false}
                        add={this.state.add}
                        povrsina={this.state.newFeature.attributes.povrsina}
                        seasons={this.state.season}
                        handleClose={() => {
                            this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                            this.loadFields();
                        }}
                        onSave={this.saveField}
                    />
                }
                {this.state.addNote &&
                    <AddNote
                        edit={false}
                        add={this.state.addNote}
                        handleClose={() => {
                            this.setState({ addNote: false, note_x: null, note_y: null });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                            this.loadFields();
                        }}
                        onSave={this.saveNote}
                    />
                }
                {this.state.openSensorDialog && <SensorDialog open={this.state.openSensorDialog} handleClick={this.handleClick} row={this.state.row} />}
                {this.state.satteliteDialog && <SatelliteInfo open={this.state.satteliteDialog} handleClick={this.handleClickSatellite} row={this.state.row} selectedSatellite={this.state.selectedSatellite} />}
                <Dialog
                    open={this.state.zoomMenu}
                    onClose={() => this.handleCloseZoomMenu()}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle style={{ backgroundColor: '#04764e', color: '#FFFFFF' }} id="simple-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginLeft: '20px', color: '#FFFFFF', marginTop: '0px', marginBottom: '0px' }}>{i18n.t('Zoom')}</p>
                            <IconButton style={{ margin: '0px', marginLeft: '81%', padding: '0px' }} onClick={() => this.handleCloseZoomMenu()}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <table>
                        <tr style={{ textAlign: '-webkit-center' }}>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Latitude')}
                                    value={this.state.zoom_latitude}
                                    style={{ margin: '10px' }}
                                    onChange={e => this.setState({ zoom_latitude: e.target.value })}
                                    helperText={i18n.t('Latitude')}
                                    id={i18n.t('Latitude')} name={i18n.t('Latitude')} />
                            </td>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Longitude')}
                                    value={this.state.zoom_longitude}
                                    style={{ margin: '10px' }}
                                    helperText={i18n.t('Longitude')}
                                    onChange={e => this.setState({ zoom_longitude: e.target.value })}
                                    id={i18n.t('Longitude')} name={i18n.t('Longitude')} />
                            </td>
                            <td>
                                <Button onClick={() => {
                                    if (this.state.zoom_latitude && this.state.zoom_longitude) {
                                        this.map.leafletElement.flyTo([this.state.zoom_latitude, this.state.zoom_longitude], this.state.zoom, { animate: true, duration: 0.5 });
                                    }
                                }}>{i18n.t('Zoom')}</Button>
                            </td>
                        </tr>
                    </table>
                </Dialog >
            </div >
        )
    }
};

export default withStyles(styles)(Maps);