import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';


export class InfoSowingWindowPotato extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Minimalna temperatura zemljišta pri sadnji krtola mora biti između 6-8॰C. Ukoliko je temperatura zemljišta niža, može se javiti bubuljičavost krompira. 
                            Optimalna temperatura za sadnju je od 10-15॰C. Pri temperaturi zemljišta od 18-25॰C krompir najbrže niče i to za 10-12 dana.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The minimum soil temperature during potato tuber planting should be between 6-8°C. If the soil temperature is lower, potato scab can occur. 
                            The optimal temperature for planting is 10-15°C. At a soil temperature of 18-25°C, potatoes sprout most quickly, usually within 10-12 days.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Minimalna temperatura tla pri sadnji gomolja mora biti između 6-8°C. Ako je temperatura tla niža, može se javiti bubuljičavost krumpira. 
                            Optimalna temperatura za sadnju je 10-15°C. Pri temperaturi tla od 18-25°C krumpir najbrže niče, za 10-12 dana.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowPotato);
