import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Button, Table, TableBody, Tooltip, TableCell, TableHead, TableRow, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import * as Icons from '@material-ui/icons';
import { getMachinesGroup, getMachinesSubgroup, getMachinesDropdown } from '../../actions/activity/ActivityActions';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        height: '35px',
        fontWeight: '500',
        fontSize: 16
    },
    body: {
        fontSize: 13,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        paddingLeft: '20px',
        paddingRight: '80px',
    }
}))(TableCell);
const styles = theme => ({
    innerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    card: {
        height: 500,
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    innerContainerTable: {
        width: '100%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    button: {
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        width: '235px'
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    typography: {
        paddingLeft: '20px',
        color: theme.palette.text.secondary,
        fontWeight: '600',
    }
});
export class ActivityMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pogonska: [],
            machineDropdown: [],
            // machinesGroupDropdown: [],
            // machinesSubgroupDropdown: [],
            pogonskeMasine: [],
            // id_pogonska_masina_grupa: {},
            // id_pogonska_masina_podgrupa: {},
            add: false,
        }
    }

    componentDidMount() {
        // this.props.getMachinesGroup();
        // this.props.getMachinesSubgroup();
        this.props.getMachinesDropdown();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.machinesGroupDropdown !== this.props.machinesGroupDropdown) {
        //     this.setState({
        //         machinesGroupDropdown: immutable.asMutable(this.props.machinesGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     })
        // }
        // if (prevProps.machinesSubgroupDropdown !== this.props.machinesSubgroupDropdown) {
        //     this.setState({
        //         machinesSubgroupDropdown: immutable.asMutable(this.props.machinesSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     })
        // }
        if (prevProps.machineDropdown !== this.props.machineDropdown) {
            this.setState({
                machineDropdown: immutable.asMutable(this.props.machineDropdown.map((item) => { return { label: item.naziv, value: item.id, naziv: item.naziv, id_pogonska_masina: item.id } }), { deep: true }),
            })
        }
        if (this.props.pogonskeMasine && prevState.pogonskeMasine !== this.props.pogonskeMasine) {
            this.setState({
                pogonskeMasine: this.props.pogonskeMasine
            })
        }
    }

    handleAdd = (pogonska) => {
        const { handleAddMachines } = this.props;
        const { pogonskeMasine } = this.state;
        let diff = _.differenceBy(pogonska, pogonskeMasine, 'id_pogonska_masina');
        handleAddMachines('pogonskeMasine', diff);
        this.setState({
            pogonska: [],
            add: false
        })
    }

    handleChangeValue = name => selected => {
        this.setState((state) => {
            let pogonska = state[name];
            pogonska = selected;
            return { ...state, pogonska };
        })
    }


    handlePopup = () => {
        this.setState({
            add: !this.state.add
        })
    }

    onDeleteClick = (pogonska, index) => {
        const { handleDeleteChild } = this.props
        handleDeleteChild('pogonskeMasine', index, pogonska);
        this.setState(prevState => {
            let newPogonske = prevState.pogonskeMasine;
            return { pogonskeMasine: newPogonske.filter((_, i) => i !== index) };
        })
    }


    render() {
        const { classes, theme, viewOnly } = this.props;
        const { pogonska, pogonskeMasine, machineDropdown } = this.state;
        const pogonskeRender = pogonskeMasine && pogonskeMasine.map((pogonska, i) => {
            if (!pogonska) return null;
            return <TableRow key={i}>
                <CustomTableCell>{pogonska.naziv && pogonska.naziv || pogonska.pogonska.naziv}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            className={classes.cssRoot}
                            disabled={viewOnly}
                            onClick={() => this.onDeleteClick(pogonska, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.innerContainer}>
                {this.state.add &&
                    <Dialog
                        open={this.state.add}
                        onClose={this.handlePopup}
                        maxWidth="sm"
                        disableBackdropClick
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle style={{ background: theme.palette.secondary.dark }} disableTypography={true} className="responsive-dialog-title">{i18n.t('Add machine')}
                            <IconButton onClick={this.handlePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <Card raised className={classes.card}>
                            <ReactSelect
                                closeMenuOnSelect={false}
                                isClearable={true}
                                isMulti={true}
                                isDisabled={viewOnly}
                                value={pogonska}
                                maxMenuHeight={250}
                                label={i18n.t('Machines')}
                                onChange={this.handleChangeValue('id_pogonska_masina')}
                                options={_.differenceBy(machineDropdown, pogonskeMasine, 'id_pogonska_masina')}
                                className="multi-select-activity-form"
                                classNamePrefix="select"
                                styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                                placeholder={i18n.t('Select')}
                            />
                            <div className={classes.buttonsRow}>
                                <Button disabled={Object.keys(pogonska).length === 0} onClick={() => this.handleAdd(pogonska)}>{i18n.t('Add')}</Button>
                                <Button onClick={this.handlePopup}>{i18n.t('Close')}</Button>
                            </div>
                        </Card>
                    </Dialog>
                }
                <div className={classes.panel}>
                    <Typography className={classes.typography} variant="subtitle1">{i18n.t('Machines')}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="Add"
                        disabled={viewOnly}
                        className={classes.button}
                        onClick={this.handlePopup}
                    >
                        {'+ ' + i18n.t('Add machine')}
                    </Button>
                </div>
                <div className={classes.innerContainerTable}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell >{i18n.t('Machine')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Delete')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pogonskeRender}
                        </TableBody>
                    </Table>
                </div>
            </div >)

    }

}


function mapStateToProps(state) {
    return {
        machineDropdown: state.activityReducer.machineDropdown,
        machineDropdownFetching: state.activityReducer.machineDropdownFetching,
        machineDropdownFetchingFailed: state.activityReducer.machineDropdownFetchingFailed,
        machinesGroupDropdown: state.activityReducer.machinesGroupDropdown,
        machinesGroupFetching: state.activityReducer.machinesGroupFetching,
        machinesGroupFetchingFailed: state.activityReducer.machinesGroupFetchingFailed,
        machinesSubgroupDropdown: state.activityReducer.machinesSubgroupDropdown,
        machinesSubgroupFetching: state.activityReducer.machinesSubgroupFetching,
        machinesSubgroupFetchingFailed: state.activityReducer.machinesSubgroupFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMachinesGroup: () => dispatch(getMachinesGroup()),
        getMachinesSubgroup: (id_grupa) => dispatch(getMachinesSubgroup(id_grupa)),
        getMachinesDropdown: () => dispatch(getMachinesDropdown()),

    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityMachine))