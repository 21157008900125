import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import { getNoteGroupDropDown, getFieldNoteById, getNoteDocument, getNoteReport, setInitialState, deleteNote } from '../../actions/note/NotesActions';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { exportDocument } from '../../actions/ExportActions';
import { Export } from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';
import AddNote from './AddNote';
import axios from '../../utils/AxiosWrapper';
import { Delete } from '../../utils/Delete';

const CheckboxTable = checkboxHOC(ReactTable);;
const styles = theme => ({
});
export class Note extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selection: [],
            selectAll: false,
            add: false,
            edit: false,
            delete: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        localStorage.removeItem('row');
        if (this.props.noteGroupDropDown.length === 0) {
            this.props.getNoteGroupDropDown();
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getNoteReport(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
    }

    componentDidUpdate(prevProps) {
        const { notesPostingSuccess, noteGroupDropDown } = this.props;

        if (prevProps.notesPostingSuccess === false && notesPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getNoteReport(wrapped.state);
            this.props.setInitialState('notesPostingSuccess');
        }

        if (prevProps.noteGroupDropDown !== noteGroupDropDown) {
            if (this.props.userProfile.id_jezik === 1) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
                })
            }
            else if (this.props.userProfile.id_jezik === 2) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv_en, value: item.id } }), { deep: true }),
                })
            }else if (this.props.userProfile.id_jezik === 4) {
                this.setState({
                    noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv_hr, value: item.id } }), { deep: true }),
                })
            }
        }

    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('notesObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('notesObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    selectAll: false,
                })
            }
        });
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteNote(this.state.selection);
        this.setState({ delete: false });
    }

    onEditClick = () => {
        const { getFieldNoteById, getNoteDocument } = this.props;
        const { selection } = this.state;
        getFieldNoteById(selection);
        this.setState({ add: !this.state.add, edit: true }, () => {
            getNoteDocument(selection)
        });
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        console.log('fnnn ttt',table)
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/field_note');
        this.setState({ export: !this.state.export });
    }

    onPieClick = () => {
        this.props.history.push('/notes_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/notes');
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'naslov',
            },
            {
                Header: i18n.t('Date of the event'),
                accessor: 'datum_dogadjaja',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                Header: i18n.t('Notes type'),
                accessor: 'naziv_en',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.noteGroupDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />

            },
            {
                Header: i18n.t('Name'),
                accessor: 'ime',
            },
            {
                Header: i18n.t('Surname'),
                accessor: 'prezime',
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis',
            }

        ];

        if (this.props.userProfile.id_jezik === 1) {
            columns[2].accessor = 'naziv';
        }
        if (this.props.userProfile.id_jezik === 4) {
            columns[2].accessor = 'naziv_hr';
        }

        const { toggleSelection, toggleAll, isSelected, handleClick } = this;
        const { selectAll, add, edit } = this.state;
        const { theme } = this.props;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit", fontSize: '13px', lineHeight: '20px', height: '32.8px' } };
                }
                return { style: { height: '32.8px' } };
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: '#3E9571'
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Button
                        onClick={() => this.handleClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Button>
                    <Button
                        onClick={() => this.onSearchClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Button>
                    <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                    {this.state.selection.length > 0 ?
                        <Button
                            onClick={() => this.onDeleteClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Button>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Button
                            onClick={() => this.onEditClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Izmeni1 headerIcon" />
                        </Button>
                        : null
                    }
                </div>
                <CheckboxTable
                    data={this.props.noteReport}
                    ref={r => (this.checkboxTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.noteReportPages}
                    loading={this.props.noteReportFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getNoteReport(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {add && <AddNote add={add} handleClose={handleClick} edit={edit} />}
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} /> */}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        noteReport: state.noteReducer.noteReport,
        noteReportFetching: state.noteReducer.noteReportFetching,
        noteReportPages: state.noteReducer.noteReportPages,
        noteGroupDropDown: state.noteReducer.noteGroupDropDown,
        noteGroupDropDownFetching: state.noteReducer.noteGroupDropDownFetching,
        noteGroupDropDownFetchingFailed: state.noteReducer.noteGroupDropDownFetchingFailed,
        notesPostingSuccess: state.noteReducer.notesPostingSuccess,
        userProfile: state.appReducer.userProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNoteReport: (state, instance) => dispatch(getNoteReport(state, instance)),
        getNoteGroupDropDown: () => dispatch(getNoteGroupDropDown()),
        exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getFieldNoteById: (id) => dispatch(getFieldNoteById(id)),
        getNoteDocument: (id) => dispatch(getNoteDocument(id)),
        deleteNote: (selection) => dispatch(deleteNote(selection)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Note));