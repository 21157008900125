import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, Link, Typography } from '@material-ui/core';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import MeteoInfoButton from '../MeteoInfoButton';
import InfoPrecipitation from '../Info/Forecast/InfoPrecipitation';

const styles = {};

export class ForecastPrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.totalPrec = this.totalPrec.bind(this);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            forecast: {},
            week: false,
            load: true,
            totalPrec: 0,
        };
    }

    componentDidMount() {
        this.getPessl();
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    getForecast = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatForecast(res); this.setState({ forecast: obj, load: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let forecast_path = '/forecast/' + station_id + '/hourly';
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        this.getForecast({ path: forecast_path, name: 'general7', method: "POST" });
    }
    totalPrec() {
        let niz = [];
        this.state.week ? niz = this.state.forecast.precipitation :  niz = this.state.forecast.precipitation.slice(0, 72)
        
        let total = 0;
        for (let i = 0; i < niz.length; i++) {
            total += niz[i];
            console.log(niz[i]);
        }
        // toFixed vraca samo jednu decimalu na primer: 3.2 a ne 3.200000
        return total.toFixed(1);
    }

    render() {
        const { classes } = this.props;

        // console.log('disease: ', this.state.disease);
        // console.log('data meteo: ', this.state.data);
        // console.log('forecast: ', this.state.forecast);
        // console.log(this.state.forecast);

        let graph1 = {};
        let graph2 = {};
        if (
            this.state.forecast.dates !== undefined
        ) {
            graph1 = {
                labels: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? this.state.forecast.precipitation : this.state.forecast.precipitation.slice(0, 72),
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 2,
                        pointBackgroundColor:'#86b8d9',
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.week ? this.state.forecast.precipitation_probability : this.state.forecast.precipitation_probability.slice(0, 72),
                        label: i18n.t('Precipitation probability [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#0d86d6',
                        borderWidth: 2,
                        pointRadius: 2,
                        pointBackgroundColor:'#0d86d6',
                        yAxisID: 'y-axis-4',
                        borderDash: [10, 5]
                    },
                ]
            };

            graph2 = {
                labels: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? this.state.forecast.precipitation : this.state.forecast.precipitation.slice(0, 72),
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 2,
                        pointBackgroundColor:'#86b8d9',
                        yAxisID: 'y-axis-1',
                    },
                    {
                        data: this.state.week ? this.state.forecast.leaf_wetness.map(item => item * 60) : this.state.forecast.leaf_wetness.slice(0, 72).map(item => item * 60),
                        label: i18n.t('Leaf wetness [min/h]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointRadius: 2,
                        pointBackgroundColor:'#7CFC00',
                        yAxisID: 'y-axis-2',
                    },
                ]
            };
        }
        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4,
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                        }
                    }
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4,
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('Precipitation and leaf wetness')}</p>
                        </div>
                        <div style={{marginLeft: 20}}>
                        <MeteoInfoButton
                            title={i18n.t('Precipitation and leaf wetness')}
                            text={<InfoPrecipitation />}
                            height='400px'
                            width='sm'
                            />
                        </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '40%', marginTop: '22px' }}>       
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                <div>
                     <div style={{display:'flex', flexDirection:'row', marginTop:'10px', marginBottom:'10px', width:'95%', justifyContent:'center', alignItems:'center', boxShadow:'0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)'}}>
                            <p style={{fontSize:'16px'}}>{i18n.t('Total precipitation for selected time period')}: </p> 
                            <p style={{marginLeft:'5px', fontWeight:'bold', fontSize:'18px'}}> {this.totalPrec()} mm </p>
                        </div>
                <div style={{ width: '95%', marginTop: '10px' }}>
                    <Bar data={graph1} height={300} width={600} options={options1} />
                </div>
                <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]'), i18n.t('Precipitation probability [%]')],
                            dates: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                            precipitation: this.state.week ? this.state.forecast.precipitation : this.state.forecast.precipitation.slice(0, 72),
                            precipitation_probability: this.state.week ? this.state.forecast.precipitation_probability : this.state.forecast.precipitation_probability.slice(0, 72)
                        }} />
                    <div style={{ width: '95%', marginTop: '10px',}}>

                        <Bar data={graph2} height={300} width={600} options={options2} />
                    </div>
                    <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                            precipitation: this.state.week ? this.state.forecast.precipitation : this.state.forecast.precipitation.slice(0, 72),
                            leaf_wetness: this.state.week ? this.state.forecast.leaf_wetness : this.state.forecast.leaf_wetness.slice(0, 72)
                        }} />
                        <div style={{ marginTop: '20px' }}></div>
                    </div>   
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastPrecipitation);