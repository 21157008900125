import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-table/react-table.css';
import {
    getStatisticReport
} from '../../actions/reports/ReportsActions';
import { getSeasonDropDown, getStates, getLocalGov, getCropForPreceding, getGroup, getSubgroup, getWODropdown, getMaterialDropdown, getMaterialSubgroup, getMaterialGroup } from '../../actions/DropDownActions';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import i18n from '../../i18n/i18n';
import ReactSelect from '../../utils/ReactSelect';
import immutable from 'seamless-immutable';
import { Button, IconButton } from '@material-ui/core';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import NumberRangeForFilter from '../../utils/NumberRangeForFilter';
import { exportDocument } from '../../actions/ExportActions';
import { Export } from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ForBooleanFilter from '../../utils/ForBooleanFilter';
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
export class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        localStorage.removeItem('row');

        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.fieldDropDown.length == 0) {
            this.props.getFieldDropDown();
        }
        if (this.props.cropsDropdown.length === 0) {
            this.props.getCropForPreceding();
        }
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
        if (this.props.woDropdown.length === 0) {
            this.props.getWODropdown();
        }
        if (this.props.materialDropdown.length === 0) {
            this.props.getMaterialDropdown();
        }
        if (this.props.materialGroupDropdown.length === 0) {
            this.props.getMaterialGroup();
        }
        if (this.props.materialSubgroupDropdown.length === 0) {
            this.props.getMaterialSubgroup();
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            fieldDropDown: immutable.asMutable(nextProps.fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialGroupDropdown: immutable.asMutable(nextProps.materialGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialSubgroupDropdown: immutable.asMutable(nextProps.materialSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropsDropdown: immutable.asMutable(nextProps.cropsDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            woDropdown: immutable.asMutable(nextProps.woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialDropdown: immutable.asMutable(nextProps.materialDropdown.map((item) => { return { label: item.label, value: item.value } }), { deep: true }),
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.reactTable;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/statistic');
        this.setState({ export: !this.state.export });
    }

    onPieClick = () => { this.props.history.push('/statistics_dashboard'); };
    onTableClick = () => { this.props.history.push('/statistics'); };

    render() {
        const { theme, clientProfile } = this.props;
        const columns = [
            {
                Header: i18n.t('ID'),
                accessor: 'id_radni_nalog'
            },
            {
                Header: i18n.t('Season'),
                accessor: 'sezona',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                            if (clientProfile) {
                                return element.value === clientProfile.id_default_sezona;
                            }
                        })}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg'
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'kat_opstina',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'datum_pocetka',
                Header: i18n.t('Start date'),
                accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                id: 'datum_zavrsetka',
                Header: i18n.t('End date'),
                accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                Header: i18n.t('Working operation'),
                accessor: 'radna_operacija_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.woDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Crop group'),
                accessor: 'kultura_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Crop subgroup'),
                accessor: 'kultura_podgrupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Crop'),
                accessor: 'kultura_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropsDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Field'),
                accessor: 'tabla',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.fieldDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'deo_table',
                Header: i18n.t('Part of the field'),
                accessor: d => d.deo_table,// Custom value accessors!  
                Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />
            },
            {
                Header: i18n.t('Main field'),
                accessor: 'glavna_tabla_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.fieldDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Field status'),
                accessor: 'naziv_proizvodnje'
            },
            {
                Header: i18n.t('Cultivated area'),
                accessor: 'povrsina',
                Filter: ({ filter, onChange }) =>
                    <NumberRangeForFilter
                        onChange={(s) => {
                            onChange(s);
                        }}

                    />
            },
            {
                Header: i18n.t('Material group'),
                accessor: 'materijal_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Material subgroup'),
                accessor: 'materijal_podgrupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Material'),
                accessor: 'materijal_naziv',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },

            // {
            //     Header: i18n.t('Value per unit'),
            //     accessor: 'cena_po_jm',
            //     Filter: ({ filter, onChange }) =>
            //         <NumberRangeForFilter
            //             onChange={(s) => {
            //                 onChange(s);
            //             }}

            //         />
            // },
            // {
            //     Header: i18n.t('Consumption'),
            //     accessor: 'utroseno',
            //     Filter: ({ filter, onChange }) =>
            //         <NumberRangeForFilter
            //             onChange={(s) => {
            //                 onChange(s);
            //             }}

            //         />
            // },
            // {
            //     Header: i18n.t('Total cost'),
            //     accessor: 'cena',
            //     Filter: ({ filter, onChange }) =>
            //         <NumberRangeForFilter
            //             onChange={(s) => {
            //                 onChange(s);
            //             }}

            //         />
            // },
            // {
            //     Header: i18n.t('Cost per ha'),
            //     accessor: 'cena_po_ha',
            //     Filter: ({ filter, onChange }) =>
            //         <NumberRangeForFilter
            //             onChange={(s) => {
            //                 onChange(s);
            //             }}

            //         />
            // },
            {
                Header: i18n.t('Comment'),
                accessor: 'komentar'
            }

        ];
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'sezona',
                        value: clientProfile && this.state.seasonDropDown ? [this.state.seasonDropDown.find(function (element) {
                            return element.value === clientProfile.id_default_sezona;
                        })] : []
                    }
                ],
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: '#3E9571'
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };

        return (
            <div className="page">
                {this.props.isStatisticShown ?
                    <div>
                        <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Statistics report')}
                            <IconButton onClick={this.props.handleClose}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header">
                    {!this.props.kontakt_osoba ?
                        <Button
                            onClick={() => this.onSearchClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Button>
                        : null}
                    <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>

                <ReactTable
                    data={this.props.statisticReport}
                    ref={r => (this.reactTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.statisticReportPages}
                    loading={this.props.statisticReportFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getStatisticReport(state, instance)}
                    className={"-striped -highlight"}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} /> */}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        statisticReport: state.reportsReducer.statisticReport,
        statisticReportFetching: state.reportsReducer.statisticReportFetching,
        statisticReportPages: state.reportsReducer.statisticReportPages,
        states: state.appReducer.states,
        statesFetching: state.appReducer.statesFetching,
        statesFetchingFailed: state.appReducer.statesFetchingFailed,
        localGoverments: state.appReducer.localGoverments,
        localGovermentsFetching: state.appReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.appReducer.localGovermentsFetchingFailed,
        seasonDropDown: state.appReducer.seasonDropDown,
        seasonDropDownFetching: state.appReducer.seasonDropDownFetching,
        seasonDropDownFetchingFailed: state.appReducer.seasonDropDownFetchingFailed,
        materialGroupDropdown: state.appReducer.materialGroupDropdown,
        materialSubgroupDropdown: state.appReducer.materialSubgroupDropdown,
        materialDropdown: state.appReducer.materialDropdown,
        woDropdown: state.appReducer.woDropdown,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        cropsDropdown: state.appReducer.cropsDropdown,
        cropGroupDropdown: state.appReducer.cropGroupDropdown,
        cropSubgroupDropdown: state.appReducer.cropSubgroupDropdown,
        clientProfile: state.appReducer.clientProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStatisticReport: (state, instance) => dispatch(getStatisticReport(state, instance)),
        getLocalGov: () => dispatch(getLocalGov()),
        getStates: () => dispatch(getStates()),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getCropForPreceding: () => dispatch(getCropForPreceding()),
        getGroup: () => dispatch(getGroup()),
        getSubgroup: () => dispatch(getSubgroup()),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        getWODropdown: () => dispatch(getWODropdown()),
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getMaterialGroup: () => dispatch(getMaterialGroup()),
        getMaterialSubgroup: () => dispatch(getMaterialSubgroup()),
        exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Statistics));