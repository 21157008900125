import axios from '../../utils/AxiosWrapper';

export const Type = {
    //REPORTS
    GET_CROP_ROTATION_REPORT_CALL: 'GET_CROP_ROTATION_REPORT_CALL',
    GET_CROP_ROTATION_REPORT_SUCCESS: 'GET_CROP_ROTATION_REPORT_SUCCESS',
    GET_CROP_ROTATION_REPORT_FAILED: 'GET_CROP_ROTATION_REPORT_FAILED',

    GET_SEEDING_REPORT_CALL: 'GET_SEEDING_REPORT_CALL',
    GET_SEEDING_REPORT_SUCCESS: 'GET_SEEDING_REPORT_SUCCESS',
    GET_SEEDING_REPORT_FAILED: 'GET_SEEDING_REPORT_FAILED',

    GET_STATISTIC_REPORT_CALL: 'GET_STATISTIC_REPORT_CALL',
    GET_STATISTIC_REPORT_SUCCESS: 'GET_STATISTIC_REPORT_SUCCESS',
    GET_STATISTIC_REPORT_FAILED: 'GET_STATISTIC_REPORT_FAILED',


    GET_NOTE_REPORT_CALL: 'GET_NOTE_REPORT_CALL',
    GET_NOTE_REPORT_SUCCESS: 'GET_NOTE_REPORT_SUCCESS',
    GET_NOTE_REPORT_FAILED: 'GET_NOTE_REPORT_FAILED',

    GET_AGRO_PRODUCTION_CALL: 'GET_AGRO_PRODUCTION_CALL',
    GET_AGRO_PRODUCTION_SUCCESS: 'GET_AGRO_PRODUCTION_SUCCESS',
    GET_AGRO_PRODUCTION_FAILED: 'GET_AGRO_PRODUCTION_FAILED',

    //DASHBOARDS
    GET_AGRO_PRODUCTION_DASHBOARD_CALL: 'GET_AGRO_PRODUCTION_DASHBOARD_CALL',
    GET_AGRO_PRODUCTION_DASHBOARD_SUCCESS: 'GET_AGRO_PRODUCTION_DASHBOARD_SUCCESS',
    GET_AGRO_PRODUCTION_DASHBOARD_FAILED: 'GET_AGRO_PRODUCTION_DASHBOARD_FAILED',

    GET_STATISTICS_DASHBOARD_CALL: 'GET_STATISTICS_DASHBOARD_CALL',
    GET_STATISTICS_DASHBOARD_SUCCESS: 'GET_STATISTICS_DASHBOARD_SUCCESS',
    GET_STATISTICS_DASHBOARD_FAILED: 'GET_STATISTICS_DASHBOARD_FAILED',


    GET_SEEDING_DASHBOARD_CALL: 'GET_SEEDING_DASHBOARD_CALL',
    GET_SEEDING_DASHBOARD_SUCCESS: 'GET_SEEDING_DASHBOARD_SUCCESS',
    GET_SEEDING_DASHBOARD_FAILED: 'GET_SEEDING_DASHBOARD_FAILED',

    GET_CROPROTATION_DASHBOARD_CALL: 'GET_CROPROTATION_DASHBOARD_CALL',
    GET_CROPROTATION_DASHBOARD_SUCCESS: 'GET_CROPROTATION_DASHBOARD_SUCCESS',
    GET_CROPROTATION_DASHBOARD_FAILED: 'GET_CROPROTATION_DASHBOARD_FAILED',

    //TEMPLATE
    GET_ACTIVITY_TEMPLATE_CALL: 'GET_ACTIVITY_TEMPLATE_CALL',
    GET_ACTIVITY_TEMPLATE_SUCCESS: 'GET_ACTIVITY_TEMPLATE_SUCCESS',
    GET_ACTIVITY_TEMPLATE_FAILED: 'GET_ACTIVITY_TEMPLATE_FAILED',

    SIGN_ACTIVITY_CALL: 'SIGN_ACTIVITY_CALL',
    SIGN_ACTIVITY_SUCCESS: 'SIGN_ACTIVITY_SUCCESS',
    SIGN_ACTIVITY_FAILED: 'SIGN_ACTIVITY_FAILED',

    DENIE_ACTIVITY_CALL: 'DENIE_ACTIVITY_CALL',
    DENIE_ACTIVITY_SUCCESS: 'DENIE_ACTIVITY_SUCCESS',
    DENIE_ACTIVITY_FAILED: 'DENIE_ACTIVITY_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',


};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function signActivity(potpisan, odbijen, id) {
    return (dispatch) => {

        dispatch({
            type: Type.SIGN_ACTIVITY_CALL
        });

        axios.post('api/agronom_rn/potpisi', {
            id: id,
            potpisan: potpisan,
            odbijen: odbijen
        })
            .then(function (response) {
                console.log('JSON.stringify', JSON.stringify(response.data));
                dispatch({
                    type: Type.SIGN_ACTIVITY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.SIGN_ACTIVITY_FAILED
                });
            });
    }
}

export function declineActivity(potpisan, odbijen, komentar, id) {
    return (dispatch) => {

        dispatch({
            type: Type.DENIE_ACTIVITY_CALL
        });

        axios.post('api/agronom_rn/odbij', {
            id: id,
            potpisan: potpisan,
            odbijen: odbijen,
            komentar: komentar
        })
            .then(function (response) {
                dispatch({
                    type: Type.DENIE_ACTIVITY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DENIE_ACTIVITY_FAILED
                });
            });
    }
}

export function getCropRotationReport(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_ROTATION_REPORT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/report/crop_rotation', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_ROTATION_REPORT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_ROTATION_REPORT_FAILED
                });
            });
    }
}

export function getStatisticReport(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STATISTIC_REPORT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/report/statistic', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATISTIC_REPORT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATISTIC_REPORT_FAILED
                });
            });
    }
}


export function getAgroProduction(state, filters) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_AGRO_PRODUCTION_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                filters: filters
            }
        };
        axios.get('api/agronom_rn/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_AGRO_PRODUCTION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_AGRO_PRODUCTION_FAILED
                });
            });
    }
}

export function getAgroProductionDashboard() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_AGRO_PRODUCTION_DASHBOARD_CALL
        });

        return axios.get('api/agronom_rn/dashboard')
            .then(function (response) {
                dispatch({
                    type: Type.GET_AGRO_PRODUCTION_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_AGRO_PRODUCTION_DASHBOARD_FAILED
                });
            });
    }
}

export function getSeedingReport(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SEEDING_REPORT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/report/seeding_statistic', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_SEEDING_REPORT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SEEDING_REPORT_FAILED
                });
            });
    }
}

export function getNoteReport(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_NOTE_REPORT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/report/field_note', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_NOTE_REPORT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_NOTE_REPORT_FAILED
                });
            });
    }
}

export function getStatisticsDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_STATISTICS_DASHBOARD_CALL
        });

        return axios.get('api/report/dashboard_statistic', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATISTICS_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATISTICS_DASHBOARD_FAILED
                });
            });
    }
}

export function getSeedingDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_SEEDING_DASHBOARD_CALL
        });

        return axios.get('api/report/dashboard_seeding_statistics', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_SEEDING_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SEEDING_DASHBOARD_FAILED
                });
            });
    }
}


export function getCropRotationDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_CROPROTATION_DASHBOARD_CALL
        });

        return axios.get('api/report/dashboard_crop_rotation', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROPROTATION_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROPROTATION_DASHBOARD_FAILED
                });
            });
    }
}

export function getTemplate(id) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_ACTIVITY_TEMPLATE_CALL
        })

        return axios.get('api/agronom_rn/template?id= ' + id)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ACTIVITY_TEMPLATE_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ACTIVITY_TEMPLATE_FAILED
                });
            });
    }
}