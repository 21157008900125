import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_VIEW_PARCELS_CALL: 'GET_VIEW_PARCELS_CALL',
    GET_VIEW_PARCELS_SUCCESS: 'GET_VIEW_PARCELS_SUCCESS',
    GET_VIEW_PARCELS_FAILED: 'GET_VIEW_PARCELS_FAILED',
    //ADD
    ADD_PARCELS_CALL: 'ADD_PARCELS_CALL',
    ADD_PARCELS_SUCCESS: 'ADD_PARCELS_SUCCESS',
    ADD_PARCELS_FAILED: 'ADD_PARCELS_FAILED',
    //DELETE
    DELETE_PARCEL_CALL: 'DELETE_PARCEL_CALL',
    DELETE_PARCEL_SUCCESS: 'DELETE_PARCEL_SUCCESS',
    DELETE_PARCEL_FAILED: 'DELETE_PARCEL_FAILED',
    //DROPDOWN
    GET_OWNERSHIP_CALL: 'GET_OWNERSHIP_CALL',
    GET_OWNERSHIP_SUCCESS: 'GET_OWNERSHIP_SUCCESS',
    GET_OWNERSHIP_FAILED: 'GET_OWNERSHIP_FAILED',

    //DASHBOARD
    GET_PARCEL_DASHBOARD_CALL: 'GET_PARCEL_DASHBOARD_CALL',
    GET_PARCEL_DASHBOARD_SUCCESS: 'GET_PARCEL_DASHBOARD_SUCCESS',
    GET_PARCEL_DASHBOARD_FAILED: 'GET_PARCEL_DASHBOARD_FAILED',

    SET_PARCEL_SUCCESS_TO_FALSE: 'SET_PARCEL_SUCCESS_TO_FALSE',
    RESET_DELETED_TO_FALSE: 'RESET_DELETED_TO_FALSE',
    RESET_PARCEL_DELETE_FAILED_TO_FALSE: 'RESET_PARCEL_DELETE_FAILED_TO_FALSE',
    SET_PARCEL_POSTING_FAILED_TO_FALSE: 'SET_PARCEL_POSTING_FAILED_TO_FALSE',

    SET_INITIAL_STATE:'SET_INITIAL_STATE',
    SET_PARCEL_ROW_IN_REDUCER:'SET_PARCEL_ROW_IN_REDUCER',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_PARCEL_ROW_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function addParcel(parcelObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_PARCELS_CALL
        });

        axios.post('api/parcela/create', parcelObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_PARCELS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_PARCELS_FAILED
                });
            });
    }
}

export function getParcel(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_PARCELS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/parcela/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_PARCELS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_PARCELS_FAILED
                });
            });
    }
}

export function deleteParcel(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_PARCEL_CALL,
        });

        axios.post(`api/parcela/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_PARCEL_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_PARCEL_FAILED
                });
            });
    }
}

export function getOwnershipDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_OWNERSHIP_CALL
        });

        axios.get('api/vlasnistvo/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_OWNERSHIP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_OWNERSHIP_FAILED
                });
            });
    }
}

export function getParcelDashboards(filters){
    return (dispatch) => {
        dispatch({
            type: Type.GET_PARCEL_DASHBOARD_CALL,
        });

        return axios.get('api/parcela/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_PARCEL_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PARCEL_DASHBOARD_FAILED
                });
            });
    }
}


