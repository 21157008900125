import React from 'react';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { toggleDrawer, toggleMenu, logout, getUserProfile, getClientProfile } from '../actions/LoginActions';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    Menu, MenuItem, ListItemText, Avatar, ExpansionPanel, IconButton,
    ExpansionPanelSummary, ExpansionPanelDetails, Typography, Fab
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { withRouter } from 'react-router-dom'
import i18n from '../i18n/i18n';
import Profile from './Profile';
import Settings from './Settings';
import Support from './Support';
import classnames from 'classnames';

const Paper = styled('aside')`
  position: fixed;
  z-index: ${p => p.theme.zIndex.drawer};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  background: ${p => p.theme.palette.secondary.main};
  transition: width 0.2s ease-in-out;
  box-shadow:  1px 0 2px rgba(0,0,0,0.07), 
                 2px 0 4px rgba(0,0,0,0.11), 
                 4px 0 8px rgba(0,0,0,0.14), 
                 8px 0 16px rgba(0,0,0,0.14);
  border-radius: 0px;
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
  height: ${p => p.theme.size(4)};
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  transition: width 0.2s ease-in-out;
  background: ${p => (p.isDrawerOpen || p.isMenuOpen ? p.theme.palette.secondary.dark : p.theme.palette.secondary.dark)};
`;

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    secondaryMain: {
        backgroundColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
    },
    secondaryDark: {
        backgroundColor: theme.palette.secondary.dark,
        background: theme.palette.secondary.dark,
    },
    secondaryLight: {
        backgroundColor: theme.palette.secondary.light,
        background: theme.palette.secondary.light,
    },
    secondarySelected: {
        background: theme.palette.secondary.light,
        borderColor: theme.palette.primary.main,
        borderLeft: 10,
        borderLeftStyle: 'solid'
    },
});

export class MenuDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: this.props.isDrawerOpen,
            isMenuOpen: this.props.isMenuOpen,
            anchorEl: null,
            profile: false,
            settingsOpen: false,
            support: false
        }
    }
    componentDidMount() {
        this.props.getUserProfile();
        this.props.getClientProfile(this.getUser().id_klijent);
        let user = this.getUser();
        let meteo_users = [30446, 30448, 30449, 30450, 30451, 29471, 30612, 32047, 32048, 32049, 32050, 32051];
        
        let meteo_check = meteo_users.includes(user.id_klijent);
        if (meteo_check === true) { this.goTo('./sensors') }

        let palic_user = [32052];
        let palic_check = palic_user.includes(user.id_klijent);
        if (palic_check === true) { this.goTo('./maps') }
    }

    componentDidUpdate(prevProps) {
        const { isDrawerOpen, isMenuOpen } = this.props;
        if (prevProps.isDrawerOpen !== isDrawerOpen) {
            this.setState({
                isDrawerOpen: isDrawerOpen
            })
        }
        if (prevProps.isMenuOpen !== isMenuOpen) {
            this.setState({
                isMenuOpen: isMenuOpen
            })
        }
    }

    toggleDrawerOver() {
        this.props.toggleMenu(true);
    }
    toggleDrawerOut() {
        this.props.toggleMenu(false);
    }

    tokenCheck() {
        let token = localStorage.getItem('token');
        return token;
    }

    onLogout() {
        this.props.logout();
        this.props.history.replace('/');
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    goTo(path) {
        this.props.history.push(path);
    }

    goToManuals() {
        if(this.getUser().id_region !== 29) {
            window.open("https://manuals.agrolife.world/farmer/", "_blank");
        } else {
            window.open("/AgroLIFE_Farmer_uputstva.pdf");
        }
    }

    handleMoreClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMoreClose = () => {
        this.setState({ anchorEl: null });
    };

    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
        this.handleMoreClose();
    }
    onSettingsClick = () => {
        this.setState({ settingsOpen: !this.state.settingsOpen });
        this.handleMoreClose();
    }

    onSupportClick = () => {
        this.setState({ support: !this.state.support });
    }

    render() {
        let user = this.getUser();
        var avatarSrc = null;
        if (this.props.userProfile && this.props.userProfile.slika) avatarSrc = this.props.userProfile.signedURL;
        const { anchorEl } = this.state;
        const { classes } = this.props;
        let meteo_users = [30446, 30448, 30449, 30450, 30451, 29471, 30612, 32047, 32048, 32049, 32050, 32051];
        let meteo_check = meteo_users.includes(user.id_klijent);

        let lite_users = [32139, 32379];
        let lite_check = lite_users.includes(user.id_klijent);

        let palic_user = [32052];
        let palic_check = palic_user.includes(user.id_klijent);
        
        return (
            <Paper isDrawerOpen={this.state.isDrawerOpen}
                isMenuOpen={this.state.isMenuOpen}
            // onMouseOver={() => this.toggleDrawerOver()}
            // onMouseOut={() => this.toggleDrawerOut()}
            >
                <Header isDrawerOpen={this.state.isDrawerOpen}
                    isMenuOpen={this.state.isMenuOpen} />
                {meteo_check === false && palic_check === false &&
                    // 30446 30448 30449 30450 30451
                    <div className="listInsideMenu" >
                        <Divider style={{ width: '350px' }} />
                        {/* <div>
                        <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")}>
                            <ExpansionPanelSummary onClick={() => this.goTo('/dashboard')} className={location.pathname.startsWith('/dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryDark, 'expansion-panel-summary')}>
                                <span className="icon-pie-chart menuIcon" />
                                <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Dashboard')}</Typography>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    </div> */}
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/activities')} className={window.location.pathname.startsWith('/activities') || window.location.pathname.startsWith('/new_activity') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-NoviRadniNalog menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Activities')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>

                        {user.moduli.length === 0 || user.moduli.some((item) => {
                            return item.naziv === 'maps';
                        }) ? <div>
                            <Divider style={{ width: '350px' }} />
                            <div>


                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/maps')} className={window.location.pathname.startsWith('/maps') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-Map menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('View map')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                {user.id_region === 29 &&
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/parcel')} className={window.location.pathname.startsWith('/parcel') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-TableLista menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Parcels')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                }

                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/field')} className={window.location.pathname.startsWith('/field') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-Fields menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Fields')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                {user.id_region === 29 &&
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/archivelist')} className={window.location.pathname.startsWith('/archivelist') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-TableLista menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Archived fields')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                }
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/notes')} className={window.location.pathname.startsWith('/notes') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-libreoffice menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Notes')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                {lite_check === false &&
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/sensors')} className={window.location.pathname.startsWith('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-feed menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Meteo stations')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                }
                                


                                {/* <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        <span className="icon-TableLista menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '18px' }}>{i18n.t('Map')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={window.location.pathname === '/maps' ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/maps')}>
                                            <span className="icon-Map menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('View map')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/field') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/field')}>
                                            <span className="icon-TableLista menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Fields')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/notes') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/notes')}>
                                            <span className="icon-libreoffice menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Notes')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                {/* {user.id_region === 2 &&
                                        <ExpansionPanelDetails className={window.location.pathname.includes('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/sensors')}>
                                                <span className="icon-feed menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Meteo stations')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    } */}
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/parcel') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/parcel')}>
                                            <span className="icon-Fields menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Parcels')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/layer') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/layer')}>
                                            <span className="icon-stack menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Layers')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/map_dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/map_dashboard')}>
                                            <span className="icon-pie-chart menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Map dashboard')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                {/* </ExpansionPanel> */}
                            </div>
                        </div> : null}

                        {/* {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'financial_services';
                    }) ? <div>
                            <Divider style={{ width: '350px' }} />
                            <div>
                                <ExpansionPanel className={classnames(classes.secondaryDark,"expansion-panel")}>
                                    <ExpansionPanelSummary className={ classnames(classes.secondaryMain,"expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        <span className="icon-credit-card menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '14px' }}>{i18n.t('Financial services')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={location.pathname.includes('/subsidies') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/subsidies')}>
                                            <span className="icon-libreoffice menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Subsidies')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={location.pathname.includes('/program') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/program')}>
                                            <span className=" icon-Beleske menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Programs')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> 
                                    <ExpansionPanelDetails className={location.pathname.includes('/incentive') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/incentive')}>
                                            <span className="icon-Common menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Incentives')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div> : null} */}
                        {user.moduli.length === 0 || user.moduli.some((item) => {
                            return item.naziv.includes('reports');
                        }) ? <div>
                            <Divider style={{ width: '350px' }} />
                            <div>
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/season_statistics')} className={window.location.pathname.startsWith('/season_statistics') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-pie-chart menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Reports')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                                {/* <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        <span className="icon-newspaper menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '18px' }}>{i18n.t('Reports')}</Typography>
                                    </ExpansionPanelSummary> */}
                                {/* {user.id === 30438 &&
                                        <div>
                                            <ExpansionPanelDetails className={window.location.pathname.includes('/consumption_dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                                <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/consumption_dashboard')}>
                                                    <span className="icon-pie-chart menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Consumption - Cost')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={window.location.pathname.includes('/revenue_dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                                <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/revenue_dashboard')}>
                                                    <span className="icon-pie-chart menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Revenue')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={window.location.pathname.includes('/balance_dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                                <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/balance_dashboard')}>
                                                    <span className="icon-pie-chart menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Revenue/Cost')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                        </div>
                                    }
                                    {user.id !== 30438 && */}
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/statistics') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/statistics')}>
                                                <span className="icon-pie-chart menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Statistics')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}
                                {/* } */}

                                {/* <ExpansionPanelDetails className={location.pathname.includes('/seeding_statistics') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/seeding_statistics')}>
                                            <span className="icon-Beleske menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Seeding statistics')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={location.pathname.includes('/note_statistics') ? classnames(classes.secondarSelectedt,'expansion-panel-detail-selected') : classnames(classes.secondaryMain,'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/note_statistics')}>
                                            <span className="icon-libreoffice menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px' }}>{i18n.t('Note statistics')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                {/* </ExpansionPanel> */}
                            </div>
                        </div> : null}

                        <Divider style={{ width: '350px' }} />

                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goToManuals()} className={classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-info menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Manuals')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.onSupportClick()} className={classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-question menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Support')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>
                        <Divider style={{ width: '350px' }} />
                    </div >
                }
                {
                    meteo_check === true &&
                    <div className="listInsideMenu" >
                        <Divider style={{ width: '350px' }} />
                        {user.moduli.length === 0 || user.moduli.some((item) => {
                            return item.naziv === 'maps';
                        }) ? <div>
                            <Divider style={{ width: '350px' }} />
                            <div>
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        <span className="icon-feed menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '18px' }}>{i18n.t('Meteo')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem style={{ width: '100%' }} onClick={() => this.goTo('/sensors')}>
                                            <span className="icon-feed menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '18px' }}>{i18n.t('Meteo stations')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div> : null}
                        <Divider style={{ width: '350px' }} />
                    </div >
                }

{
                    palic_check === true &&
                    <div className="listInsideMenu" >
                        <Divider style={{ width: '350px' }} />
                        {user.moduli.length === 0 || user.moduli.some((item) => {
                            return item.naziv === 'maps';
                        }) ? <div>
                            <Divider style={{ width: '350px' }} />
                            <div>
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} >
                                        <span className="icon-feed menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '18px' }}>{i18n.t('Map')}</Typography>
                                    </ExpansionPanelSummary>
                                    
                                </ExpansionPanel>
                            </div>
                        </div> : null}
                        <Divider style={{ width: '350px' }} />
                    </div >
                }
                <div style={{ display: 'flex', backgroundColor: '#126038', height: '7em', boxShadow: '0px 2px 5px 4px rgba(18, 96, 56, 0.5), 0px 4px 10px 8px rgba(18, 96, 56, 0.4), 0px 8px 20px 16px rgba(18, 96, 56, 0.3)' }}>
                    {avatarSrc ? <Avatar
                        src={avatarSrc}
                        className="profile-avatar"
                        style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '12px' }}
                    /> :
                        <Avatar
                            className="profile-avatar"
                            style={{ alignSelf: 'center', opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '12px', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)' }}
                        >
                            <Typography color='primary' variant='h4'> {user.ime_prezime.replace('null', '').replace(/(\S)\S*\s*/ig, "$1").toUpperCase()}</Typography>
                        </Avatar>
                    }

                    <div style={{ alignSelf: 'center' }}>
                        <table>
                            <tr>
                                <td>
                                    <Typography className="profile-typography" style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '6px' }}>
                                        <div>{user.ime_prezime !== ' ' ? user.ime_prezime.replace('null', '') : user.klijent_naziv}</div>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography className="profile-typography2" style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '6px' }}>
                                        <div>{user.id_region === 13 || user.id_region === 26 || user.id_region === 25 ? user.klijent_grupa_naziv_en : user.klijent_grupa_naziv}</div>
                                    </Typography>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <IconButton
                        variant="fab" aria-label="more"
                        className="menuMoreButton"
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup="true"
                        style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', width: '45px', marginLeft: '10px' }}
                        onClick={this.handleMoreClick}>
                        <Icons.MoreVert className="moreIcon" />
                    </IconButton>

                    {/* <Fab
                        color="primary"
                        aria-label="add"
                        className="action-button"
                        style={{ marginTop: '30px', height: '40px !important', width: '40px !important' }}
                        size="large"
                        onClick={this.handleMoreClick}>
                        <span className="icon-Settings" style={{ fontSize: '20px' }} />
                    </Fab> */}

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMoreClose}
                    >
                        {meteo_check === false &&
                            <MenuItem onClick={() => this.onProfileClick()} >
                                <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>{i18n.t('Profile')}</Typography>} />
                                <span className="icon-user logOutIcon" />
                            </MenuItem>
                        }
                        {meteo_check === false &&
                            <MenuItem onClick={() => this.onSettingsClick()} >
                                <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>{i18n.t('Settings')}</Typography>} />
                                <span className="icon-cog logOutIcon" />
                            </MenuItem>
                        }
                        <MenuItem onClick={() => this.onLogout()} >
                            <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>Logout</Typography>} />
                            <span className="icon-LogOut logOutIcon" />
                        </MenuItem>
                    </Menu>

                    {this.state.profile && <Profile open={this.state.profile}
                        handleClose={this.onProfileClick}
                        userProfile={this.props.userProfile} />}
                    {this.state.settingsOpen && <Settings open={this.state.settingsOpen}
                        handleClose={this.onSettingsClick}
                        settings={this.props.settings} />}

                    {this.state.support && <Support open={this.state.support}
                        handleClose={this.onSupportClick} />}
                </div>

            </Paper >
        );
    }
}

function mapStateToProps(state) {
    return {
        isDrawerOpen: state.appReducer.isDrawerOpen,
        isMenuOpen: state.appReducer.isMenuOpen,
        user: state.appReducer.user,
        userProfile: state.appReducer.userProfile
    };
}
function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        toggleMenu: open => dispatch(toggleMenu(open)),
        logout: open => dispatch(logout(open)),
        getUserProfile: () => dispatch(getUserProfile()),
        getClientProfile: (id) => dispatch(getClientProfile(id)),
    };
}
export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuDrawer)));