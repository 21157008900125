/**
 * Created by pajicv on 5/31/18.
 */

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Map, LayersControl, Popup } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet.pm/dist/leaflet.pm.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.pm';
import 'proj4leaflet';
import 'leaflet-measure';
import './leaflet-measure.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { calculateArea } from '../../utils/MapUtils';

import * as LayerActions from "../../actions/map/LayerActions";
import * as MapActions from "../../actions/map/MapActions";
import * as LayerSelectors from "../../selectors/LayerSelectors";
import { addParcel } from '../../actions/catastral_data/ParcelActions';
import { addField as addFieldAction, setInitialState } from '../../actions/catastral_data/FieldActions';
import AddField from '../catastral_data/field/AddField';
import AddParcel from '../catastral_data/parcel/AddParcel';
import BaseLayers from '../../layers/BaseLayers';
import SatelliteImageryLayers from '../../layers/SatelliteImageryLayers';
import ClientLayers from '../../layers/ClientLayers';
import FieldLayer from '../../layers/FieldLayer';
import ParcelLayer from '../../layers/ParcelLayer';
import NoteLayer from '../../layers/NoteLayer';
import InstitutionalLayers from '../../layers/InstitutionalLayers';
import Forecast10Days from '../../layers/Forecast10Days';

//import LayerTree from "./LayerTree/LayerTree_v2";
import LayerTree from "./LayerTree/Drawer/TreePanel";
import ParcelAttributes from "./ParcelAttributes";
import ZoomToMenu from "./Find/ZoomToMenu";
import FindMenu from "./ForecastMenu";
import TrackingButton from './Tracking/TrackingButton';

import './MapContainer.css';

import axios from '../../utils/AxiosWrapper';
import { getSeasonDropDown } from "../../actions/DropDownActions";

class MapPanel extends Component {

    constructor(props) {
        super(props);

        this.EMPTY_FEATURE = {
            type: null,
            isDrawn: false,
            isCompleted: false,
            geometry: null,
            attributes: null
        };

        this.state = {
            mapCenter: {
                lat: 0,
                lng: 0
            },
            mapBounds: null,
            mapZoom: 15,
            newFeature: { ...this.EMPTY_FEATURE },
            zoomToMenuOpen: false,
            selectedSeasons: [props.clientProfile.id_default_sezona],
            isDeleting: false
        };

        this.saveParcel = this.saveParcel.bind(this);
        this.clearParcel = this.clearParcel.bind(this);
    }

    updateDimensions() {
        //za sada je hardkodirano, srediti da se izracunava
        const headerHeight = 0;
        const height = window.innerWidth >= 992 ? (window.innerHeight - headerHeight) : 400;
        this.setState({
            height: height
        });
    }

    componentWillMount() {

        this.updateDimensions();

        /*const user = this.getUser();

        const {lat, lng, zoom} = user;

        const latLng =  L.Projection.SphericalMercator.unproject({x: Number(lat), y: Number(lng)});

        console.log(latLng);

        this.setState({mapCenter: { ...latLng }, mapZoom: 6});*/

    }

    /*getUser = () => {

        let user = localStorage.getItem('user');

        let userObject = null;

        try {
            userObject = JSON.parse(user);
        } catch(err) {
            console.log(err);
        }

        return userObject;

    };*/

    loadParcels() {

        const { selectedSeasons } = this.state;

        if (!this.refs.map) return;

        const map = this.refs.map.leafletElement;

        //odredjujemo granice mape
        const bounds = map.getBounds();

        // Bounding box je u formi [min_longitude, min_latitude, max_longitude, max_latitude]
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const bbox = mapBounds.join(',');

        //CRS je po defaultu EPSG:4326

        if (this.props.parcelLayer.visible) {
            this.props.getParcels(bbox/*, crs*/);
        }

        if (this.props.fieldLayer.visible) {
            this.props.getFields(bbox, selectedSeasons/*, crs*/);
        }

        if (this.props.noteLayer.visible) {
            this.props.fetchNotes(bbox/*, crs*/);
        }

    }

    loadMarkers() {

        if (!this.refs.map) return;

        this.props.getAgriResearchInstitutes();

        this.props.getRiverBasinAuth();

        this.props.getAgriUniversities();

    }

    zoomToBounds = (stateId, localGovId, fields) => {

        const self = this;

        axios.get(`api/map/zoomToBounds?stateId=${stateId}&localGovId=${localGovId}&fields=${fields.map(field => field.value).join(',')}`)
            .then(function (response) {

                const map = self.refs.map.leafletElement;

                const { latmin, lngmin, latmax, lngmax } = response.data.data;

                const bounds = [[latmin, lngmin], [latmax, lngmax]];

                map.fitBounds(bounds);
                //map.setMaxBounds(bounds);

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    zoomToCoordinates = (lat, lng) => {
        if (!this.refs.map) {
            return;
        }

        const map = this.refs.map.leafletElement;

        if (lat && lng) {
            map.flyTo(L.latLng(parseFloat(lat), parseFloat(lng)));
        }
    };

    handleSeasonSelect = (selectedSeasons) => this.setState({ selectedSeasons }, () => {
        if (this.props.fieldLayer.visible) {
            if (!this.refs.map) return;

            const map = this.refs.map.leafletElement;

            //odredjujemo granice mape
            const bounds = map.getBounds();

            // Bounding box je u formi [min_longitude, min_latitude, max_longitude, max_latitude]
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            const bbox = mapBounds.join(',');

            this.props.getFields(bbox, selectedSeasons/*, crs*/);
        }
    });

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions.bind(this));

        if (!this.refs.map) {
            return;
        }

        const map = this.refs.map.leafletElement;

        //postavljamo inicijalnu poziciju centra mape
        const latLng = map.getCenter();
        const mapCenter = { ...latLng };

        //postavljamo inicijalne granice mape
        const bounds = map.getBounds();
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const mapZoom = map.getZoom();

        this.setState({ mapCenter, mapBounds, mapZoom });

        this.loadParcels();
        this.loadMarkers();
        this.props.getSeasonDropDown();

        const measureControl = new L.Control.Measure({
            position: 'bottomleft',
            primaryLengthUnit: 'meters',
            secondaryLengthUnit: 'kilometers',
            primaryAreaUnit: 'hectares',
            secondaryAreaUnit: 'sqmeters'
        })
        measureControl.addTo(map)

        map.pm.addControls({
            position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
            // drawMarker: false, // does not add button to draw markers
            // drawPolyline: false, // does not add button to draw markers
            // drawRectangle: true, // does not add button to draw markers
            // drawPolygon: true, // adds button to draw a polygon
            // drawCircle: false, // adds button to draw a cricle
            // cutPolygon: false, // adds button to cut a hole in a polygon
            // editMode: true, // adds button to toggle edit mode for all layers
            // removalMode: false, // adds a button to remove layers
            drawMarker: false, // does not add button to draw markers
            drawCircleMarker: false, // does not add button to draw markers
            drawPolyline: false, // does not add button to draw markers
            drawRectangle: true, // adds button to draw a polygon
            drawPolygon: true, // adds button to draw a cricle
            drawCircle: false, // adds button to toggle edit mode for all layers
            editMode: true,
            dragMode: false,
            cutPolygon: false,
            removalMode: false


        });

        const self = this;

        map.on('zoomend', e => {

            //odredjujemo nove granice mape
            const bounds = map.getBounds();
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            const mapZoom = map.getZoom();

            if (mapZoom >= 13) {

                map.getPane('overlayPane').hidden = false;

                self.loadParcels();

            } else {

                map.getPane('overlayPane').hidden = true;

            }

            self.setState({ mapBounds, mapZoom });

        });

        map.on('moveend', e => {

            //odredjujemo novi centar mape
            const latLng = map.getCenter();
            const mapCenter = { ...latLng };

            //odredjujemo nove granice mape
            const bounds = map.getBounds();
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            self.loadParcels();

            self.setState({ mapCenter, mapBounds });
        });

        map.on('pm:create', e => {

            self.drawnFeature = e.layer;

            let newFeature = { ...self.state.newFeature };
            newFeature.type = self.props.editedLayerId;
            newFeature.geometry = e.layer.getLatLngs();
            newFeature.isDrawn = true;
            newFeature.attributes = {
                povrsina: calculateArea(newFeature.geometry)
            };

            self.setState({ newFeature });
        });

    }

    componentDidUpdate(prevProps) {
        const { fieldPostingSuccess, setInitialState } = this.props;
        if (prevProps.fieldPostingSuccess !== fieldPostingSuccess && fieldPostingSuccess === true) {
            setInitialState('fieldPostingSuccess');
        }
    }


    saveParcel(attributes) {
        let { geometry } = this.state.newFeature;
        this.props.saveParcel({ geometry, attributes });
        this.clearParcel();
    }

    clearParcel() {
        const map = this.refs.map.leafletElement;
        map.removeLayer(this.drawnFeature);
        this.setState({ newFeature: { ...this.EMPTY_FEATURE } });
    }

    saveFeature = (attributes) => {
        const { addField, addParcel } = this.props;
        const { newFeature: { geometry } } = this.state;
        addField({ ...attributes, geometry, id_default_sezona: this.props.clientProfile.id_default_sezona });
        this.loadParcels();
        this.clearParcel();
    };

    toggleDeleting = () => this.setState({ isDeleting: !this.state.isDeleting })

    handleDelete = async (featureId) => {
        try {
            const result = await axios.post('api/field/delete', { selection: [featureId] })
            this.loadParcels()
        } catch (error) {
            console.log(error)
        }
    }

    handleClose = () => {
        this.clearParcel();
    }

    componentWillUnmount() {
        //iskljucivanje map eventova jer je pucalo na unmount
        if (this.refs.map) {
            this.refs.map.leafletElement.off();
        }
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleOpenZoomToMenu = () => this.setState({ zoomToMenuOpen: true });

    handleCloseZoomToMenu = () => this.setState({ zoomToMenuOpen: false });

    render() {

        const { mapCenter, seasons } = this.props;

        const { zoomToMenuOpen, selectedSeasons, isDeleting } = this.state;

        const center = L.latLng([mapCenter.lat, mapCenter.lng]);

        const maxBounds = L.latLngBounds(
            L.latLng(this.props.mapBounds[0][0], this.props.mapBounds[0][1]),
            L.latLng(this.props.mapBounds[1][0], this.props.mapBounds[1][1])
        );

        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flexGrow: 1, height: this.state.height }}>
                    <Map
                        center={center}
                        bounds={maxBounds}
                        maxBounds={maxBounds}
                        minZoom={6}
                        maxZoom={18}
                        maxBoundsViscosity={0.75}
                        ref="map"
                    >
                        <LayersControl position="topright">
                            <BaseLayers />
                            <SatelliteImageryLayers />
                            <ClientLayers />
                            <ParcelLayer />
                            <FieldLayer isDeleting={isDeleting} onDelete={this.handleDelete} />
                            <NoteLayer />
                            {/*<InstitutionalLayers/>*/}
                            <Forecast10Days mapBounds={this.state.mapBounds} />
                        </LayersControl>
                    </Map>
                    {zoomToMenuOpen && <ZoomToMenu
                        open={zoomToMenuOpen}
                        zoomToBounds={this.zoomToBounds}
                        zoomToCoordinates={this.zoomToCoordinates}
                        onZoomToMenuClose={this.handleCloseZoomToMenu}
                    />}
                    {/* {(this.state.newFeature.isDrawn && !this.state.newFeature.isCompleted) &&
                        <ParcelAttributes attributes={this.state.newFeature.attributes}
                            setParcelAttributes={this.saveParcel} cancelParcelCreate={this.clearParcel} />
                    } */}
                    {
                        (this.state.newFeature.isDrawn && !this.state.newFeature.isCompleted) && (
                            <AddField add={!this.state.newFeature.isCompleted} edit={false} handleClose={this.handleClose} povrsina={this.state.newFeature.attributes.povrsina} onSave={this.saveFeature} />
                        )
                    }
                </div>
                <div style={{
                    position: 'absolute',
                    top: 230,
                    left: 75,
                    width: 30,
                    height: 30,
                    backgroundColor: !isDeleting ? 'white' : '#ccc',
                    border: !isDeleting ? '1px solid #000' : '1px solid #333',
                    borderRadius: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1001
                }}
                    onClick={this.toggleDeleting}
                >
                    <img width={16} height={16} alt="del" src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ1dWlkOjY1RTYzOTA2ODZDRjExREJBNkUyRDg4N0NFQUNCNDA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjk5QzE2QjUyODUzMzExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjk5QzE2QjUxODUzMzExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE1IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTk5NzA1OGEtZDI3OC00NDZkLWE4ODgtNGM4MGQ4YWI1NzNmIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YzRkZmQxMGMtY2NlNS0xMTc4LWE5OGQtY2NkZmM5ODk5YWYwIi8+IDxkYzp0aXRsZT4gPHJkZjpBbHQ+IDxyZGY6bGkgeG1sOmxhbmc9IngtZGVmYXVsdCI+Z2x5cGhpY29uczwvcmRmOmxpPiA8L3JkZjpBbHQ+IDwvZGM6dGl0bGU+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+UGbNXAAAAI5JREFUeNpi+P//PwM2DAQNQPwfB27ApY8RqhkFMDIyCgCp9wx4AFAfIzZxFgYCAF0j0LL/+NQz4TDkAwOZAGQ7SDM/A3XARyYGKgOskUIRgBr4AU8SIRZ/gCcbQjFHguMYqR6G2AzMhmJyxLAm7McUiDHQxcujBo4aOOQNxJb1ZCkQG6LFF9kVFIgACDAAqr5rFp6MjMcAAAAASUVORK5CYII='} />
                </div>
                <LayerTree
                    onZoomToMenuOpen={this.handleOpenZoomToMenu}
                    seasons={seasons}
                    selectedSeasons={selectedSeasons}
                    onSeasonSelect={this.handleSeasonSelect}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        editedLayerId: LayerSelectors.getEditedLayerId(state),
        mapBounds: LayerSelectors.getMapBounds(state),
        parcelLayer: LayerSelectors.getParcelLayer(state),
        fieldLayer: LayerSelectors.getFieldLayer(state),
        noteLayer: LayerSelectors.getNoteLayer(state),
        mapInitFetching: state.layersReducer.mapInitFetching,
        mapInitFetchingFailed: state.layersReducer.mapInitFetchingFailed,
        mapCenter: state.layersReducer.mapCenter,
        mapBounds: state.layersReducer.mapBounds,
        clientProfile: state.appReducer.clientProfile,
        seasons: state.appReducer.seasonDropDown,
        fieldPostingSuccess: state.fieldReducer.fieldPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveParcel: payload => dispatch(LayerActions.saveParcel(payload)),
        getParcels: (bbox, crs) => dispatch(LayerActions.getParcels(bbox, crs)),
        saveField: payload => dispatch(LayerActions.saveField(payload)),
        getFields: (bbox, crs) => dispatch(LayerActions.getFields(bbox, crs)),
        getAgriResearchInstitutes: () => dispatch(LayerActions.getAgriResearchInstitutes()),
        getRiverBasinAuth: () => dispatch(LayerActions.getRiverBasinAuth()),
        getAgriUniversities: () => dispatch(LayerActions.getAgriUniversities()),
        fetchNotes: (bbox, crs) => dispatch(LayerActions.fetchNotes(bbox, crs)),
        fetchMapInit: () => dispatch(MapActions.fetchMapInit()),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        addField: fieldObj => dispatch(addFieldAction(fieldObj)),
        addParcel: (parcelObj) => dispatch(addParcel(parcelObj)),
        setInitialState: (name) => dispatch(setInitialState(name))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapPanel);