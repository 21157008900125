import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, IconButton, Link } from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.secondary.main,
    },
});

const initialState = {
    naziv: '', username: '',
    ime: '', prezime: '',
    password1: '', password2: '',
    region: '', regions: [],
    clientGroup: null, clientGroups: [],
    userGroup: null, userGroups: [],
    opstina: '', opstine: [], opstinaDisabled: true,
    katastarska: '', katastarske: [], katastarskaDisabled: true,
    language: '', languages: [],
    season: '', seasons: [], seasonDisabled: true,
    currency: '', currencies: [],
    workspace: null, workspaces: [],
    x: '', y: '', zoom: '',
    mesto: '', drzava: '', ulica_i_broj: '',
    mobilni: '', telefon: '', osoba: '', mejl: '',
    skype: '', pib: '', bpg: '',
};

export class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getByURL('region/filter_region', 'regions');
        this.getByURL('klijent_korisnik/language', 'languages');
        this.getByURL('klijent_korisnik/currency', 'currencies');
        this.getByURL('klijent_korisnik/workspace', 'workspaces');
    }
    getByURL(url, obj, value, name) {
        // url: api/'url' // obj: this.state.obj // value: params value // name: params value name
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => console.log('Something went wrong, please try again!'));
        }
    }
    addClients() {
        if (

            this.state.username &&
            this.state.ime &&
            this.state.prezime &&
            this.state.region &&
            this.state.opstina &&
            this.state.katastarska &&
            this.state.language &&
            this.state.currency &&
            this.state.season &&
            this.state.password1 === this.state.password2 &&
            validator.isEmail(this.state.username) === true &&
            validator.isLength(this.state.password1, { min: 6, max: 20 })

        ) {
            let account = {

                naziv: this.state.username,
                username: this.state.username,
                ime: this.state.ime,
                prezime: this.state.prezime,
                password: this.state.password1,
                region: Number(this.state.region),
                clientGroup: Number('3'),
                userGroup: Number('11'),
                opstina: Number(this.state.opstina),
                katastarska: Number(this.state.katastarska),
                language: Number(this.state.language),
                season: Number(this.state.season),
                currency: Number(this.state.currency),
                workspace: this.state.workspace,
                x: Number(this.state.x),
                y: Number(this.state.y),
                zoom: Number(this.state.zoom),
                mesto: this.state.mesto,
                drzava: this.state.drzava,
                ulica_i_broj: this.state.ulica_i_broj,
                mobilni: this.state.mobilni,
                telefon: this.state.telefon,
                osoba: this.state.osoba,
                mejl: this.state.mejl,
                skype: this.state.skype,
                pib: this.state.pib,
                bpg: this.state.bpg,
            }

            if (account.naziv === '') account.naziv = null;
            if (account.username === '') account.username = null;
            if (account.ime === '') account.ime = null;
            if (account.prezime === '') account.prezime = null;
            if (account.mesto === '') account.mesto = null;
            if (account.drzava === '') account.drzava = null;
            if (account.ulica_i_broj === '') account.ulica_i_broj = null;
            if (account.mobilni === '') account.mobilni = null;
            if (account.telefon === '') account.telefon = null;
            if (account.osoba === '') account.osoba = null;
            if (account.mejl === '') account.mejl = null;
            if (account.skype === '') account.skype = null;
            if (account.pib === '') account.pib = null;
            if (account.bpg === '') account.bpg = null;
            if (account.x === '') account.x = null;
            if (account.y === '') account.y = null;
            if (account.zoom === '') account.zoom = null;

            axios.post('klijent_korisnik/account', account)
                .then(res => {

                    toast.success(('Successfully created account!'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        draggablePercent: 80
                    });
                })
                .catch(err => {
                    console.log('Error: ', err.message);
                    if (err.message == 'Request failed with status code 400') {
                        toast.error(('User with that e-mail already exists!'), {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            draggablePercent: 80
                        });
                    } else {
                        toast.error(('Something went wrong, please try again!'), {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            draggablePercent: 80
                        });
                    }
                })
            this.resetClient();
            this.goBack();

        } else if (this.state.password1 !== this.state.password2) {
            toast.error(('You have entered different passwords!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        } else if (validator.isEmail(this.state.username) === false) {
            toast.error(('Email format is incorrect!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        } else if (!validator.isLength(this.state.password1, { min: 6, max: 20 })) {
            toast.error(('Password must be between 6 and 20 characters long!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        } else {
            toast.error(('Please enter all fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }

    }
    resetClient() { this.setState(initialState); }

    goBack = () => {
        this.props.history.push('/');
    };

    render() {
        const { classes } = this.props;
        return (
            <div>

                <div className="login2">
                    <Paper className="roundedBox2" >
                        <div className={classnames(classes.primaryMain, "loginText")}>
                            REGISTER
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <div className="logo">
                                <img src={require('../../assets/images/login_agrolife.svg')} width="300" height="70" />
                            </div>
                            <div style={{ flexDirection: 'row', paddingLeft: '40px' }}>

                                <TextField required
                                    label={('Username - must be an email')}
                                    type='email'
                                    helperText={('Login username - email')}
                                    value={this.state.username}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ username: e.target.value }); }} >
                                </TextField>
                                <TextField required
                                    label={('Name')}
                                    value={this.state.ime}
                                    helperText={('Name')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ ime: e.target.value }); }} >
                                </TextField>
                                <TextField required
                                    label={('Surname')}
                                    value={this.state.prezime}
                                    helperText={('Surname')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ prezime: e.target.value }); }} >
                                </TextField>
                                <TextField required
                                    label={('Region')}
                                    select
                                    helperText={('Region')}
                                    value={this.state.region}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => {

                                        this.setState({
                                            region: e.target.value,
                                            opstina: '',
                                            opstinaDisabled: false,
                                            katastarska: '',
                                            katastarskaDisabled: true,
                                            season: '',
                                            seasonDisabled: false,
                                        });
                                        this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                        this.getByURL('klijent_korisnik/season', 'seasons', e.target.value, 'id_region');
                                    }} >
                                    <option value='' />
                                    {this.state.regions.map((regions) =>  <option key={regions.id} value={regions.id}>{(regions.naziv === 'BiH' ? regions.naziv = 'Federacija BiH' : null)}{regions.naziv} </option>)}
                                </TextField>
                                <TextField required
                                    label={('State')}
                                    select
                                    helperText={('State')}
                                    disabled={this.state.opstinaDisabled}
                                    value={this.state.opstina}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => {
                                        this.setState({
                                            opstina: e.target.value,
                                            katastarska: '',
                                            katastarskaDisabled: false,
                                        });
                                        this.getByURL('field/filter_kat_opstina_uslov', 'katastarske', e.target.value, 'id_opstina');
                                    }} >
                                    <option value='' />
                                    {this.state.opstine.map((opstine) => <option key={opstine.id} value={opstine.id}>{opstine.naziv}</option>)}
                                </TextField>
                                <TextField required
                                    label={('Local government')}
                                    select
                                    helperText={('Local government')}
                                    disabled={this.state.katastarskaDisabled}
                                    value={this.state.katastarska}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ katastarska: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.katastarske.map((katastarske) => <option key={katastarske.id} value={katastarske.id}>{katastarske.naziv}</option>)}
                                </TextField>
                                <TextField required
                                    label={('Season')}
                                    select
                                    disabled={this.state.seasonDisabled}
                                    value={this.state.season}
                                    helperText={('Default season')}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ season: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.seasons.map((seasons) => <option key={seasons.id} value={seasons.id}>{seasons.naziv}</option>)}
                                </TextField>

                                <TextField required
                                    label={('Language')}
                                    select
                                    value={this.state.language}
                                    helperText={('Language')}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ language: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.languages.map((languages) => <option key={languages.id} value={languages.id}>{languages.naziv}</option>)}
                                </TextField>
                                <TextField required
                                    label={('Currency')}
                                    select
                                    value={this.state.currency}
                                    helperText={('Currency')}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ currency: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.currencies.map((currencies) => <option key={currencies.id} value={currencies.id}>{currencies.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={('Street and number')}
                                    value={this.state.ulica_i_broj}
                                    helperText={('Street and number')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ ulica_i_broj: e.target.value }); }} >
                                </TextField>
                                <TextField
                                    label={('Mobile phone number')}
                                    type="number"
                                    helperText={('Mobile phone number')}
                                    value={this.state.mobilni}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ mobilni: e.target.value }); }} >
                                </TextField>
                                <TextField
                                    label={('BPG')}
                                    type="number"
                                    value={this.state.bpg}
                                    helperText={('Unique farm number')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ bpg: e.target.value }); }} >
                                </TextField>
                                <TextField
                                    label={('Enter password')}
                                    required
                                    type="password"
                                    value={this.state.password1}
                                    helperText={('Enter password / 6-20 characters')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ password1: e.target.value }); }} >
                                </TextField>
                                <TextField
                                    label={('Retype password')}
                                    required
                                    type="password"
                                    value={this.state.password2}
                                    helperText={('Retype password')}
                                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                    onChange={(e) => { this.setState({ password2: e.target.value }); }} >
                                </TextField>

                                <br></br>
                                <Button style={{ width: '150px', alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                    onClick={() => {
                                        this.addClients();
                                    }} >
                                    {('Register')}
                                </Button>

                                <Button style={{ width: '150px', alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px', marginLeft: '30px' }}
                                    onClick={() => this.goBack()} >
                                    {('Back')}
                                </Button>
                            </div>
                        </div>
                    </Paper>
                    {/* <div style={style}>
                        <a href="https://manuals.agrolife.world/privacy_policy" target="blank">Privacy policy </a>
                        |
                        <a href="https://manuals.agrolife.world/tos" target="blank">Terms & Conditions</a>
                        |
                        <span className="icon-Facebook loginIcon"></span>
                        <span className="icon-Twiter loginIcon"></span>
                        <span className="icon-Instagram loginIcon"></span>
                        <span className="icon-linkedin loginIcon"></span>
                        <span className="icon-G+ loginIcon"></span>
                        |<a href="http://agrolife.world" target="blank">www.agrolife.world</a>
                    </div> */}
                </div>

            </div >
        );
    }
}


export default withStyles(styles)(RegisterPage);