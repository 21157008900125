import React from 'react';
import i18n from '../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';

export default class NewSeasonAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();
        let link = '';

        if (user.id_region === 2) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings_rs";
        }
        else if (user.id_region === 20) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings_rs";
        }
        else if (user.id_region === 21) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings_rs";
        }
        else if (user.id_region === 23) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings_rs";
        }

        else if (user.id_region === 13) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings";
        }
        else if (user.id_region === 25) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings";
        }
        else if (user.id_region === 26) {
            link = "https://manuals.agrolife.world/farmer/basic/settings/settings";
        }

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.alert}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('New season alert')}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div style={{ padding: '18px 0 0 0', fontFamily: 'Roboto', fontSize: '18px', lineHeight: '26px', textAlign: 'justify' }}>
                                {i18n.t('Dear farmers')},
                                <br />
                                <br />
                                {i18n.t(' Current season has changed (2021/2022)')}.
                                {i18n.t(` That's why you don't see data from the previous season, but that data is not lost`)}.
                                {i18n.t(' If you want to see and work with that data, you can easily change the season in settings, and that data will available again')}.
                                <br />
                                {i18n.t('Guide on how to change the season can be found here')}:

                                <br /><br />
                                <a href={link} target="_blank" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {i18n.t('Guide on how to change the season')}
                                </a>
                                <br /> <br />
                                {i18n.t('For additional questions, you can contact us by e-mail or phone')}: <br />
                                {i18n.t('E-mail adresa')}: agrolife.podrska@gmail.com<br />
                                {i18n.t('Phone')}: 021 / 271 - 4500 <br />
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button variant='contained' color='secondary' onClick={() => {
                                    axios.get('api/season/new_season_alert_update');
                                    this.props.handleClose();
                                }}>{i18n.t('OK')}</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};