import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addParcel, getOwnershipDropDown } from '../../../actions/catastral_data/ParcelActions';
import { getLocalGov, getKatKultura } from '../../../actions/DropDownActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});
export class AddParcels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcelObj: this.props.parcelObj
        }
    }

    componentDidMount() {
        this.props.getLocalGov();
        this.props.getKatKultura();
        this.props.getOwnershipDropDown();
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.parcelPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('parcelPostingFailed');
        }
    }

    addParcel() {
        if (this.state.parcelObj.broj &&
            this.state.parcelObj.povrsina &&
            this.state.parcelObj.id_vlasnistvo) {
            this.props.addParcel(
                this.state.parcelObj)
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.parcelObj);
        obj[name] = event.target.value;
        this.setState({ parcelObj: obj });
    };


    render() {

        let localGoverments = [];
        if (this.props.localGoverments && !this.props.seasonFetchingDropDown) {
            localGoverments = this.props.localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let kat_kulture = [];
        if (this.props.kat_kulture && !this.props.kat_kultureFetching) {
            kat_kulture = this.props.kat_kulture.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let ownershipDropDown = [];
        if (this.props.ownershipDropDown && !this.props.ownershipFetchingDropDown) {
            ownershipDropDown = this.props.ownershipDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        const { theme } = this.props;
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit parcel') : i18n.t('Add parcel')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Number')}
                                        value={this.state.parcelObj.broj}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('broj')}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Crop')}
                                        value={this.state.parcelObj.id_katastarska_kultura}
                                        onChange={this.handleChangeValue('id_katastarska_kultura')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {kat_kulture}
                                    </TextField>
                                </Grid> */}
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Land area') + ' (ha)'}
                                        value={this.state.parcelObj.povrsina}
                                        onChange={this.handleChangeValue('povrsina')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        required
                                        select
                                        label={i18n.t('Ownership')}
                                        value={this.state.parcelObj.id_vlasnistvo}
                                        onChange={this.handleChangeValue('id_vlasnistvo')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {ownershipDropDown}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Local government')}
                                        value={this.state.parcelObj.id_katastarska_opstina}
                                        onChange={this.handleChangeValue('id_katastarska_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {localGoverments}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('Potes')}
                                        value={this.state.parcelObj.potes}
                                        onChange={this.handleChangeValue('potes')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Immovable property number')}
                                        value={this.state.parcelObj.br_lista}
                                        onChange={this.handleChangeValue('br_lista')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('Class')}
                                        value={this.state.parcelObj.klasa}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('klasa')}
                                    />
                                </Grid>
                                
                                {/* <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('State area')}
                                        value={this.state.parcelObj.povrsina_drz}
                                        onChange={this.handleChangeValue('povrsina_drz')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid> */}
                               
                                <Grid item xs>      
                                </Grid>

                                <Grid item xs={3}>
                                <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.addParcel()}
                                        style={{ alignItems: 'right,', marginLeft: '10px', marginTop: '20px' }}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        parcelPosting: state.parcelReducer.parcelPosting,
        parcelPostingSuccess: state.parcelReducer.parcelPostingSuccess,
        parcelPostingFailed: state.parcelReducer.parcelPostingFailed,

        localGoverments: state.appReducer.localGoverments,
        localGovermentsFetching: state.appReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.appReducer.localGovermentsFetchingFailed,

        kat_kulture: state.appReducer.kat_kulture,
        kat_kultureFetching: state.appReducer.kat_kultureFetching,
        kat_kultureFetchingFailed: state.appReducer.kat_kultureFetchingFailed,

        parcelObj: state.parcelReducer.parcelObj,
        ownershipDropDown: state.parcelReducer.ownershipDropDown,
        ownershipFetchingDropDown: state.parcelReducer.ownershipFetchingDropDown,
        ownershipFetchingFailedDropDown: state.parcelReducer.ownershipFetchingFailedDropDown,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: () => dispatch(setInitialState()),
        getLocalGov: () => dispatch(getLocalGov()),
        getKatKultura: () => dispatch(getKatKultura()),
        getOwnershipDropDown: () => dispatch(getOwnershipDropDown()),
        addParcel: (parcelObj) => dispatch(addParcel(parcelObj)),

    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddParcels))