import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import axios from '../utils/AxiosWrapper';
import * as XLSX from 'xlsx'
import immutable from 'seamless-immutable';
import {
    Dialog, DialogContent, DialogTitle,
    Button, Avatar, TextField, Tab, Tabs, AppBar, Card,
    IconButton
} from '@material-ui/core';
import {
    changePassword, getLanguages,
    editProfile, setInitialState, editProfilePicture
} from '../actions/LoginActions';
import validator from 'validator';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { CircleLoader } from 'react-spinners';
import ReactSelect from '../utils/ReactSelect';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});

export class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            userProfile: this.props.userProfile,
            password: {},
            languages: []
        };
    }
    toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 80
    }

    componentDidMount() {
        this.props.getLanguages();
        this.getPersonalData();
        this.getActivitiesData();
        this.getTablesData();
        this.getNotesData();
    }


    componentDidUpdate(prevProps) {
        const { languages, userProfile, passwordChange, passwordChangeFetchingFailed, profileEdited, profileEditedFetchingFailed } = this.props;
        if (prevProps.languages !== languages) {
            this.setState({
                languages: immutable.asMutable(languages.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            });
        }
        if (prevProps.userProfile !== userProfile) {
            this.setState({
                userProfile: userProfile
            });
        }
        if (prevProps.passwordChange !== passwordChange) {
            toast.success(i18n.t('You successfully change your password!'), this.toastConf);
            this.props.setInitialState('passwordChange');
            this.setState({ value: 0 });
        }
        if (prevProps.passwordChangeFetchingFailed !== passwordChangeFetchingFailed) {
            toast.error(i18n.t('Wrong entered current password!'), this.toastConf);
            this.props.setInitialState('passwordChangeFetchingFailed');
        }

        if (prevProps.profileEdited !== profileEdited && profileEdited === true) {
            toast.success(i18n.t('You successfully updated your profile!'), this.toastConf);
            this.props.setInitialState('profileEdited');
            this.setState({ value: 1 });
        }
        if (prevProps.profileEditedFetchingFailed !== profileEditedFetchingFailed) {
            toast.error(i18n.t('Profile updating failed!'), this.toastConf);
            this.props.setInitialState('profileEditedFetchingFailed');
        }
    }

    getPersonalData = () => {
        axios.get('/api/profile/get_personal_data')
            .then(res => {
                const exportData = res.data.data
                this.setState({
                    personalData: exportData
                })
            })
            .catch(err => console.log(err))
    }

    getActivitiesData = () => {
        axios.get('/api/profile/get_activities_data')
            .then(res => {
                const exportData = res.data.data
                this.setState({
                    activitiesData: exportData
                })
            })
            .catch(err => console.log(err))
    }

    getNotesData = () => {
        axios.get('/api/profile/get_notes_data')
            .then(res => {
                const exportData = res.data.data
                this.setState({
                    notesData: exportData
                })
            })
            .catch(err => console.log(err))
    }

    getTablesData = () => {
        axios.get('/api/profile/get_tables_data')
            .then(res => {
                const exportData = res.data.data
                this.setState({
                    tablesData: exportData
                })
            })
            .catch(err => console.log(err))
    }

    handleExport = () => {

        const dataProfile = this.state.personalData.map((item) => {
            return {
                [i18n.t('Client ID')]: item.id_klijent,
                [i18n.t('Username')]: item.username,
                [i18n.t('Name')]: item.ime,
                [i18n.t('Surname')]: item.prezime,
                [i18n.t('Email')]: item.mejl,
                [i18n.t('Phone')]: item.telefon,
                [i18n.t('Mobile phone number')]: item.mobilni,
                [i18n.t('Created at')]: item.vreme_kreiranja,
                [i18n.t('Active')]: item.aktivan,
                [i18n.t('Region')]: item.region,
                [i18n.t('State')]: item.opstina,
                [i18n.t('Local government')]: item.katastarska_opstina,
                [i18n.t('Currency')]: item.valuta,
                [i18n.t('Skype')]: item.skype,
                [i18n.t('Mob. Reg.')]: item.kreiran_mobilna,
                [i18n.t('FB ID')]: item.fb_id,
                [i18n.t('Google ID')]: item.google_id,
                [i18n.t('Street and number')]: item.ulica_i_broj_korisnik
            }
        })

        const dataTable = this.state.tablesData.map((item) => {
            return {
                [i18n.t('Table ID')]: item.id,
                [i18n.t('Table name')]: item.naziv,
                [i18n.t('Season')]: item.lejer,
                [i18n.t('Land area')]: item.povrsina,
                [i18n.t('Unit')]: item.merna_jedinica,
                // [i18n.t('Area mj')]: item.povrsina_mj,
                [i18n.t('Active')]: item.aktivan,
                [i18n.t('Username')]: item.klijent_vlasnik,
                [i18n.t('Crop')]: item.kultura,
                [i18n.t('Crop group')]: item.kultura_grupa,
                [i18n.t('Crop subgroup')]: item.kultura_podgrupa,
                [i18n.t('Created at')]: item.vreme_kreiranja,
                [i18n.t('Edited at')]: item.vreme_izmene,
                [i18n.t('State')]: item.opstina,
                [i18n.t('Local government')]: item.katastarska_opstina,
                [i18n.t('Type of production')]: item.vrsta_proizvodnje,
                [i18n.t('Date of conversion')]: item.datum_konverzije,
                [i18n.t('Seed production')]: item.semenska_proizvodnja,
                [i18n.t('Organic production')]: item.organska_proizvodnja,
                [i18n.t('Basis of lease')]: item.osnov_zakupa,
                [i18n.t('Lease from')]: item.zakup_od,
                [i18n.t('Lease to')]: item.zakup_do,
                [i18n.t('Lease price')]: item.zakupnina,
                [i18n.t('Cultivated area')]: item.obradjeno,
                [i18n.t('Abandoned')]: item.zapusteno,
                [i18n.t('Usurped')]: item.uzurpirano,
                [i18n.t('Contract number')]: item.broj_ugovora,
                [i18n.t('Soil analysis')]: item.analiza_plodnosti,
                [i18n.t('Year of analysis')]: item.godina_analize,
                [i18n.t('Land area (gov)')]: item.povrsina_drz,
                [i18n.t('Geometry')]: item.geometry,
                [i18n.t('GEOM')]: item.geom_mobile
            }
        })

        const dataRadniNalozi = this.state.activitiesData.map((item) => {
            return {
                [i18n.t('Activity ID')]: item.id,
                [i18n.t('Active')]: item.aktivan,
                [i18n.t('Status')]: item.status,
                [i18n.t('Region')]: item.region,
                [i18n.t('Season')]: item.sezona,
                //[i18n.t('Crop')]: item.kultura,
                [i18n.t('Working operation')]: item.radna_operacija,
                [i18n.t('Created at')]: item.vreme_kreiranja,
                [i18n.t('Start date')]: item.datum_pocetka,
                [i18n.t('End date')]: item.datum_zavrsetka,
                [i18n.t('Edited at')]: item.vreme_izmene,
                [i18n.t('Locked')]: item.zakljucan,
                [i18n.t('Rejected')]: item.odbijen,
                [i18n.t('Signed')]: item.potpisan
            }
        })
        const dataTerenskeBeleske = this.state.notesData.map((item) => {
            return {
                [i18n.t('Note ID')]: item.id,
                [i18n.t('Title')]: item.naslov,
                [i18n.t('Region')]: item.region,
                [i18n.t('Intervention')]: item.terenska_intervencija_vrsta,
                [i18n.t('Event date')]: item.datum_dogadjaja,
                [i18n.t('Username')]: item.klijent_vlasnik,
                [i18n.t('Created at')]: item.vreme_kreiranja,
                [i18n.t('Edited at')]: item.vreme_izmene,
                [i18n.t('State')]: item.opstina,
                [i18n.t('Local government')]: item.katastarska_opstina,
                [i18n.t('Crop')]: item.kultura,
                [i18n.t('Average yield')]: item.prosecan_prinos,
                [i18n.t('Price')]: item.cena,
                [i18n.t('Root')]: item.korov,
                [i18n.t('Disease ')]: item.bolest,
                [i18n.t('Pests')]: item.stetocina,
                [i18n.t('Material')]: item.materijal,
                [i18n.t('Date of analysis')]: item.datum_analize,
                [i18n.t('Depth')]: item.dubina,
                [i18n.t('phkci')]: item.phkci,
                [i18n.t('phh2o')]: item.phh2o,
                [i18n.t('humus')]: item.humus,
                [i18n.t('aip2o5')]: item.aip2o5,
                [i18n.t('aik2o')]: item.aik2o,
                [i18n.t('ukupni_n')]: item.ukupni_n,
                [i18n.t('caco3')]: item.caco3,
                [i18n.t('Remark')]: item.napomena,
                [i18n.t('Geometry')]: item.geometry,
                [i18n.t('GEOM')]: item.geom_mobile
            }
        })


        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(dataProfile)

        ws['!rows'] = [{ hpx: 30 }];
        ws['!cols'] = [
            { wpx: 60 },
            { wpx: 160 },
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 160 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 140 },
            { wpx: 60 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 120 },
            { wpx: 60 },
            { wpx: 60 },
            { wpx: 80 },
            { wpx: 140 },
            { wpx: 140 },
            { wpx: 140 }

        ];

        let ws2 = XLSX.utils.json_to_sheet(dataTable)

        ws2['!rows'] = [{ hpx: 30 }];
        ws2['!cols'] = [
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 200 },
            { wpx: 160 },
            { wpx: 300 },
            { wpx: 300 },
            { wpx: 160 },
            { wpx: 160 },
            { wpx: 160 },
            { wpx: 160 },
            { wpx: 120 },
            { wpx: 100 },
            { wpx: 160 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 120 },
            { wpx: 100 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 120 },
            { wpx: 80 }

        ];

        let ws3 = XLSX.utils.json_to_sheet(dataRadniNalozi)

        ws3['!rows'] = [{ hpx: 30 }];
        ws3['!cols'] = [
            { wpx: 100 },
            { wpx: 60 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 120 },
            { wpx: 200 },
            { wpx: 160 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 160 },
            { wpx: 60 },
            { wpx: 60 },
            { wpx: 60 },
            { wpx: 60 },


        ];
        let ws4 = XLSX.utils.json_to_sheet(dataTerenskeBeleske)

        ws4['!rows'] = [{ hpx: 30 }];
        ws4['!cols'] = [
            { wpx: 60 },
            { wpx: 160 },
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 160 },
            { wpx: 160 },
            { wpx: 160 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 60 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 80 },
            { wpx: 200 },
            { wpx: 200 },
            { wpx: 200 },

        ];

        XLSX.utils.book_append_sheet(wb, ws, i18n.t("Personal details"))
        XLSX.utils.book_append_sheet(wb, ws2, i18n.t("Tables"))
        XLSX.utils.book_append_sheet(wb, ws3, i18n.t("Activities"))
        XLSX.utils.book_append_sheet(wb, ws4, i18n.t("Notes"))

        XLSX.writeFile(wb, "AgroLIFE_data.xlsx")
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    handleChangeProfileValue = (name) => event => {
        let obj = Object.assign({}, this.state.userProfile);
        obj[name] = event.target.value;
        this.setState({ userProfile: obj });
    };

    handleChangePasswordValue = (name) => event => {
        let obj = Object.assign({}, this.state.password);
        obj[name] = event.target.value;
        this.setState({ password: obj });
    };

    editProfile = () => {
        this.props.editProfile(this.state.userProfile);
    }

    changePassword = () => {
        if (this.state.password &&
            this.state.password.old_password &&
            this.state.password.new_password &&
            this.state.password.new_password1) {
            if (this.state.password.new_password !== this.state.password.new_password1) {
                toast.error(i18n.t('Passwords do not match!'), this.toastConf);
            } else if (!validator.isLength(this.state.password.new_password, { min: 6, max: 20 })) {
                toast.error(i18n.t('Password must contain at least 6 letters!'), this.toastConf);
            } else {
                this.props.changePassword(this.state.password.old_password,
                    this.state.password.new_password);
            }
        } else {
            toast.error(i18n.t('Please enter all fields!'), this.toastConf);
        }
    }

    onDropPicture = ({ accepted, rejected }) => {
        if (!!accepted && accepted.length !== 0) {
            this.props.editProfilePicture(accepted);
        }
    }

    onChangeLanguage = selectedOption => {
        let obj = Object.assign({}, this.state.userProfile);
        obj['id_jezik'] = selectedOption.value;
        this.setState({ userProfile: obj }, () => {
            this.props.editProfile(this.state.userProfile);
            i18n.changeLanguage(selectedOption.label);
        });

    }

    render() {
        var avatarSrc = require('../assets/images/profileImage.png');
        if (this.props.userProfile.slika) avatarSrc = this.props.userProfile.signedURL;
        var idJezik = this.state.userProfile.jezik && this.state.userProfile.jezik.id || 2;
        const { theme } = this.props;


        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ className: "dialogOverlow" }}
            >
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Profile')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialogOverlow">
                    <Card className="userProfile-img" >
                        <Avatar
                            src={avatarSrc}
                            className="userProfile-avatar"
                        />

                        {!this.props.profilePictureEditedFetching ?
                            <Dropzone
                                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml', 'image/gif']}
                                maxSize={5242880}
                                className="dropZone-profile"
                                multiple={false}
                                onDrop={(accepted, rejected) => this.onDropPicture({ accepted, rejected })}
                            >
                                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    return <div className="dropZoneDiv">
                                        <span className=" icon-Izmeni1 dropZoneIcon" />
                                    </div>
                                }}
                            </Dropzone>
                            :
                            <CircleLoader
                                color={'#61a50e'}
                                loading={this.props.profilePictureEditedFetching}
                            />
                        }
                    </Card>
                    <Card style={{ 'margin': '10px' }}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                fullWidth
                            >
                                <Tab label={i18n.t('Update profile')}
                                    className="tab" />
                                <Tab label={i18n.t('Change password')}
                                    className="tab" />
                            </Tabs>
                        </AppBar>
                        {this.state.value === 0 &&
                            <Card style={{ 'padding': '20px' }}>
                                <TextField
                                    type="email"
                                    required
                                    onChange={this.handleChangeProfileValue('mejl')}
                                    label={i18n.t('Contact email')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.mejl}
                                />
                                <br />
                                <TextField
                                    required
                                    onChange={this.handleChangeProfileValue('mobilni')}
                                    label={i18n.t('Phone number')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.mobilni}
                                />
                                {/* <br />
                                <TextField
                                    required
                                    onChange={this.handleChangeProfileValue('skype')}
                                    label={i18n.t('Skype')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.skype}
                                />
                                <br />
                                <Button onClick={() => this.editProfile()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Update')}
                                </Button> */}


                            </Card>}
                        {this.state.value === 1 &&
                            <Card style={{ 'padding': '20px' }} >
                                <TextField
                                    required
                                    type='password'
                                    onChange={this.handleChangePasswordValue('old_password')}
                                    label={i18n.t('Current password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <TextField
                                    onChange={this.handleChangePasswordValue('new_password')}
                                    required
                                    type='password'
                                    label={i18n.t('New password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <TextField
                                    onChange={this.handleChangePasswordValue('new_password1')}
                                    required
                                    type='password'
                                    label={i18n.t('Retype new password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <Button onClick={() => this.changePassword()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Change')}
                                </Button>
                            </Card>
                        }
                    </Card>
                    <ReactSelect
                        closeMenuOnSelect={true}
                        isClearable={false}
                        value={this.state.languages.find(function (element) {
                            return element.value === idJezik;
                        })}
                        maxMenuHeight={105}
                        label={i18n.t('Language')}
                        onChange={this.onChangeLanguage}
                        options={this.state.languages}
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                        styles={{ indicatorSeparator: () => { }, dropdownIndicator: defaultStyles => ({ ...defaultStyles, color: 'white' }) }}
                    />

                    {this.state.personalData && this.state.activitiesData && this.state.tablesData && this.state.notesData ?
                        <div>
                            <br />
                            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <Button onClick={() => this.handleExport()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Download all data')}
                                </Button>
                            </div>
                        </div>
                        :
                        <div><MoonLoader loading={true} size={40} /></div>
                    }

                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        passwordChange: state.appReducer.passwordChange,
        passwordChangeFetchingFailed: state.appReducer.passwordChangeFetchingFailed,
        profileEdited: state.appReducer.profileEdited,
        profilePictureEditedFetching: state.appReducer.profilePictureEditedFetching,
        languages: state.appReducer.languages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        changePassword: (old_password, new_password) => dispatch(changePassword(old_password, new_password)),
        editProfile: (obj) => dispatch(editProfile(obj)),
        editProfilePicture: (obj) => dispatch(editProfilePicture(obj)),
        getLanguages: () => dispatch(getLanguages()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile));