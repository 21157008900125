import axios from "./AxiosWrapper"
import { wentWrong } from "./ToastConfig";


export async function areaOfParts(id_tabla, id_proizvodna, povrsinaTemp) {
       
    
    let povrsinaTable;
    let povrsinaDelovaBezTrenutne;
    let temp = parseFloat(povrsinaTemp).toFixed(4)
       await axios.get('api/field/get_field_area', {params:{ id_tabla: id_tabla}})
            .then(res => { 
                // console.log('res data get_field_area', res.data)
                povrsinaTable= parseFloat(res.data)
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error);
            })

        await axios.get('api/field/get_parts_area', {params:{ id_tabla: id_tabla, id_proizvodna: id_proizvodna}})
            .then(res => { 
                if(res.data){
                    povrsinaDelovaBezTrenutne= parseFloat(res.data).toFixed(4)
                }else{
                povrsinaDelovaBezTrenutne =0
                }
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error);
            })
           
            // console.log(povrsinaTable, povrsinaDelovaBezTrenutne)
           

            let zbir = 0
            zbir = parseFloat(povrsinaDelovaBezTrenutne) + parseFloat(temp);
            // console.log('zbir', typeof(zbir));

            // console.log('provera povrsinaTable, zbir', povrsinaTable, zbir)

            if (parseFloat(povrsinaTable) < parseFloat(zbir)) {return false}
            else {return true}
}

export async function availableSurface(id_parcele, id_proizvodna, povrsinaTemp, povrsinaParcele) {
       
    
    let povrsinaTabli;
    let razlika;

    let temp = parseFloat(povrsinaTemp).toFixed(4)

    //    await axios.get('api/field/get_field_area', {params:{ id_parcele: id_parcele}})
    //         .then(res => { 
    //              console.log('res data get_field_area', res.data)
    //             povrsinaTable= parseFloat(res.data)
    //         })
    //         .catch(error => {
    //             wentWrong('Something went wrong, please try again!');
    //             console.log('Error 2 (getByURL): ', error);
    //         })

        await axios.get('api/field/get_tables_area', {params:{id_parcele}})
            .then(res => { 
                if(res.data){
                    povrsinaTabli= parseFloat(res.data).toFixed(4)
                }else{
                    povrsinaTabli =0
                }
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error);
            })
           
            // console.log(povrsinaTable, povrsinaDelova)
           
            if(temp){
            razlika = parseFloat(povrsinaParcele) - parseFloat(povrsinaTabli);
            }else{
            razlika = parseFloat(povrsinaParcele) - parseFloat(povrsinaTabli)
            }
            return razlika;
}