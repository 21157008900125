import React from 'react';
import { connect } from 'react-redux';
import LoginPage from '../components/page/LoginPage';
import ForgotPassword from '../components/page/ForgotPassword';
import ResetPassword from '../components/page/ResetPassword';
import RegisterPage from '../components/page/RegisterPage';
import { Route, Redirect } from 'react-router-dom';
import axios from './../utils/AxiosWrapper';
import { toggleDrawer, setInitialState } from '../actions/LoginActions';
import styled from 'react-emotion';
import Header from '../utils/Header/Index';
import MenuDrawer from '../components/MenuDrawer';
import { ThemeProvider } from 'emotion-theming';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from '../utils/theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { withRouter } from 'react-router-dom'
import GlobalDashboard from '../components/GlobalDashboard'
import ProtectedRoute from './ProtectedRoute';
import ActivityContainer from '../components/activities/ActivityContainer';
import ViewParcel from '../components/catastral_data/parcel/ViewParcel';
import ViewField from '../components/catastral_data/field/ViewField';
import MapContainer from '../components/Map/MapContainer';
import Note from '../components/note/Note';
import NoteDashboard from '../components/reports/Dashboards/NoteDashboard';
import Statistics from '../components/reports/Statistics';
import ConsumptionDashboard from '../components/reports/Dashboards/ConsumptionDashboard';
import RevenueDashboard from '../components/reports/Dashboards/RevenueDashboard';
import BalanceDashboard from '../components/reports/Dashboards/BalanceDashboard';
import SensorList from '../components/sensor/SensorList';
import SensorMap from '../components/sensor/SensorMap';
import Maps from './../components/Maps/Maps';
import NewActivity from './../components/activities/activities_new/NewActivity';
import SeasonStatistics from '../components/reports/SeasonStatistics';
import ArchiveList from '../components/catastral_data/archive/ArchiveList';

// import MapDashboard from '../components/Map/MapDashboard';
const Container = styled('main')`
  width: 100vw;
`;

const Content = styled('section')`
  padding-top: 0px;
  padding-left: ${p => p.authenticated ? p.theme.size(5) : 0};
  transform-origin: left 2px;
  transition: width  0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: 
    ${p =>
        p.isDrawerOpen && p.authenticated
            ? `translateX(${p.theme.size(15)})`
            : 'none'};
  width:${p =>
        p.isDrawerOpen && p.authenticated
            ? `calc(100% - ${p.theme.size(20)})`
            : `calc(100% - ${p.theme.size(5)})`};
  display:flex;
  justify-content:${p => p.authenticated ? 'center' : 'start'};
`;

const muiTheme = createMuiTheme({
    palette: theme.palette,
    overrides: {
        MuiCollapse: {
            entered: {
                height: "auto",
                overflow: "visible"
            }
        },
        MuiExpansionPanel: {
            expanded: {
                margin: 0
            }
        }
    }
});

export class Agrolife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isDrawerOpen: this.props.isDrawerOpen,
            userProfile: this.props.userProfile,
            authenticated: false
        };
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) this.setState({ authenticated: true });
        else this.setState({ authenticated: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token && nextProps.user && nextProps.loginSuccess === true) {
            this.setState({ authenticated: true });
            toast.success('Welcome' + ' ' + nextProps.user.username + '', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            this.props.setInitialState('loginSuccess');
        }
        // if (this.state.token === '') {
        //     axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 1 } })
        // }
        this.setState({
            token: nextProps.token,
            isDrawerOpen: nextProps.isDrawerOpen,
            userProfile: nextProps.userProfile,
        });
    }

    tokenCheck() { return localStorage.getItem('token'); };

    render() {
        let content;
        const { authenticated } = this.state;
        content =
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <div>
                        <Container>
                            <Content authenticated={authenticated} isDrawerOpen={this.state.isDrawerOpen}>
                                {/* <ProtectedRoute exact path="/map" authorized={authenticated} component={MapContainer} />
                                <ProtectedRoute exact path="/map_dashboard" authorized={authenticated} component={MapDashboard} /> 
                                */}
                                <ProtectedRoute exact path="/notes" authorized={authenticated} component={Note} />
                                <ProtectedRoute exact path="/notes_dashboard" authorized={authenticated} component={NoteDashboard} />
                                <ProtectedRoute exact path="/season_statistics" authorized={authenticated} component={SeasonStatistics} />
                                <ProtectedRoute exact path="/consumption_dashboard" authorized={authenticated} component={ConsumptionDashboard} />
                                <ProtectedRoute exact path="/revenue_dashboard" authorized={authenticated} component={RevenueDashboard} />
                                <ProtectedRoute exact path="/balance_dashboard" authorized={authenticated} component={BalanceDashboard} />
                                <ProtectedRoute exact path="/map" authorized={authenticated} component={MapContainer} />
                                <ProtectedRoute exact path="/field" authorized={authenticated} component={ViewField} />
                                <ProtectedRoute exact path="/archivelist" authorized={authenticated} component={ArchiveList} />
                                <ProtectedRoute exact path="/parcel" authorized={authenticated} component={ViewParcel} />
                                <ProtectedRoute exact path="/activities" authorized={authenticated} component={ActivityContainer} />
                                <ProtectedRoute exact path="/dashboard" authorized={authenticated} component={GlobalDashboard} />
                                <ProtectedRoute exact path="/sensors" authorized={authenticated} component={SensorList} />
                                <ProtectedRoute exact path="/sensor_map" authorized={authenticated} component={SensorMap} />
                                <ProtectedRoute exact path="/maps" authorized={authenticated} component={Maps} />
                                <ProtectedRoute exact path="/new_activity" authorized={authenticated} component={NewActivity} />
                                <Route exact path="/" component={LoginPage} />
                                <Route exact path="/forgotpass" component={ForgotPassword} />
                                <Route exact path="/resetpass" component={ResetPassword} />
                                <Route exact path="/registerPage" component={RegisterPage} />
                                {
                                    authenticated && window.location.pathname === '/' ?
                                        <Redirect from='/' to="activities" />
                                        : null
                                }
                            </Content>
                        </Container>
                        {authenticated &&
                            <div>
                                <MenuDrawer />
                                <Header />
                            </div>
                        }
                    </div>
                </ThemeProvider>
            </MuiThemeProvider>
        return (
            <div className="container">
                {content}
                <ToastContainer draggable={false} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.appReducer.token,
        user: state.appReducer.user,
        isDrawerOpen: state.appReducer.isDrawerOpen,
        loginSuccess: state.appReducer.loginSuccess,
        userProfile: state.appReducer.userProfile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Agrolife))