import immutable from 'seamless-immutable';
import { Type as NotesType } from '../../actions/note/NotesActions';


export const INITIAL_STATE = immutable({
    noteReport: [],
    noteReportFetching: false,
    noteReportFetchingFailed: false,
    noteReportPages: 1,

    noteItems: {},
    noteItemsFetching: false,
    noteItemsFetchingFailed: false,

    noteGroupDropDown: [],
    noteGroupDropDownFetching: false,
    noteGroupDropDownFetchingFailed: false,
    //notes 
    notesObj: {},
    notesFetching: false,
    notesFetchingFailed: false,
    notesPosting: false,
    notesPostingSuccess: false,
    notesPostingFailed: false,

    notesDocuments: [],
    notesDocumentFetching: false,
    notesDocumentFetchingFailed: false,

    deleted: false,
    deleteNoteFailed: false
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case NotesType.GET_NOTE_REPORT_CALL: {
            const noteReportFetching = true;
            return state.merge({ noteReportFetching });
            break;
        }

        case NotesType.GET_NOTE_REPORT_SUCCESS: {
            const noteReport = action.data.data;
            const noteReportPages = action.data.total_pages
            const noteReportFetching = false;
            return state.merge({ noteReport, noteReportPages, noteReportFetching });
            break;
        }

        case NotesType.GET_NOTE_REPORT_FAILED: {
            const noteReportFetchingFailed = true;
            return state.merge({ noteReportFetchingFailed });
            break;
        }

        case NotesType.GET_NOTE_DASHBOARD_CALL: {
            const noteItemsFetching = true;
            return state.merge({ noteItemsFetching });
            break;
        }

        case NotesType.GET_NOTE_DASHBOARD_SUCCESS: {
            const noteItems = action.data;
            const noteItemsFetching = false;
            return state.merge({ noteItems, noteItemsFetching });
            break;
        }

        case NotesType.GET_NOTE_DASHBOARD_FAILED: {
            const noteItemsFetchingFailed = true;
            const noteItemsFetching = false;
            return state.merge({ noteItemsFetching, noteItemsFetchingFailed });
            break;
        }

        case NotesType.GET_NOTE_GROUP_DROPDOWN_CALL: {
            const noteGroupDropDownFetching = true;
            return state.merge({ noteGroupDropDownFetching });
            break;
        }

        case NotesType.GET_NOTE_GROUP_DROPDOWN_SUCCESS: {
            const noteGroupDropDown = action.data.data;
            const noteGroupDropDownFetching = false;
            return state.merge({ noteGroupDropDown, noteGroupDropDownFetching });
            break;
        }

        case NotesType.GET_NOTE_GROUP_DROPDOWN_FAILED: {
            const noteGroupDropDownFetchingFailed = true;
            const noteGroupDropDownFetching = false;
            return state.merge({ noteGroupDropDownFetching, noteGroupDropDownFetchingFailed });
            break;
        }


        case NotesType.NOTE_FETCHING_CALL: {
            const notesFetching = true;
            return state.merge({ notesFetching })
            break;
        }

        case NotesType.NOTE_FETCHING_SUCCESS: {
            const notesFetching = false;
            const notesObj = action.data.data;
            return state.merge({ notesFetching, notesObj });
            break;
        }

        case NotesType.NOTE_FETCHING_FAILED: {
            const notesFetching = false;
            const notesFetchingFailed = true;
            return state.merge({ notesFetching, notesFetchingFailed });
            break;
        }


        case NotesType.NOTE_SENDING_CALL: {
            const notesPosting = true;
            return state.merge({ notesPosting })
            break;
        }

        case NotesType.NOTE_SENDING_SUCCESS: {
            const notesPosting = false;
            const notesPostingSuccess = true;
            return state.merge({ notesPosting, notesPostingSuccess });
            break;
        }

        case NotesType.NOTE_SENDING_FAILED: {
            const notesPosting = false;
            const notesPostingFailed = true;
            return state.merge({ notesPosting, notesPostingFailed });
            break;
        }

        case NotesType.NOTE_DOCUMENTS_CALL: {
            const notesDocumentFetching = true;
            return state.merge({ notesDocumentFetching });
            break;
        }

        case NotesType.NOTE_DOCUMENTS_SUCCESS: {
            const notesDocuments = action.data.data;
            const notesDocumentFetching = false;
            return state.merge({ notesDocuments, notesDocumentFetching });
            break;
        }

        case NotesType.NOTE_DOCUMENTS_FAILED: {
            const notesDocumentFetchingFailed = true;
            const notesDocumentFetching = false;
            return state.merge({ notesDocumentFetching, notesDocumentFetchingFailed });
            break;
        }

        case NotesType.DELETE_NOTE_SUCCESS: {
            const array = state['note'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ note: array, deleted: deleted })
            break;
        }

        case NotesType.DELETE_NOTE_FAILED: {
            const deleteNoteFailed = true;
            return state.merge({ deleteNoteFailed });
            break;
        }

        default:
            return state;
    }
}
