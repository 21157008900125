import React from 'react';
import Dashboard from '../Dashboard';
import i18n from '../../../i18n/i18n';

export default class RevenueDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Dashboard
                    url='api/dashboards/material_revenue'
                    labels='naziv_materijal'
                    dataset='ukupna_cena'
                    title={i18n.t('Material revenue')}
                    type='doughnut'
                    // unit='jedinica_mere'
                    filters={['naziv_sezona', 'naziv_kultura', 'naziv_tabla']}
                    filter_names={['Sezone', 'Kulture', 'Table']}
                />
            </div>
        )
    }
}